import { DisciplineSettingsTabsService } from './discipline-settings-tabs.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-discipline-settings-tabs',
    templateUrl: './discipline-settings-tabs.component.html',
    styleUrls: ['./discipline-settings-tabs.component.scss'],
})
export class DisciplineSettingsTabsComponent implements OnInit {
    settingsTab: any = [];
    external_id: any;
    previewLoading: boolean = true;
    i18n: any = [];
    currentLanguage: any;
    public buttonActive: boolean = false;

    constructor(
        private disciplineSettingsTabsService: DisciplineSettingsTabsService,
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService
    ) {}

    ngOnInit(): void {
        this.getUrlParams();
        this.getTabSettings(this.external_id);
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUrlParams() {
        console.log('EXTERNAL ID', this.route.params);
        this.route?.parent?.parent?.params?.subscribe(
            (params) => (this.external_id = params.id)
        );
    }

    getTabSettings(external_id: any) {
        this.disciplineSettingsTabsService
            .getDisciplineSettingsTab(external_id)
            .subscribe(
                (settingsTab) => (this.settingsTab = settingsTab),
                (err) => console.log(err),
                () => {
                    console.log('SETTINGS TAB', this.settingsTab);
                    (this.buttonActive = false), (this.previewLoading = false);
                }
            );
    }

    postTabSettings() {
        this.disciplineSettingsTabsService
            .postDisciplineSettingsTab(this.external_id, this.settingsTab)
            .subscribe(() => {
                this.buttonActive = false;
                window.location.reload();
            });
    }

    changeTabForm() {
        this.buttonActive = true;
    }
}
