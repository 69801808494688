import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/pages/login/login.service';

declare const gapi: any;
declare const google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleSpacesService {
    private scope = 'https://www.googleapis.com/auth/chat.spaces https://www.googleapis.com/auth/chat.memberships';
    public clientId = '';
    private tokenClient: any = {};

    constructor(
        private loginService: LoginService,
        private toastr: ToastrService
    ) {
        this.clientId = JSON.parse(localStorage.getItem('clientId') || 'null');
    }

    getRequestCode() {
        this.tokenClient.requestCode();
    }

    requestChatScope(callback): void {
        this.getGoogleAuthTokenPicker(callback);
        this.onAuthApiLoad(callback);
        gapi.load('client');
    }

    checkScopeDrive(token): boolean {
        return !!(token.scope.includes('chat.memberships') && token.scope.includes('chat.spaces'));
    }

    checkTokenValid(token, returnToken) {
        this.loginService.tokenInfoGoogle(token).subscribe({
            next: (tokenInfo) => {
                if (this.checkScopeDrive(tokenInfo)) {
                    if (returnToken instanceof Function) {
                        returnToken(token);
                    }
                } else {
                    this.authRefreshToken(token);
                }
            },
            error: () => {
                this.authRefreshToken(token);
            },
        });
    }

    getGoogleAuthTokenPicker(returnToken) {
        if (localStorage.getItem('googleAuthTokenPicker') !== null) {
            const googleAuthTokenPicker = JSON.parse(
                localStorage.getItem('googleAuthTokenPicker') || ''
            );
            if (
                googleAuthTokenPicker !== null
                && googleAuthTokenPicker !== undefined
                && googleAuthTokenPicker !== ''
            ) {
                this.checkTokenValid(googleAuthTokenPicker, returnToken);
            }
        } else {
            this.authRefreshToken();
        }
    }

    authRefreshToken(token?) {
        this.loginService.refreshTokenGoogle().subscribe({
            next: (response) => {
                if (response.hasOwnProperty('token_picker')) {
                    localStorage.setItem(
                        'googleAuthTokenPicker',
                        JSON.stringify(response.token_picker)
                    );
                    this.getRequestCode();
                    if (token) {
                        this.gapiLoad(token);
                    }
                }
            },
            error: (err) => {
                console.log('CALLBACK HAS ERROR authRefreshToken', err);
            },
        });
    }

    gapiLoad(token) {
        if (gapi.hasOwnProperty('client')) {
            gapi.client.setToken({ access_token: token }); // Atualiza o novo token (permissions)
            gapi.load('client:chat');
        } else {
            gapi.load('client');
            setTimeout(() => { this.gapiLoad(token); }, 500);
        }
    }

    // Adiciona scopo do drive para o usuário google do cliente
    private onAuthApiLoad(returnToken) {
        this.tokenClient = google.accounts.oauth2.initCodeClient({
            client_id: this.clientId,
            scope: this.scope,
            immediate: false,
            callback: (response) => {
                if (response.hasOwnProperty('error')) {
                } else {
                    this.loginService.loginWithGoogle({ code: response.code }).subscribe({
                        next: (res) => {
                            this.loginService.setGoogleCredencials(res);
                            if (returnToken instanceof Function) {
                                returnToken(res.token_picker);
                            }
                        },
                        error: (err) => {
                            if (err.status == 401) {
                                response = { error: { errCode: 400, error: 'invalid_grant' } };
                            }
                        },
                    });
                }
            },
        });
    }

    showMessage(title: string, message: string, type: string): void {
        this.toastr.show(message, title, { progressBar: true }, type);
    }
}
