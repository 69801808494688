<div class="flex justify-center items-center h-full" *ngIf="isLoading$ | async">
    <app-bullets-loader></app-bullets-loader>
</div>

<section class="grid grid-cols-1 sm:grid-cols-2 gap-4 dash-transition items-start" *ngIf="!showLoading">
    <!-- Card de últimas atividades -->
    <div class="w-full activits">
        <div class="flex flex-col gap-4">
            <div class="bg-inicieColors-blue rounded-lg p-4 shadow-lg">
                <div class="flex flex-col sm:flex-row gap-4 items-center sm:justify-between">
                    <div class="flex flex-col items-center sm:flex-row gap-3">
                        <div class="w-16 h-16">
                            <app-user-frame [img_src]="user?.image" [user_name]="userName" [adaptable_size]="true"></app-user-frame>
                        </div>
                        <div class="flex flex-col items-center text-center sm:items-start sm:text-left">
                            <span class="font-Roboto text-customWhite-default font-semibold text-19 md:text-26">
                                {{ i18n.dash_welcome_student }} {{ userName }}
                            </span>
                            <span class="text-12 text-customWhite-default pl-2s">
                                {{ user?.email }}
                            </span>
                        </div>
                    </div>
                    <button [routerLink]="['/disciplines']" class="btn-discipline border border-customWhite-default rounded-md items-center h-full px-3 py-1">
                        <span class="text-14 text-customWhite-default font-Roboto font-medium">
                            {{ i18n.dash_my_disciplines }}
                        </span>
                    </button>
                </div>
            </div>

            <div class="flex flex-row gap-4 items-center justify-center mb-4 content-buttons">
                <button class="flex rounded-md border button-select items-center justify-center" [class.border-customBlue-default]="isMyTasksActive" [class.border-customGray-default]="!isMyTasksActive" (click)="toggleTabs('my-tasks')">
                    <span class="text-14 font-medium" [class.text-customBlue-default]="isMyTasksActive" [class.text-customGray-default]="!isMyTasksActive">
                        {{ i18n.dash_my_tasks }}
                    </span>
                </button>
                <button class="flex rounded-md border border-customGray-default button-select items-center justify-center" [class.border-customBlue-default]="!isMyTasksActive" [class.border-customGray-default]="isMyTasksActive" (click)="toggleTabs('my-announcements')">
                    <span class="text-14 text-customGray-default font-medium" [class.text-customBlue-default]="!isMyTasksActive" [class.text-customGray-default]="isMyTasksActive">
                        {{ i18n.dash_my_announcements }}
                    </span>
                </button>
            </div>

            <div *ngIf="!isMobile || isMyTasksActive">
                <app-card-teacher [dashData]="dashData" [user]="user" [i18n]="i18n"></app-card-teacher>
            </div>
        </div>
    </div>

    <!-- Card de anúncios -->
    <div class="w-full announcements h-full">
        <div class="flex flex-col gap-4 h-full">
            <app-dashboard-feed [i18n]="i18n" [dashData]="dashData" style="height: 100%; max-height: 48.3rem;"></app-dashboard-feed>
        </div>
    </div>
</section>
