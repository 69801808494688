import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

import { Months, PostPlannedWeek } from '../interfaces/current-year.interface';
import { ResponseModulesValues, Skills } from '../interfaces/curricular-parameters.interface';
import { ListItem, PostDetailedPlanning } from '../interfaces/detailed-planning.interface';
import { ModuleDetail, ResponseModules } from '../interfaces/modules.interface';
import {
    Component,
    CurricularFilters, DetailedView, ParamsFilters, PostPlanning, ResponsePlanning
} from '../interfaces/planning.interface';
import { ResponseNextColumn, Segment } from '../interfaces/start-planning.interface';

@Injectable({
    providedIn: 'root'
})
export class ContentPlannerService {
    constructor(private http: HttpClient) { }

    // CRUD helpers
    getSegments(): Observable<{ segments: Segment[] }> {
        return this.http.get<{ segments: Segment[] }>(`${API_Routes.URL}/plannings/segments`);
    }

    getNextColumn(value_id: number, column: string): Observable<ResponseNextColumn> {
        return this.http.get<ResponseNextColumn>(`${API_Routes.URL}/plannings/listvalues/nextcolumn?value_id=${value_id}&column=${column}`);
    }

    getSkillValues<T>(guidelineId: number, value_id: number, column: string, isSkill: number): Observable<T> {
        return this.http.get<T>(`${API_Routes.URL}/plannings/skill-values?value_id=${value_id}&column=${column}&guideline_id=${guidelineId}&skills=${isSkill}`);
    }

    // Planning
    postPlanning(body: PostPlanning): Observable<ResponsePlanning> {
        return this.http.post<ResponsePlanning>(`${API_Routes.URL}/plannings`, body);
    }

    setDisciplineTemplate(planningId: number): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/plannings/set-discipline`, { planningId });
    }

    patchPlanning(planning_external_id: string, body: PostPlanning): Observable<ResponsePlanning> {
        return this.http.patch<ResponsePlanning>(`${API_Routes.URL}/plannings/${planning_external_id}`, body);
    }

    deletePlanning(planning_external_id: string): Observable<ResponsePlanning> {
        return this.http.delete<ResponsePlanning>(`${API_Routes.URL}/plannings/${planning_external_id}`);
    }

    getPlanning(): Observable<{ plannings: ResponsePlanning[] }> {
        return this.http.get<{ plannings: ResponsePlanning[] }>(`${API_Routes.URL}/plannings`);
    }

    getPlanningDetail(planning_external_id: string): Observable<ResponsePlanning> {
        return this.http.get<ResponsePlanning>(`${API_Routes.URL}/plannings/${planning_external_id}/visualization`);
    }

    getPlanningDetailedView(planning_external_id: string): Observable<DetailedView> {
        return this.http.get<DetailedView>(`${API_Routes.URL}/plannings/${planning_external_id}/detailed-view`);
    }

    getPlanningsByFilters(params: ParamsFilters): Observable<DetailedView> {
        const httpParams = new HttpParams()
            .set('segment_id', params.segment_id.toString())
            .set('degree_id', params.degree_id.toString())
            .set('area_id', params.area_id.toString())
            .set('component_id', params.component_id.toString());

        return this.http.get<DetailedView>(`${API_Routes.URL}/plannings/list-by-filters`, { params: httpParams });
    }

    getFiltersByKey(params: ParamsFilters): Observable<Component[]> {
        const httpParams = new HttpParams()
            .set('segmentId', params.segment_id.toString() ?? '')
            .set('degreeId', params?.degree_id?.toString() ?? '')
            .set('areaId', params?.area_id?.toString() ?? '')
            .set('componentId', params?.component_id?.toString() ?? '')
            .set('key', params?.key ?? '');

        return this.http.get<Component[]>(`${API_Routes.URL}/plannings/list-filters-by-key`, { params: httpParams });
    }

    // Modules
    getModulesValues(): Observable<{ tags: ResponseModulesValues[] }> {
        return this.http.get<{ tags: ResponseModulesValues[] }>(`${API_Routes.URL}/plannings/modules/values`);
    }

    postModules<T>(planning_external_id: string, body: T): Observable<ResponsePlanning[]> {
        return this.http.post<ResponsePlanning[]>(`${API_Routes.URL}/plannings/${planning_external_id}/modules`, body);
    }

    getModules(planning_external_id: string): Observable<{ plannings_modules: ResponseModules[] }> {
        return this.http.get<{ plannings_modules: ResponseModules[] }>(`${API_Routes.URL}/plannings/${planning_external_id}/modules`);
    }

    getModulesDetail(planning_external_id: string, modules_external_id: string): Observable<ModuleDetail> {
        return this.http.get<ModuleDetail>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${modules_external_id}/visualization`);
    }

    patchModule<T>(planning_external_id: string, modules_external_id: string, body: T): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${modules_external_id}`, body);
    }

    patchModuleName<T>(planning_external_id: string, modules_external_id: string, body: { name: string }): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${modules_external_id}/update-name`, body);
    }

    patchModuleClass<T>(classes: any): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/plannings/modules/update-class`, classes);
    }

    patchReorderClass<T>(classes: any): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/plannings/modules/reorder-class`, classes);
    }

    deleteResource<T>(classesId: number, contentId: number): Observable<T> {
        return this.http.delete<T>(`${API_Routes.URL}/plannings/modules/class/${classesId}/content/${contentId}`);
    }

    deleteModule(planning_external_id: string, modules_external_id: string): Observable<ResponseModules[]> {
        return this.http.delete<ResponseModules[]>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${modules_external_id}`);
    }

    getSkills(query: string, page: number, per_page: number): Observable<Skills> {
        return this.http.get<Skills>(`${API_Routes.URL}/plannings/skills?q=${query}&page=${page}&per_page=${per_page}`);
    }

    getGuidelines<T>(): Observable<T> {
        return this.http.get<T>(`${API_Routes.URL}/plannings/guidelines`);
    }

    // Detailed planning
    getListItems(): Observable<{ procedure: ListItem[], material: ListItem[] }> {
        return this.http.get<{ procedure: ListItem[], material: ListItem[] }>(`${API_Routes.URL}/plannings/modules/class_plannings/list-items`);
    }

    postDetailedPlanning(planning_external_id: string, module_external_id: string, body: PostDetailedPlanning): Observable<PostDetailedPlanning> {
        return this.http.post<PostDetailedPlanning>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${module_external_id}/class_plannings`, body);
    }

    postAddClass<T>(body): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/plannings/modules/addclass`, body);
    }

    deleteClass<T>(classId: number): Observable<T> {
        return this.http.delete<T>(`${API_Routes.URL}/plannings/modules/class/${classId}`);
    }

    getFilters(): Observable<CurricularFilters> {
        return this.http.get<CurricularFilters>(`${API_Routes.URL}/plannings/list-filters`);
    }

    // Teacher
    getMonths(year: number, month: number): Observable<Months[]> {
        return this.http.get<Months[]>(`${API_Routes.URL}/plannings/modules/list-weeks?year=${year}&month=${month}`);
    }

    postPlannedWeek(planning_external_id: string, module_external_id: string, body: PostPlannedWeek[]): Observable<PostPlannedWeek[]> {
        return this.http.patch<PostPlannedWeek[]>(`${API_Routes.URL}/plannings/${planning_external_id}/modules/${module_external_id}/planned_week`, body);
    }
}
