/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-search-input',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchInputComponent implements OnInit, OnChanges {
    @Input() handleText = (textA: any) => { };

    @Input() placeholder = 'Procurar...';
    @Input() search: any = '';

    async ngOnInit(): Promise<void> {
    // this.handleChange = this.handleText
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.search = '';
        }
    }

    async onSearchChange(searchText: string) {
        const textX = await handleSearchChange(searchText);
        this.search = textX;
        this.handleText(textX);
    }
}

let timeout: NodeJS.Timeout | null = null;
async function handleSearchChange(text: string) {
    return new Promise((resolve) => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            resolve(text);
        }, 500);
    });
}
