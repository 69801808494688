import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

export type StatusTypes = 'presence' | 'absent' | '1/2_presence' | '3/4_presence';
export interface UserTypes {
    id?: string;
    name?: string;
    user_name?: string;
    external_id?: string;

    email?: string;
    image?: string;
    user_image?: string;
    profile?: string;

    user_external_id?: string;
    presence?: StatusTypes;
    teacher_ip?: string;
    observation?: string;
}

export interface ClassDetailsTypes {
    name: string;
    id?: string;
    external_id: string;
    section?: string;
    participants: {
        count: {
            teacher: number;
            student: number;
        },
        teacher: UserTypes[];
        student: UserTypes[];
    };
}

export type StatusRegister = 'finished' | 'started';

interface PayloadFrequency {
    disciplineExternalId: string;
    titleDiary: string;
    topicDiary: string;
    initialDate: string;
    startTime: string;
    endTime: string;
    status: StatusRegister,
    students: UserTypes[];
}

export interface HistoryTypes {
    id: number;
    name: string;
    date_class: string;
    start_class: string;
    end_class: string;
    status: string;
}

interface Settings {
    name: string;
    date_class: string;
    start_class: string;
    end_class: string;
    status: string;
}

interface DiaryTypes {
    diary: string;
    settings: Settings;
    students: UserTypes[];
}

export interface ResponseCalendaryHistory {
    year: number;
    months: number[];
    days: DayData[];
}

interface DayData {
    day: number;
    status: StatusRegister;
}

@Injectable({
    providedIn: 'root'
})
export class CallingRollService {
    constructor(private http: HttpClient) { }

    getStudents(external_id: string): Observable<ClassDetailsTypes> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${external_id}/basic`);
    }

    saveFrequency(data: PayloadFrequency): Observable<any> {
        const payload = {
            diary: data?.topicDiary,
            settings: {
                name: data?.titleDiary,
                date_class: data?.initialDate,
                start_class: data?.startTime,
                end_class: data?.endTime,
                status: data?.status,
            },
            students: data?.students,
        };

        return this.http.post(`${API_Routes.URL}/disciplines/${data?.disciplineExternalId}/frequency`, payload);
    }

    getHistories(disciplineId: string, date_class: string): Observable<HistoryTypes[]> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${disciplineId}/frequency/list?date_class=${date_class}`);
    }

    getOneFrequency(disciplineId: string, frequencyId: number): Observable<DiaryTypes> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${disciplineId}/frequency?id=${frequencyId}`);
    }

    getFrequencyCalendary(disciplineId: string, year: number, month: number): Observable<ResponseCalendaryHistory[]> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${disciplineId}/frequency/listMonths?year=${year}&month=${month}`);
    }

}

