<div class="flex flex-col gap-4">
    <div
        class="card-tasks flex flex-col bg-customWhite-default rounded-t-lg p-4 px-6 gap-6 shadow-lg"
    >
        <header class="">
            <div class="flex justify-between items-center">
                <h1 class="font-semibold text-26 text-customBlue-default">
                    {{ i18n.dash_dashboard_tasks }}
                </h1>
            </div>
        </header>
        <section class="flex flex-col gap-6">
            <!-- Select Discipline -->
            <div>
                <div class="">
                    <div class="relative">
                        <div>
                            <button
                                class="w-full flex items-center justify-between cursor-pointer p-2 pr-5 rounded-md border border-customGray-mediumGray"
                                (click)="isDropdownOpen = !isDropdownOpen"
                            >
                                <span
                                    *ngIf="!checkedList.length"
                                    class="text-customGray-dark"
                                >
                                    {{ i18n.dash_discipline }}
                                </span>
                                <ng-container *ngIf="checkedList.length">
                                    <span
                                        class="text-customGray-dark"
                                        style="
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        "
                                    >
                                        {{ checkedList.length }}
                                        {{
                                            checkedList.length > 1
                                                ? i18n.dash_selected_disciplines
                                                : i18n.dash_selected_discipline
                                        }}
                                    </span>
                                </ng-container>
                                <button
                                    class="justify-end"
                                    [@rotatedState]="!isDropdownOpen"
                                >
                                    <span
                                        class="iconify w-8 h-8 text-customBlue-default"
                                        data-icon="iconamoon:arrow-up-2-fill"
                                    ></span>
                                </button>
                            </button>
                        </div>

                        <div
                            class="w-full drop-show cursor-pointer border-t-0 border border-customGray-default rounded-b-md bg-customWhite-default style-scroll"
                            *ngIf="isDropdownOpen"
                        >
                            <div>
                                <label
                                    class="container flex gap-2 items-center p-2 bg-customGray-iceGray"
                                >
                                    <input
                                        class="hidden"
                                        type="checkbox"
                                        [(ngModel)]="allSelected"
                                        (change)="selectAll()"
                                    />
                                    <div
                                        class="check flex justify-center"
                                    ></div>
                                    <div class="text-customGray-dark">
                                        {{ i18n.dash_select_all_disciplines }}
                                    </div>
                                </label>
                            </div>

                            <div class="drop-list p-2">
                                <label
                                    class="container flex gap-2 items-center"
                                    *ngFor="
                                        let discipline of dashData.disciplines
                                    "
                                >
                                    <input
                                        class="hidden"
                                        type="checkbox"
                                        [(ngModel)]="discipline.checked"
                                        (change)="getSelectedValue(discipline)"
                                    />
                                    <div
                                        class="check flex justify-center"
                                    ></div>
                                    <div
                                        [pTooltip]="discipline.name"
                                        tooltipPosition="top"
                                        class="text-customGray-dark"
                                        style="
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        "
                                    >
                                        {{ discipline.name }}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="checkedList.length > 0">
                    <div class="flex flex-wrap gap-2 mt-4">
                        <ng-container
                            *ngFor="let discipline of dashData.disciplines"
                        >
                            <div
                                *ngIf="discipline.checked"
                                class="flex items-center justify-between p-2 border border-customGray-mediumGray"
                                style="max-width: 250px; min-width: 0"
                            >
                                <div
                                    [pTooltip]="discipline.name"
                                    tooltipPosition="top"
                                    class="text-customGray-dark"
                                    style="
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    "
                                >
                                    {{ discipline.name }}
                                </div>

                                <div
                                    class="cursor-pointer"
                                    (click)="removeFilter(discipline)"
                                >
                                    <span
                                        class="iconify w-5 h-5 text-customBlue-default"
                                        data-icon="mdi:close"
                                    ></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <!-- In progress -->
            <div
                class="flex flex-col w-full rounded-md border border-customGray-mediumGray p-4"
            >
                <div
                    id="header"
                    class="flex flex-row justify-between gap-6"
                    (click)="toggleExpand('in_progress')"
                >
                    <div
                        class="flex flex-col sm:flex-row gap-0 sm:gap-3 items-start sm:items-center justify-between w-full"
                    >
                        <div class="flex flex-row gap-3 items-center">
                            <div class="bg-customGray-iceGray rounded-full p-2">
                                <span
                                    class="iconify text-customBlue-default"
                                    data-icon="lets-icons:mortarboard"
                                    data-height="30"
                                    data-width="30"
                                ></span>
                            </div>
                            <span
                                class="font-semibold text-18 text-customBlue-default font-Roboto"
                            >
                                {{ i18n.dash_in_progress }}
                            </span>
                        </div>
                        <span
                            *ngIf="filteredContents?.in_progress?.length"
                            class="count-tasks font-normal text-15 text-customGray-dark font-Roboto"
                        >
                            {{ filteredContents?.in_progress?.length }}
                            <span
                                *ngIf="
                                    filteredContents?.in_progress?.length > 1
                                "
                            >
                                {{ i18n.dash_tasks | lowercase }}
                            </span>
                            <span
                                *ngIf="
                                    filteredContents?.in_progress?.length === 1
                                "
                            >
                                {{ i18n.dash_task }}
                            </span>
                        </span>
                    </div>
                    <div
                        class="flex flex-row gap-3 items-center justify-center"
                    >
                        <button [@rotatedState]="!isExpanded['in_progress']">
                            <span
                                class="iconify w-8 h-8 text-customBlue-default"
                                data-icon="iconamoon:arrow-up-2-fill"
                            ></span>
                        </button>
                    </div>
                </div>
                <div id="content" *ngIf="isExpanded['in_progress']">
                    <ng-container *ngIf="filteredContents?.in_progress?.length">
                        <ng-container
                            *ngFor="
                                let inProgress of filteredContents.in_progress
                            "
                        >
                            <div
                                class="flex flex-col sm:flex-row w-full rounded-md border border-customGray-mediumGray p-1 sm:p-4 gap-6 justify-between items-center mt-4 cursor-pointer"
                                (click)="routeToLink(inProgress)"
                            >
                                <div class="flex gap-3 items-center">
                                    <span
                                        class="iconify text-customGray-dark"
                                        data-icon="basil:book-outline"
                                        data-height="30"
                                        data-width="30"
                                    ></span>
                                    <div class="flex flex-col">
                                        <span
                                            class="font-semibold text-16 text-customGray-dark font-Roboto"
                                        >
                                            {{ inProgress?.title }}
                                        </span>
                                        <span
                                            class="text-14 text-customGray-dark font-Roboto"
                                        >
                                            {{ inProgress?.discipline_name }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-row sm:flex-col items-start gap-1 sm:items-center justify-center h-full"
                                >
                                    <span
                                        class="text-12 text-customGray-dark font-Roboto"
                                    >
                                        {{ i18n.dash_delivery_date }}
                                    </span>
                                    <div
                                        class="flex justify-center items-start gap-1"
                                    >
                                        <span
                                            class="iconify text-customGray-dark"
                                            data-icon="bx:calendar-alt"
                                            data-height="20"
                                            data-width="18"
                                        ></span>
                                        <span
                                            class="italic md:not-italic text-12 sm:text-14 text-customGray-dark"
                                        >
                                            {{
                                                formateData(
                                                    inProgress?.end_date
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        class="flex items-center gap-1 sm:hidden"
                                    >
                                        <i
                                            class="iconify-inline font-semibold"
                                            data-icon="bi:clock"
                                            data-width="14"
                                        ></i>
                                        <span
                                            class="italic md:not-italic text-12 sm:text-14 font-Roboto"
                                        >
                                            {{
                                                formatTime(inProgress?.end_date)
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex gap-3">
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="inProgress?.pending"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 font-normal font-roboto text-customGray-dark"
                                        >
                                            {{ i18n.dash_pending_single }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="inProgress?.delivered"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_handed_over }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="inProgress?.reviewed"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_reviewed }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="!filteredContents?.in_progress?.length"
                    >
                        <div class="flex justify-center items-center p-6">
                            <span
                                class="text-18 text-center text-customGray-iconGray font-semibold font-Roboto"
                            >
                                {{ i18n.dash_empty_message_in_progress }}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- No deadlines -->
            <div
                class="flex flex-col w-full rounded-md border border-customGray-mediumGray p-4"
            >
                <div
                    id="header"
                    class="flex flex-row justify-between gap-6"
                    (click)="toggleExpand('no_deadlines')"
                >
                    <div
                        class="flex flex-col sm:flex-row gap-0 sm:gap-3 items-start sm:items-center justify-between w-full"
                    >
                        <div class="flex flex-row gap-3 items-center">
                            <div class="bg-customGray-iceGray rounded-full p-2">
                                <span
                                    class="iconify text-customBlue-default"
                                    data-icon="lets-icons:mortarboard"
                                    data-height="30"
                                    data-width="30"
                                ></span>
                            </div>
                            <span
                                class="font-semibold text-18 text-customBlue-default font-Roboto"
                            >
                                {{ i18n.dash_no_deadlines }}
                            </span>
                        </div>
                        <span
                            *ngIf="filteredContents?.no_deadline?.length"
                            class="count-tasks font-normal text-15 text-customGray-dark font-Roboto"
                        >
                            {{ filteredContents?.no_deadline?.length }}
                            <span
                                *ngIf="
                                    filteredContents?.no_deadline?.length > 1
                                "
                            >
                                {{ i18n.dash_tasks | lowercase }}
                            </span>
                            <span
                                *ngIf="
                                    filteredContents?.no_deadline?.length === 1
                                "
                            >
                                {{ i18n.dash_task }}
                            </span>
                        </span>
                    </div>
                    <div
                        class="flex flex-row gap-3 items-center justify-center"
                    >
                        <button [@rotatedState]="!isExpanded['no_deadlines']">
                            <span
                                class="iconify w-8 h-8 text-customBlue-default"
                                data-icon="iconamoon:arrow-up-2-fill"
                            ></span>
                        </button>
                    </div>
                </div>
                <div id="content" *ngIf="isExpanded['no_deadlines']">
                    <ng-container *ngIf="filteredContents?.no_deadline?.length">
                        <ng-container
                            *ngFor="
                                let noDeadline of filteredContents.no_deadline
                            "
                        >
                            <div
                                class="flex flex-col sm:flex-row w-full rounded-md border border-customGray-mediumGray p-4 gap-6 justify-between items-center mt-4 cursor-pointer"
                                (click)="routeToLink(noDeadline)"
                            >
                                <div class="flex gap-3 items-center">
                                    <span
                                        class="iconify text-customGray-dark"
                                        data-icon="basil:book-outline"
                                        data-height="30"
                                        data-width="30"
                                    ></span>
                                    <div class="flex flex-col">
                                        <span
                                            class="font-semibold text-16 text-customGray-dark font-Roboto"
                                        >
                                            {{ noDeadline?.title }}
                                        </span>
                                        <span
                                            class="text-14 text-customGray-dark font-Roboto"
                                        >
                                            {{ noDeadline?.discipline_name }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex gap-3">
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="noDeadline?.pending"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 font-normal font-roboto text-customGray-dark"
                                        >
                                            {{ i18n.dash_pending_single }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="noDeadline?.delivered"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_handed_over }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="noDeadline?.reviewed"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_reviewed }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="!filteredContents?.no_deadline?.length"
                    >
                        <div class="flex justify-center items-center p-6">
                            <span
                                class="text-18 text-center text-customGray-iconGray font-semibold font-Roboto"
                            >
                                {{ i18n.dash_empty_message_no_deadlines }}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Reviewed Last Month -->
            <div
                class="flex flex-col w-full rounded-md border border-customGray-mediumGray p-4"
            >
                <div
                    id="header"
                    class="flex flex-row justify-between gap-6"
                    (click)="toggleExpand('last_month')"
                >
                    <div
                        class="flex flex-col sm:flex-row gap-0 sm:gap-3 items-start sm:items-center justify-between w-full"
                    >
                        <div class="flex flex-row gap-3 items-center">
                            <div class="bg-customGray-iceGray rounded-full p-2">
                                <span
                                    class="iconify text-customBlue-default"
                                    data-icon="lets-icons:mortarboard"
                                    data-height="30"
                                    data-width="30"
                                ></span>
                            </div>
                            <span
                                class="font-semibold text-18 text-customBlue-default font-Roboto"
                            >
                                {{ i18n.dash_reviewed_last_month }}
                            </span>
                        </div>
                        <span
                            *ngIf="
                                filteredContents?.reviewed_last_month?.length >
                                0
                            "
                            class="count-tasks-2 font-normal text-15 text-customGray-dark font-Roboto"
                        >
                            {{ filteredContents?.reviewed_last_month?.length }}
                            <span
                                *ngIf="
                                    filteredContents?.reviewed_last_month
                                        ?.length > 1
                                "
                            >
                                {{ i18n.dash_tasks | lowercase }}
                            </span>
                            <span
                                *ngIf="
                                    filteredContents?.reviewed_last_month
                                        ?.length === 1
                                "
                            >
                                {{ i18n.dash_task | lowercase }}
                            </span>
                        </span>
                    </div>
                    <div
                        class="flex flex-row gap-3 items-center justify-center"
                    >
                        <button [@rotatedState]="!isExpanded['last_month']">
                            <span
                                class="iconify w-8 h-8 text-customBlue-default"
                                data-icon="iconamoon:arrow-up-2-fill"
                            ></span>
                        </button>
                    </div>
                </div>
                <div id="content" *ngIf="isExpanded['last_month']">
                    <ng-container
                        *ngIf="filteredContents?.reviewed_last_month?.length"
                    >
                        <ng-container
                            *ngFor="
                                let lastMonth of filteredContents?.reviewed_last_month
                            "
                        >
                            <div
                                class="flex flex-col sm:flex-row w-full rounded-md border border-customGray-mediumGray p-1 sm:p-4 gap-6 justify-between items-center mt-4 cursor-pointer"
                                (click)="routeToLink(lastMonth)"
                            >
                                <div class="flex gap-3 items-center">
                                    <span
                                        class="iconify text-customGray-dark"
                                        data-icon="basil:book-outline"
                                        data-height="30"
                                        data-width="30"
                                    ></span>
                                    <div class="flex flex-col">
                                        <span
                                            class="font-semibold text-16 text-customGray-dark font-Roboto"
                                        >
                                            {{ lastMonth?.title }}
                                        </span>
                                        <span
                                            class="text-14 text-customGray-dark font-Roboto"
                                        >
                                            {{ lastMonth?.discipline_name }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="flex flex-row sm:flex-col items-start gap-1 sm:items-center justify-center h-full"
                                >
                                    <span
                                        class="text-12 text-customGray-dark font-Roboto"
                                    >
                                        {{ i18n.dash_delivery_date }}
                                    </span>
                                    <div
                                        class="flex justify-center items-start gap-1"
                                    >
                                        <span
                                            class="iconify text-customGray-dark"
                                            data-icon="bx:calendar-alt"
                                            data-height="20"
                                            data-width="18"
                                        ></span>
                                        <span
                                            class="italic md:not-italic text-12 sm:text-14 text-customGray-dark"
                                        >
                                            {{
                                                formateData(lastMonth?.end_date)
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        class="flex items-center gap-1 sm:hidden"
                                    >
                                        <i
                                            class="iconify-inline font-semibold text-customGray-dark"
                                            data-icon="bi:clock"
                                            data-width="14"
                                        ></i>
                                        <span
                                            class="italic md:not-italic text-12 sm:text-14 font-Roboto text-customGray-dark"
                                        >
                                            {{
                                                formatTime(lastMonth?.end_date)
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div class="flex gap-3">
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="lastMonth?.pending"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 font-normal font-roboto text-customGray-dark"
                                        >
                                            {{ i18n.dash_pending_single }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="lastMonth?.delivered"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_handed_over }}
                                        </span>
                                    </div>
                                    <div class="flex flex-col">
                                        <app-progress-bar
                                            mode="determinate"
                                            [value]="lastMonth?.reviewed"
                                            strokeWidth="12"
                                            styleClass="circle-progress"
                                        ></app-progress-bar>
                                        <span
                                            class="text-12 text-customGray-dark font-normal font-roboto"
                                        >
                                            {{ i18n.dash_reviewed }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="!filteredContents?.reviewed_last_month?.length"
                    >
                        <div class="flex justify-center items-center p-6">
                            <span
                                class="text-18 text-center text-customGray-iconGray font-semibold font-Roboto"
                            >
                                {{
                                    i18n.dash_empty_message_reviewed_last_month
                                }}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
    </div>
</div>
