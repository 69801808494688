import { TimeService } from 'src/app/services/time-services.service';
import { DashboardService } from './../../dashboard.service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dashboard-feed',
    templateUrl: './dashboard-feed.component.html',
    styleUrls: ['./dashboard-feed.component.scss'],
})

export class DashboardFeedComponent {
    @Input() dashData: any = [];
    @Input() i18n: any = [];

    constructor(
        private dashboardService: DashboardService,
        private timeService: TimeService,
    ) { }

    getTime(date) {
        return this.timeService.getTime(date);
    }

    routeToLink(announcement: any) {
        this.dashboardService.toAnnouncements(announcement.discipline_external_id);
    }
}
