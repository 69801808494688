import { Location } from '@angular/common';
import {
    Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { BeforeUnloadService } from 'src/app/services/beforeUnload/before-unload.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { SectionService } from '../../../../section.service';
import { ContentTaskUploadService } from '../content-task-upload/content-task-upload.service';

@Component({
    selector: 'app-content-view-content-list',
    templateUrl: './content-view-content-list.component.html',
    styleUrls: ['./content-view-content-list.component.scss']
})
export class ContentViewContentListComponent implements OnInit, OnChanges {
    @Input() expanded: boolean;
    @Input() i18n: any;
    @Input() content_external_id: string;
    @Input() discipline_external_id: string;
    @Input() section_external_id: string;
    @Input() content: any;
    @Input() user: any;

    @Output() goToContentEmitter = new EventEmitter<any>();
    @Output() getConditionalContentTitle = new EventEmitter<any>();

    isLoading = false;
    max_width = '100%';
    contentTypes: any = [];

    constructor(
        private sectionService: SectionService,
        private platModalService: PlatformModalsService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private contentTaskUploadService: ContentTaskUploadService,
        private beforeUnloadService: BeforeUnloadService
    ) { }

    ngOnInit(): void {
        this.getSection(this.section_external_id);
        setTimeout(this.getScreenSize.bind(this), 500);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.content && this.content) {
                this.verifySectionLoaded(this.content);
            }
        }
    }

    setNameTypeContents(type: string): string {
        this.contentTypes = [
            { type: 'File', name: `${this.i18n.disciplines_page_file}` },
            { type: 'Youtube', name: `${this.i18n.disciplines_youtube}` },
            { type: 'TextPage', name: `${this.i18n.disciplines_text_page}` },
            { type: 'Link', name: `${this.i18n.disciplines_link}` },
            { type: 'Assignment', name: `${this.i18n.disciplines_docs_assignment}` },
            { type: 'Forum', name: `${this.i18n.disciplines_forum}` },
            { type: 'InternalQuiz', name: `${this.i18n.disciplines_upload_quiz}` },
            { type: 'External', name: `${this.i18n.disciplines_external}` },
            { type: 'Scorm', name: `${this.i18n.disciplines_scorm}` },
            { type: 'LTI', name: `${this.i18n.disciplines_page_lti}` },
            { type: 'Conference', name: `${this.i18n.disciplines_conference}` },
            { type: 'UploadAssignment', name: `${this.i18n.disciplines_upload_assignment}` },
            { type: 'DocsAssignment', name: `${this.i18n.disciplines_docs_assignment}` },
            { type: 'SheetsAssignment', name: `${this.i18n.disciplines_type_sheets_assignment}` },
            { type: 'UploadQuiz', name: `${this.i18n.disciplines_upload_quiz}` },
            { type: 'ExternalQuiz', name: `${this.i18n.disciplines_upload_quiz_external}` },
            { type: 'InternalConference', name: `${this.i18n.disciplines_conference}` },
        ];

        return this.contentTypes.find((contentType: any) => contentType.type === type).name;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize($event?) {
        const windowWidth = window.innerWidth;
        let offset = 120;
        if (windowWidth <= 1000 && windowWidth >= 375) {
            offset = 60;
        }
        if (windowWidth < 375) {
            offset = 40;
        }

        const getWidth = windowWidth - offset;
        this.max_width = `${getWidth}px`;
    }

    findContentByExternalId(external_id): any[] {
        let content: any = [];
        if (this.section && this.section.contents.length) {
            content = this.section.contents.find((content: any) => content.external_id == external_id);
        }
        return content;
    }

    goToContentByArrow(section_external_id: string, content_external_id: string) {
        const content: any = this.findContentByExternalId(content_external_id);
        // if not find content in current section verify
        // if its next or previous content and get
        // section from content to go
        if (!content) {
            this.section_external_id = section_external_id;
            this.getSection(section_external_id, content_external_id);
            return;
        }
        this.goToContent(content);
    }

    goToContent(content: { external_id: string; type: string; next_section: string }) {
        if (!content) {
            this.platModalService.toggle('message', this.i18n.disciplines_access_content_unauthorized, 'close');
            return;
        }

        if (this.content?.type === 'UploadAssignment' && this.checkGoToContent()) {
            return;
        }
        this.goToContentEmitter.emit(content.external_id);
        const queryParams = { section: this.section_external_id, type: content?.type };
        const url = this.router.createUrlTree([`../${content.external_id}`, queryParams], { relativeTo: this.route }).toString();

        this.location.go(url);
    }

    checkGoToContent(): boolean {
        if (!this.beforeUnloadService.canDeactivate()) {
            return true;
        }

        return false;
    }

    isLocked(status: string, begin: boolean | undefined) {
        if (status === 'LOCKED' && begin === true) {
            return false;
        }
        if (status === 'LOCKED' && begin === undefined) {
            return true;
        }
        if (status === 'PENDING' && begin === undefined) {
            return false;
        }
        if (status === 'COMPLETE' && begin === undefined) {
            return false;
        }
        if (status === 'PENDING' && begin === true) {
            return false;
        }
        if (status === 'COMPLETE' && begin === true) {
            return false;
        }
        return false;
    }

    contentStatus(status: string, begin: boolean | undefined) {
        if (status === 'LOCKED' && begin === true) {
            return './assets/svg/circle-empty.svg';
        }
        if (status === 'LOCKED' && begin === undefined) {
            return './assets/svg/padlock.svg';
        }
        if (status === 'PENDING' && begin === undefined) {
            return './assets/svg/circle-empty.svg';
        }
        if (status === 'COMPLETE' && begin === undefined) {
            return './assets/svg/circle-check.svg';
        }
        if (status === 'PENDING' && begin === true) {
            return './assets/svg/circle-empty.svg';
        }
        if (status === 'COMPLETE' && begin === true) {
            return './assets/svg/circle-check.svg';
        }

        return './assets/svg/circle-empty.svg';
    }

    getTitleFromConditionalContent = (content) => {
        let conditionalTitle = '';

        conditionalTitle = content?.conditional_content?.title;

        if (conditionalTitle !== undefined && content?.status === 'LOCKED') {
            return `${this.i18n.disciplines_release_activity} ${conditionalTitle}`;
        }
        return '';
    };

    emitTitleFromConditionalContent(content) {
        console.log('content ', content);
        const contentLockedFromSection: any = this.findContentByExternalId(content.external_id);
        console.log('contentLockedFromSection ', contentLockedFromSection);

        this.getConditionalContentTitle.emit(contentLockedFromSection?.conditional_content);
    }

    section: any;
    getSection(section_external_id, content_external_id?: string) {
        this.isLoading = true;
        this.sectionService.getSection(this.discipline_external_id, section_external_id).subscribe({
            next: (response) => {
                // Always brings only one
                this.section = response[0];
                this.contentTaskUploadService.section_ext_id$.next(this.section.external_id);
                if (content_external_id) {
                    this.goToContentByArrow(section_external_id, content_external_id);
                }
                this.isLoading = false;
            },
            error: (err) => {
                let isModalOpen: boolean | null = null;
                this.platModalService.getModalState('message').subscribe({
                    next: (statusModal) => {
                        isModalOpen = statusModal;
                    }
                });

                this.isLoading = false;

                if (!isModalOpen) {
                    if (err.hasOwnProperty('error')) {
                        if (err.error.hasOwnProperty('error')) {
                            this.platModalService.toggle('message', err.error.error, 'close');
                        }
                    }
                }

                if (err.status === 401 && err.error.error === 'user_does_not_belong_to_discipline') {
                    this.router.navigate(['../../']);
                }
            }
        });
    }

    verifySectionLoaded(content: any) {
        const timer = setInterval(() => {
            if (this.section) {
                clearInterval(timer);
                this.verifyCurrentContentStatus(content);
                this.emitTitleFromConditionalContent(content);
            }
            console.log('Timer ', this.section);
        }, 250);
    }

    verifyCurrentContentStatus(content: any) {
        const contentFromSection: any = this.findContentByExternalId(content.external_id);

        if (content.status !== contentFromSection?.status) {
            this.getSection(this.section_external_id);
        }
    }
}
