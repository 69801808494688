import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OverviewService {
    constructor(private http: HttpClient) { }

    createGoogleSpace(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/googlechat/create-space/`, params);
    }

    syncGoogleSpace(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/googlechat/sync-space/`, params);
    }
}
