import { NgModule } from '@angular/core';
import { LoadingModalModule } from 'src/app/components/modals/loading-modal/loading-modal.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { SportsComponent } from './sports.component';
import { SportsRoutingModule } from './sports-routing.module';

@NgModule({
    declarations: [
        SportsComponent
    ],
    imports: [
        SharedModule,
        LoadingModalModule,
        PermissionModule,
        SportsRoutingModule
    ]
})
export class SportsModule { }
