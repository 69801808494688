import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
    message: string;
    i18n: any = {};
    modalOpen = false;
    status: string;

    constructor(
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.sharedService.getModalInfoState()
            .subscribe((state) => {
                this.modalOpen = state.isOpen;
                this.status = state.status;

                if (state?.message) {
                    this.message = state.message;
                }
            });
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Disciplines');
    }

    closeModal() {
        this.sharedService.closeInfoModal();
    }
}
