import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class DisciplinesTitleResolver implements Resolve<void> {
    constructor(
        private sharedService: SharedService,
        private titleService: TitlePageService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        const i18n = { ...this.sharedService.getTranslationsOf('Disciplines') };

        const paths = [
            { url: '', title: i18n.disciplines_my_classes },
            { url: ':id', title: '$' },
            { url: 'overview', title: '$' },
            { url: 'content-tab', title: i18n.disciplines_site_title_content },
            { url: 'content-view', title: '$' },
            { url: 'content-assignment', title: i18n.disciplines_site_title_content },
            { url: 'announcements', title: i18n.disciplines_site_title_announcements },
            { url: 'forum', title: i18n.disciplines_site_title_forum },
            { url: 'question-bank', title: i18n.disciplines_site_title_question_bank },
            { url: 'discipline-settings', title: i18n.disciplines_site_title_settings },
            { url: 'grades', title: i18n.disciplines_site_title_grades },
            { url: 'reports', title: i18n.disciplines_site_title_reports },
            { url: 'assessments', title: i18n.disciplines_assessments },
        ];

        this.titleService.resolveTitle(route, paths);
    }
}
