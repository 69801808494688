import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-select-discipline-classroom-box',
    templateUrl: './select-discipline-classroom-box.component.html',
    styleUrls: ['./select-discipline-classroom-box.component.scss'],
})
export class SelectDisciplineClassroomBoxComponent {
    @Input() discipline: any;
    @Input() selectedDiscipline: any;
    @Input() modelForm: any; // Certifique-se de que modelForm seja uma propriedade de entrada
    @Input() type: any;

    showTooltip = false;

    @Output() formDisciplineData = new EventEmitter();

    checkDisciplineChange() {
        this.formDisciplineData.emit(this.discipline.external_id);
    }

    isSelected() {
        if (!this.selectedDiscipline) {
            return false;
        }

        return this.selectedDiscipline.includes(this.discipline.external_id);
    }

    disciplineTitle(title: string) {
        if (title.length > 50) {
            return `${title.substring(0, 50)}...`;
        }
        return title;
    }
}
