import { finalize } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, Inject, ViewChild, Renderer2, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConferenceCheckerService } from 'src/app/services/conference-checker.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ConfirmationService } from 'primeng/api';
import { ConditionalAndCriteriaService } from 'src/app/components/conditional-advance/conditional-and-criteria.service';
import { Subscription } from 'rxjs';
import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { GoogleGetFileService } from 'src/app/services/google-get-file-service.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { DOCUMENT } from '@angular/common';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';
import { SectionService } from '../../section.service';
import { ContentService } from '../../content.service';
import { ContentQuizService } from './components/content-view-quiz/content-quiz.service';
import { DisciplineDetailService } from '../../../disciplines-detail/discipline-detail.service';
import { HomeService } from 'src/app/pages/home/home.service';
import { ContentViewContentListComponent } from './components/content-view-content-list/content-view-content-list.component';
import { collapseAnimation } from 'src/app/utils/animations/animations';
import { TimeService } from 'src/app/services/time-services.service';
import { ForumService } from '../../../forum/forum.service';
import { CanComponentDeactivate } from '../../content.guard';
import { ContentTaskUploadComponent } from './components/content-task-upload/content-task-upload.component';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';


@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss'],
  providers:[ConfirmationService],
  animations: [
    collapseAnimation('250')
  ],
})
export class ContentViewComponent implements OnInit, AfterViewChecked ,OnDestroy, CanComponentDeactivate {
  @ViewChild('textDescription') textDescription: ElementRef;
  @ViewChild('iframe') iframe: Renderer2
  @ViewChild('contentViewContentList', { static: false }) contentViewContentList!: ContentViewContentListComponent;
  @ViewChild('contentTaskUpload') contentTaskUpload!: ContentTaskUploadComponent;
  collapseOption: boolean = false;
  collapseOptionComments: boolean = false;
  discipline_external_id: any;
  section_external_id: any;
  content_external_id: any;
  type: any;
  url: any = '';
  content: any;
  sections: any = [];
  contents: any = [];
  expanded: boolean = false;
  showModal: boolean;
  reportBugForm: UntypedFormGroup;
  hasIframe: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  videoConference = { text: [], video: [] };
  user: any;
  modalOpen$ = this.sharedService.modalOpen$;
  filesToUploadModal: any[];
  fileTypeToModalUpload: string = '';
  isLoading: boolean = false;
  timerSubscription: Subscription;
  currentLanguage: any;
  i18n: any = [];
  maxFiles: number;
  loadingContent: boolean = false;
  isLoadingUrl: boolean = false;
  permissionQuestionnaire: any = {};
  isNextContentPermission: boolean | null = false;

  // Adicionado
  isGapiLoaded = this.googleGetFileService.isGapiLoaded;
  getDownload: boolean = false;
  isGapiLoadedSubscription: Subscription;
  indexFile: number = 0;
  file: any = {};

  startDate: any
  endDate: any;
  getDataFromContentView: any;

  counter: any = 0
  timer: any = []
  timeStatus: any;

  isConcludedStudentNotification: string;
  errorRequest: boolean = false;
  isMobile: boolean = false;

  checker: any;

    page = 1;
    per_page = 10;

  //getterCounterSub: Subscription;

    attemptsGetVisualizationWithBackoff = 0;
    hasVisualizationWithBackoff = false;
    private timeoutIdBackoff;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private conferenceCheckerService: ConferenceCheckerService,
    private sectionService: SectionService,
    private conclusionCriteriaService: ConditionalAndCriteriaService,
    private confirmationService: ConfirmationService,
    private contentService: ContentService,
    private domSanitizer: DomSanitizer,
    private FormBuilder: UntypedFormBuilder,
    private hostElement: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private googleGetFileService: GoogleGetFileService,
    private platModalService: PlatformModalsService,
    private restoreFormattingService: RestoreFormattingService,
    private quizService: ContentQuizService,
    private disciplineDetailsService: DisciplineDetailService,
    private homeService: HomeService,
    private cd: ChangeDetectorRef,
    private timeService: TimeService,
    private forumService: ForumService,
    private localStorageService: LocalStorageService

  ) {
    this.checker = setInterval(() => {
        // the following is required, otherwise the view will not be updated
        this.cd.markForCheck();
    }, 75);
  }

  elem;

  ngOnInit(): void {
    this.sharedService.removeGlobalScript$.next(true);
    this.applyCustomContentScripts();
    this.getTranslations();
    this.getUrlParams();
    this.VerifyNotPermissionsQuestionnaire();
    this.createReportBugForm();
    this.getUser();
    this.checkIsMobile();

    this.isGapiLoadedSubscription =
    this.googleGetFileService.isGapiLoaded.subscribe((status: any) => {
      this.isGapiLoaded = status;
      if (this.getDownload) {
        this.downloadFile();
      }
    });
  }
    
  ngAfterViewChecked(): void {
    this.getStateExpanded();
    this.cd.detectChanges();
    
    if (this.content?.description) {
      this.restoreFormattingService.restoreFormatting(this.textDescription);
    }
  }

  canDeactivate(): boolean {
    if (this.contentTaskUpload) {
        return this.contentTaskUpload.canDeactivate();
      }
      // Default to allowing deactivation if childComponent is not available
      return true;
  }

  onLoad(){
    this.elem = document.querySelector('#notFileIframe');
  }

  get titleValid() {
    return this.reportBugForm.get('title');
  }

  get description() {
    return this.reportBugForm.get('description');
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Disciplines'),
      ...this.sharedService.getTranslationsOf('Users'),
      ...this.sharedService.getTranslationsOf('Modal'),
      ...this.sharedService.getTranslationsOf('AdvanceAndCriterion'),
    };
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  getReloadStatus() {
    this.getContent(this.discipline_external_id, this.section_external_id, this.content_external_id);
  }

  criteriaType(completion_rule: string) {
    this.conclusionCriteriaService.receivesContent(
      this.discipline_external_id,
      this.content_external_id
    );
    this.conclusionCriteriaService.whatIsTheCriterion(completion_rule);
    if(completion_rule == 'TIME') {
      this.timeCriteriaCounter(this.discipline_external_id, this.content_external_id);
    }
  }

  getUser() {
    this.sharedService.getUser().subscribe({
      next: (user) => {
        this.user = user;
      }
    });
  }

  getUrlParams() {
    
    this.route?.parent?.parent?.params?.subscribe((params: Params) => {
      this.discipline_external_id = params.id;
    });

    this.route?.params?.subscribe((params: Params) => {
      
      this.section_external_id = params.section;
      if (params.id != this.content_external_id) {
        this.goToContentEmitter(params.id);
      }
    });

  }
  
  createReportBugForm(): void {
    this.reportBugForm = this.FormBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  nextContent() {
    this.contentViewContentList.goToContentByArrow(this.content?.next_section, this.content?.next_content);
  }


  previousContent() {
    this.contentViewContentList.goToContentByArrow(this.content?.previous_section, this.content?.previous_content);
  }

  collapseChat = false;
  toggleVideoConferenceChatVisibility() {
    this.collapseChat = !this.collapseChat;
  }

  getStateExpanded() {
    this.homeService.getExpanded$.subscribe({
      next: (reponse: boolean) => {
        this.expanded = reponse;
        this.homeService.setExpanded$.next(this.expanded);
      }
    })
  }

  expandView(): void {   
    this.expanded = !this.expanded;
    this.homeService.getExpanded$.next(this.expanded);
  }

  sanitizeURL(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getDataEndTime(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
  
  returnArrayValid(data: []) {
    return data?.filter((data) => data !== null &&  data !== undefined);
  }

  goToContentEmitter(content_external_id: any){
    this.content_external_id = content_external_id
    this.getContent(
      this.discipline_external_id, 
      this.section_external_id, 
      content_external_id
    );
  }

  is_locked: boolean = false;
  isLoadingDocsAndSheets: boolean = false;
  errorScorm: boolean = false;
  getContent(
    discipline_external_id: string,
    section_external_id: string,
    content_external_id: string
    ) {
    this.isLoadingUrl = true;
    this.hasVisualizationWithBackoff = true;

    // Necessário para garantir que o loading não seja chamado mais de uma vez.
    if (this.attemptsGetVisualizationWithBackoff === 0) {
        this.toggleLoading();
    }

    this.stopTimer()
    this.contentService
    .getContent(
      discipline_external_id,
      section_external_id,
      content_external_id
      )
      .subscribe({
        next: (content) => {
          this.clearVisualizationBackoff();
          this.attemptsGetVisualizationWithBackoff = 0;

          this.collapseOptionComments = false;
          this.content = [];
          this.is_locked = false;
          this.url = '';
          this.errorRequest = false;

          this.disciplineDetailsService.titleName$.next(content.title);
          
          if (content.status == 'LOCKED') {
            this.is_locked = true;
            this.content = content;
            return
          }

          this.content = content;
          
          this.getDataEndTime(this.content.start_date, this.content.end_date);
          this.isConcludedStudentNotification = this.content.status;
          this.criteriaType(this.content.completion_rule);
          this.conclusionCriteriaService.receivesContent(
            discipline_external_id,
            content_external_id
          );

          this.getUserDataComments(this.content.userData)

          this.hasIframe = false;

          this.contents = [];


          if (content.type == 'File') {
            this.hasIframe = true;
            content.contentSpecific.files.forEach(element => {
                if (element.trashed) {
                    
                    return
                }
              element.height = '480px';
              // let drive_id = element.public_url.split('?id=').pop();
              // let url = `https://drive.google.com/file/d/${drive_id}/preview`;
              let url = element.public_url;
              element.url = url;
              if (element.mime_type == 'application/pdf') {
                element.height = '680px';
              }
              if (element.mime_type.includes('image')) {
                element.height = '300px';
              }            
            });
          }

          if (content.type === 'Link') {
            this.url = content?.contentSpecific?.link_site;
            
            if (content?.contentSpecific?.isIframable) {
              this.hasIframe = true;

              const getIframe = setInterval(() => {
                let iframe = this.hostElement.nativeElement.querySelector('iframe');
                if (iframe) {
                  iframe.src = this.url;
                  clearInterval(getIframe);
                }
              }, 200);
            } else {
              this.platModalService.toggle('decision', 'generic_subject_link_content', {
                forward: () => {
                  window.open(this.url, '_blank')?.focus();
                },
                finally: () => {
                  this.platModalService.close('decision');
                },
              });
            }
          }

          if (content.type === 'Youtube') {
            this.url = content?.contentSpecific?.link_site;
            if (!content?.contentSpecific?.isIframable) {
              this.platModalService.toggle('decision', 'generic_subject_link_content', {
                forward: () => {
                  window.open(this.url, '_blank')?.focus();
                },
                finally: () => {
                  this.platModalService.close('decision');
                },
              });
            }
          }

          if (content.type === 'DocsAssignment' || content.type === 'SheetsAssignment') {
            if (content?.userData) {
              this.url = content?.userData?.file?.public_url;
              this.isLoadingUrl = false;
            }

          }

          if (content.type === 'Scorm') {
            this.hasIframe = true;
            this.isLoadingUrl = true;
            const width = '100%';
            const height = '500px';

            this.contentService
              .getScormUrl(
                discipline_external_id,
                section_external_id,
                content_external_id,
                width,
                height,
              )
              .subscribe({
                  next:(loadUrl: { loadData }) => {
                    const iframe = this.hostElement.nativeElement.querySelector('iframe');
                    const url = loadUrl?.loadData ? loadUrl.loadData : '';
                    
                    iframe.src = url;
                    this.isLoadingUrl = false;
                  },
                  error:(err)=>{
                    this.errorScorm = true;
                    this.isLoadingUrl = false;
                    this.platModalService.toggle('message', err.error.error, 'close');
                  }
              });
          }

          if (content.type === 'TextPage') this.hasIframe = false;

          if (content.type === 'LTI') {
            
            this.url = content?.contentSpecific?.lti_url;
            
            if (content?.contentSpecific?.isIframable) {
              this.hasIframe = true;
              setTimeout(() => {
                const iframe = this.hostElement.nativeElement.querySelector('iframe');
                iframe.src = this.url;
              }, 100);
            } else {
              // TODO abrir em outra janela
              this.platModalService.toggle('decision', 'generic_subject_link_content', {
                forward: () => {
                  window.open(this.url, '_blank')?.focus();
                },
                finally: () => {
                  this.platModalService.close('decision');
                },
              });
            }
          }

          if (content.type === 'UploadAssignment') {
            this.filesToUploadModal = [];
            if (content.userData !== null) {
              if (content.userData.files.length !== 0) {
                this.filesToUploadModal = content?.userData?.files;
              }
            }

            this.fileTypeToModalUpload = 'docs';
            this.maxFiles = parseInt(content.contentSpecific.maxFiles, 10);
          }

          if (content.type === "InternalConference") {
            this.hasIframe = true;
            // this.url = content.contentSpecific.conference.url
            this.getInternalConferenceUrl(content);

          }

          if (content.type === 'UploadQuiz') {
            this.hasIframe = true;
            this.getDataFromContentView = content?.contentSpecific;

          }

          if (content.type === 'Conference') {
            this.receiveRecordingsTextFile(content?.contentSpecific?.recordings?.text);
          }

          if (content.type === 'Forum' || content.attached_forum.forum_post_id) {
            this.getForumPost();
          }
        },
        error: (error) => {
            if (error?.error?.error === 'build_content_in_progress' && this.attemptsGetVisualizationWithBackoff < 6 && this.hasVisualizationWithBackoff) {
                this.attemptsGetVisualizationWithBackoff += 1;
                // Calcula o tempo de espera exponencial em milissegundos
                const delay = 2 ** this.attemptsGetVisualizationWithBackoff * 1000;

                this.timeoutIdBackoff = setTimeout(() => {
                    this.getContent(discipline_external_id, section_external_id, content_external_id);
                }, delay);

                return;
            }
            
            this.errorRequest = true;
            this.toggleLoading();
            this.isLoadingUrl = false;
            let messageError = '';
            // Reseta o contador se o status mudar ou o máximo de tentativas for atingido
            this.attemptsGetVisualizationWithBackoff = 0; 
          
          if (error?.error?.error) {
            switch (error.status) {
              case 422:
                if (Array.isArray(error.error.error)) {
                  error.error.error.forEach((element) => {
                    if (Array.isArray(element)) {
                      element.forEach((err) => { messageError += `<p>${err}</p><br>` })
                    } else {
                      messageError = element;
                    }
                  })
                }
                break
              default: messageError = error.error.error;
            }

            if (error.error.error === 'build_content_in_progress' || error.error.error === 'build_content_failed') {
                messageError = error.error.error;
            }
          }

          if (this.i18n.hasOwnProperty(messageError)) {
            messageError = this.i18n[messageError]
          }

            let isModalOpen: boolean | null = null;

            this.platModalService.getModalState('message').subscribe({
                next: (statusModal) => {
                    isModalOpen = statusModal;
                }
            });

            if (!isModalOpen) {
                this.platModalService.toggle('message', messageError, 'close');
            }

            if (error.status === 401 && error.error.error === 'user_does_not_belong_to_discipline') {
                this.router.navigate(['../../']);
                return;
            }
        },
        complete: () => {
          // Por motivos do Fórum chamar uma nova requisição e o loading finalizar após a última requisição, há esta validação 
          if (this.content.type !== 'Forum' && !this.content?.attached_forum?.forum_post_id) {
            this.toggleLoading();
          }

          this.isLoadingUrl = false;
        },
      });
  }

  feedback: any;
  hasFeedBack: boolean = false;
  getUserDataComments(userData){
    this.feedback = {};
    this.hasFeedBack = false;
    if (userData == null || userData?.feedback?.length == 0) {
      this.hasFeedBack = false;
      return
    }
    this.hasFeedBack = true;
    this.feedback = userData?.feedback;

  }

  includeInContentObject(files){
    if (files.length) {
        files.forEach(file => {
            this.content?.contentSpecific?.recordings.video.push(file);
        });
    }
  }


  isAddingRecordingText: boolean = false;
  recordingsTextFile;
  receiveRecordingsTextFile(file){
    if (file.length) {
        this.recordingsTextFile = file;
    }
  }

  addRecording(files: any){
    this.isAddingRecordingText = true;
    const idOfFilesFromEvent = files.map((recording) => recording.id);
    const urlParams = {
        discipline_id: this.discipline_external_id,
        content_id: this.content_external_id,
        section_id: this.section_external_id,
    }
    this.conferenceCheckerService.addRecording({
        ...urlParams,
        body: { files: idOfFilesFromEvent },
      }).subscribe({
        next: (res) => {
            console.log('res ', res);
            this.isAddingRecordingText = false;
            this.recordingsTextFile = [];
            files.forEach(file => {
                this.content?.contentSpecific?.recordings?.text.push(file)
            });
        },
        error: () => {

        }
    })
  }

  conditionalContent: any;
  getConditionalContent(contentLockedFromSection){
    this.conditionalContent = contentLockedFromSection;
    
  }

  goToContent(section_external_id, content_external_id){
    this.contentViewContentList.goToContentByArrow(section_external_id, content_external_id)
  }

  async getInternalConferenceUrl(content){
    let token = localStorage.getItem('token');

    if (content.contentSpecific.conference.url) {
      const response = await fetch(content.contentSpecific.conference.url, {
        method: 'GET',
        headers: {
          'Authorization': "Bearer " + token
        }
      })

      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      if (response) {

        this.url = urlObject;
        setTimeout(() => {
          const iframe =
          this.hostElement.nativeElement.querySelector('iframe');
          iframe.src = this.url ;
          let status = '';
          let origin = window.location.origin;

          let checkStatusConference = setInterval(() => {
            window.addEventListener("message", (e)=>{
              if (e.origin !== origin) {
                return;
              }

              status = e.data;

              if (status == 'finish') {
                clearInterval(checkStatusConference);
                this.returnRouteContent();
              }

            });

          }, 5000);
        }, 100);

      }
    }

  }


  prepareParamateresToUploadModal(fileType: string) {
    fileType === 'video'
      ? (this.fileTypeToModalUpload = 'video')
      : (this.fileTypeToModalUpload = 'document');
  }

  postBugReport(): void {
    const params = {
      title: this.reportBugForm.controls.title.value,
      description: this.reportBugForm.controls?.description?.value,
    };
    // TO DO API POST
  }

  

  returnRouteContent(): void {
    this.router.navigate([`../../content-tab`], {
      relativeTo: this.route,
    });
  }

  toggleLoading(): void {
    this.isLoading = !this.isLoading;
    this.loadingContent = !this.loadingContent;
  }

  openModal(): void {
    this.reportBugForm.controls['title'].setValue('');
    this.reportBugForm.controls['description'].setValue('');
    this.showModal = !this.showModal;
  }

  toggleCollapseOption() {
    this.collapseOption = !this.collapseOption;
  }

  getTime(date) {
    return this.timeService.getTime(date);
  }

  closeModal(): void {
    this.showModal = false;
  }

  returnIconMaterialsSupport(mime_type: string) {
    return mime_type.includes('image')
      ? 'LTI'
      : mime_type.includes('video')
      ? 'Youtube'
      : mime_type.includes('text')
      ? 'TextPage'
      : 'FilePDF';
  }

  downloadFile() {
    let filesInfo = {
      url: '',
      mime_type: '',
    };

    // identifica se é um arquivo do google apps
    if (this.file?.mime_type.includes('google-apps')) {
      filesInfo.mime_type =
        this.sharedService.selectMimeTypeToExport(this.file) || '';
    }

    filesInfo.url = this.file.path;

    if (this.isGapiLoaded) {
      this.googleGetFileService
        .downloadFile(filesInfo.url)
        .subscribe({
          next: (response: any) => {
            let url: any;

            if (filesInfo.mime_type !== '') {
              url = response.result.exportLinks[filesInfo.mime_type];
            } else {
              url = response.result.webContentLink;
            }

            window.open(url);
            this.getDownload = false;
          },
          error: (err) => {
            if (err.status == 404) {
              this.platModalService.toggle(
                'message',
                'Sem permissão para acessar o arquivo',
                'close'
              );
            } else {
              setTimeout(() => this.getGoogleFile(), 1000);
            }
          }
        })
    }
  }

  VerifyNotPermissionsQuestionnaire(): void {
    if (localStorage.getItem('permissions')) {
      const permissions = JSON.parse(localStorage.getItem('permissions')!)
      this.permissionQuestionnaire = permissions.questionnaire;
    }
  }

  // Inicia serviço do GoogleDrive para baixar o arquivo
  getGoogleFile(file?) {
    this.file = file;
    this.getDownload = true;
    this.googleGetFileService.initGoogleDriveGapi();
  }

  changeFile(index: number) {
    this.indexFile = index;
    document.getElementsByClassName('router-outlet')[0].scrollTop = 0;
  }

  //Funções do critério de conclusão TEMPO
  timeCriteriaPost(
    discipline_external_id: string,
    content_ext_id: string,
    params: any,
    ) {
    this.conclusionCriteriaService
    .postCompletedContent(
      discipline_external_id,
      content_ext_id,
      params,
      )
    .subscribe(
      (data) => {
        this.timeStatus = data?.status
        if (this.timeStatus == 'COMPLETE') {
          this.content = {...this.content, status: 'COMPLETE'};
          this.stopTimer();
        }
      },
      () => this.stopTimer(),
      () => {},
    )
  }

  timeCriteriaCounter(
    discipline_external_id: string,
    content_ext_id: string,
    ) {
      let params = {}
      let counter = 0;
      this.timer = setInterval(() => {
        counter++;
        this.timeCriteriaPost(discipline_external_id, content_ext_id, params)
        this.counter = counter
      }, 60000);
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  studentNotification() {
    if(this.isConcludedStudentNotification = 'PENDING') {
      this.conclusionCriteriaService.studentNotificationMethod();
      this.getReloadStatus();
      this.isConcludedStudentNotification = 'COMPLETE'
    }
  }

  

  getTraductionsCompletionRule(type: string): string | undefined {
    if (type === null || type === undefined) return '';
    
    if (type === 'ACCESS') return this.i18n.adv_and_crit_access;
    if (type === 'TIME') return this.i18n.adv_and_crit_time;
    if (type === 'ATTEMPT_SUBMISSION') return this.i18n.adv_and_crit_attempt_sub;
    if (type === 'STUDENT_NOTIFICATION') return this.i18n.adv_and_crit_std_notification;
  }

  getTraductionTypeContent(type: string): string | undefined {
    if (type === null || type === undefined) return '';

    // Assignment 
    if (type === 'Assignment' || type === 'UploadAssignment') {
      return this.i18n.disciplines_type_upload_assignment;
    }

    // DocsAssignment 
    if (type === 'DocsAssignment') {
      return this.i18n.disciplines_type_docs_assignment;
    }

    // SheetsAssignment 
    if (type === 'SheetsAssignment') {
      return this.i18n.disciplines_type_sheets_assignment;
    }

    // File 
    if (type === 'File') {
      return this.i18n.disciplines_page_file;
    }

    // Youtube 
    if (type === 'Youtube') {
      return this.i18n.disciplines_youtube;
    }

    // TextPage 
    if (type === 'TextPage') {
      return this.i18n.disciplines_text_page;
    }

    // Link 
    if (type === 'Link') {
      return this.i18n.disciplines_link;
    }

    // Forum 
    if (type === 'Forum') {
      return this.i18n.disciplines_select_tab_forum;
    }

    // Conference 
    if (type === 'Conference' || type === 'InternalConference') {
      return this.i18n.disciplines_conference;
    }

    // Quiz 
    if (type === 'InternalQuiz') {
      return this.i18n.disciplines_upload_quiz;
    }
  
    // Quiz 
    if (type === 'UploadQuiz') {
      return this.i18n.disciplines_upload_quiz_external;
    }
   
    // Scorm 
    if (type === 'Scorm') {
      return this.i18n.disciplines_scorm;
    }

    // LTI 
    if (type === 'LTI') {
      return this.i18n.disciplines_page_lti;
    }
  }

  // Função para formatar a data removendo os segundos e timeZone...
  searchFirstPathDate(text: any): string {
    if (!text || text === null || text === undefined) return '';
    
    let primeiraParte = '';
    let index = 0;

    // Encontrar o índice do segundo ':' na string
    for (let i = 0; i < text.length; i++) {
      if (text[i] == ':' && index < 2) {
        index++;
        if (index == 2) {
          primeiraParte = text.slice(0, i);
          break;
        }
      }
    }

    // Se o segundo ':' não for encontrado, retornar a string original
    if (primeiraParte === '') {
      primeiraParte = text;
    }

    return primeiraParte;
  }
  
  checkIsMobile(): void {
    const userAgent = window.navigator.userAgent;
    if (/Mobi/i.test(userAgent)) {
      // The user is on a mobile device (Android, iOS or other)
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  getForumPost(): void {
    let forum_post_id = null;
    let isForum = false;

    // Verifica se é fórum
    if (this.content?.contentSpecific?.forum_post_id) {
        forum_post_id = this.content.contentSpecific.forum_post_id;
        isForum = true;
    }
    
    // Verifica se é fórum vinculado
    if (this.content?.attached_forum?.forum_post_id) {
        forum_post_id = this.content.attached_forum.forum_post_id
    }

    this.forumService.getForumPost(forum_post_id, this.page, this.per_page, 'DESC').subscribe({
        next: (response) => {
            if (isForum) {
                this.content.contentSpecific = response;
            } else {
                this.content.attached_forum = response;
            }
        },
        error: () => { },
        complete: () => {
            this.toggleLoading();
        }
    })
  }

  applyCustomContentScripts() {
    let advancedOptions;

    if (this.localStorageService.getLocalStorageItem('advanced')) {
        advancedOptions = JSON.parse(localStorage.getItem('advanced') || '');
    }

    if (advancedOptions) {
        if (advancedOptions.content_screen_script !== '') {
            this.sharedService.insertHTMLFromString(advancedOptions.content_screen_script, 'content_script');
        }
    }
  }

  removeCustomContentScripts() {
    if (this.sharedService.checkIfScriptsExists('content_script')) {
        this.sharedService.removeScript('content_script');
    }
  }

  ngOnDestroy(): void {
    this.removeCustomContentScripts();
    this.sharedService.removeGlobalScript$.next(false);
    this.stopTimer();
    this.isGapiLoadedSubscription?.unsubscribe();
    this.disciplineDetailsService.titleName$.next('');
    this.clearVisualizationBackoff();
  }

  clearVisualizationBackoff(): void {
    this.hasVisualizationWithBackoff = false;

    if (this.timeoutIdBackoff) {
        clearTimeout(this.timeoutIdBackoff); // Limpa o timeout
        this.timeoutIdBackoff = null; // Zera o identificador para evitar chamadas desnecessárias
    }
  }
}
