<div id="app__container" [ngClass]="{'impersonating': userIsImpersonating }" *ngIf="userHasToken" [style]="addStyle">
  <app-header class="header" (onToggleMenu)="toggleMenuOnAside()"></app-header>
  <app-sidebar class="sidebar" (closeMenu)="toggleExpandBtnOnHeader()"></app-sidebar>
  <app-push-notification></app-push-notification>
  <div class="router-outlet">
    <app-third-party-cookie class="third-cookie"></app-third-party-cookie>
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="!userHasToken"></router-outlet>

<!-- o isTranslationsUploaded$ abaixo funciona somente para tela de login -->
<!-- o isTranslationsUploaded abaixo funciona para o resto da aplicação (obs: isso pode ser melhor implementado no futuro)-->
<ng-container *ngIf="(isTranslationsUploaded$ | async) || isTranslationsUploaded ">
  <app-loading-modal></app-loading-modal>
  <app-impersonfication></app-impersonfication>
  <app-decision-modal></app-decision-modal>
  <app-messenger-toast></app-messenger-toast>
  <app-messenger-toast-base></app-messenger-toast-base>
  <app-message-modal></app-message-modal>

  <div [ngClass]="{'hidden': !canAccessibility }">
      <app-vlibras></app-vlibras>
      <app-user-way></app-user-way>
  </div>
</ng-container>

<app-cookies-enabled-modal></app-cookies-enabled-modal>

