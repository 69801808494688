<div class="relative flex flex-col gap-1">
    <h2 *ngIf="title" class="text-14 text-customBlue-default font-Roboto">
        {{ title }}
    </h2>
    <div class="overflow-x-hidden whitespace-nowrap" #carousel>
        <div class="flex gap-3">
            <div class="inline-block" *ngFor="let item of items">
                <button
                    class="bg-customWhite-default px-4 py-2 rounded-10 hover:bg-customBlue-royal hover:bg-opacity-5"
                    [ngClass]="{
                        'bg-customBlue-royal bg-opacity-5 border border-customBlue-royal':
                            itemActive === item.id,
                        'opacity-50 cursor-not-allowed': checkDisabled(item.id)
                    }"
                    [disabled]="checkDisabled(item.id)"
                    (click)="selectItem(item.id)"
                >
                    <span class="text-16 text-customBlue-default">
                        {{ item.description }}
                    </span>
                </button>
            </div>
        </div>
    </div>
    <button
        *ngIf="showArrows"
        class="absolute right-0 top-30 transform rounded-full z-10 bg-customWhite-default"
        (click)="scrollRight()"
    >
        <span
            class="iconify text-customBlue-royal text-32"
            data-icon="fluent:chevron-circle-right-20-filled"
        ></span>
    </button>
</div>
