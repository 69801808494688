<div style="height: 500px;" >
    <ng-container *ngIf="srcIframe && !isLoading">
        <iframe 
            class="w-full"
            style="height: calc(100vh - 180px)" 
            [src]="srcIframe | safeUrl"
        ></iframe>
    </ng-container>

    <div 
        *ngIf="isLoading"
        style="position: absolute;transform: translateX(50%);top: 50%;left: 50%;" 
        class="p-3 rounded-md bg-customWhite-default"
    >
      <app-bullets-loader></app-bullets-loader>
    </div>
</div>
