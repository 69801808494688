import { SharedService } from 'src/app/shared/shared.service';
import { Level } from './../../../../section.service';
import {
  Criterion,
  
} from 'src/app/pages/disciplines/components/content/section.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { ContentSharedService } from '../../../../content-shared.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  selector: 'app-create-rubrica',
  templateUrl: './create-rubrica.component.html',
  styleUrls: ['./create-rubrica.component.scss'],
})
export class CreateRubricaComponent implements OnInit {
  listCriterion: Criterion[] = [];
  params: Params;
  maxPointsParams: number = 0;
  maxPoints: number = 0;
  i18n: any = {};

  formPoints: FormGroup;

  subscritption: Subscription[] = [];
  decimal_places: number = 0;
  currentLanguage: string | null = '';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private FormBuilder: FormBuilder,
    private localStorageService: LocalStorageService

  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getTranslations();
    this.returnListCriterionMock();
    this.changeMaxPoints();
  }

  createForm(){
    this.formPoints = this.FormBuilder.group({
      rubricaPointsInput: ['', Validators.required],
    });
  }

  get rubrica_points_input (): number{
    let rubricaPoints  = this.formPoints.get('rubricaPointsInput')?.value.toString();

    const decimalIndex = rubricaPoints.search(/[,\.]/);
    if (decimalIndex !== -1) {
      rubricaPoints = rubricaPoints.replace(',', '.');
    }
    if (rubricaPoints == '') {
      rubricaPoints = 0
    }
    return parseFloat(rubricaPoints);
  }
  
  getTranslations() {
    this.i18n = this.sharedService.getTranslationsOf('Disciplines');
    this.currentLanguage = localStorage.getItem('currentLanguage');
    if (this.currentLanguage == null) {
      this.currentLanguage = 'pt-BR';
    }
  }

  addCriterion(): void {
    this.listCriterion.push({
      name: '',
      order: 0,
      deleted: false,
      levels: [
        {
          name: '',
          description: '',
          points: 0,
          order: 0,
          deleted: false,
        },
      ],
    });
  }

  addLevel(indexCriterion: number): void {
    this.listCriterion[indexCriterion].levels.push({
      name: '',
      description: '',
      points: 0,
      order: 0,
      deleted: false,
    });
  }

  deleteLevel(indexCriterion: number, indexLevels: number): void {
    this.listCriterion[indexCriterion].levels[indexLevels].deleted = true;
    
    const hasAnylevel: boolean =  this.listCriterion[indexCriterion].levels.some((level)=> !level.deleted)
    
    if (!hasAnylevel) {
      this.listCriterion[indexCriterion].deleted = true;
    }

    this.changeMaxPoints();

    const hasAnyCriterion: boolean = this.listCriterion.some((criterion: any)=> !criterion.deleted);

    if (!hasAnyCriterion) {
      this.addCriterion();
      this.addLevel(0);

    }

  }

  onSubmit(form: NgForm): void {
    
    if (form.valid) {
      // Order position
      this.listCriterion.map((el, index: number) => {
        el.order = index + 1;
        el.levels.map((e, i: number) => {
          e.order = i + 1;
          e.points = this.transformToNumeric(e.points.toString());
        });
      });

      // if (this.listCriterion.filter((e) => e.deleted === undefined)) {
      //   this.listCriterion.map((e) => {
      //     e['deleted'] = false;
      //     if (e.levels.filter((el) => el.deleted === undefined)) {
      //       e.levels.map((el) => (el['deleted'] = false));
      //     }
      //   });
      // }
      localStorage.setItem('criterionsList', JSON.stringify(this.listCriterion));
      this.backward();
    }
    return;
  }

  backward(): void {
    this.route.params.subscribe((params: Params) => {
      let queryParams = {
        section: params.section,
        section_title: params.section_title,
        type: params?.type,
        rubric_edited: 1
      };

      if (params?.edit === '1') {
        (queryParams['content'] = params.content),
          (queryParams['edit'] = params?.edit);
      }

      this.router.navigate(['../assignment', queryParams], {
        relativeTo: this.route,
      });
    });
  }

  // Loads an empty criterion, when it is the user's first time
  returnListCriterionMock(): void {
    let listCriterionSetup;
    let decimal_places_mask;

    if (this.localStorageService.getLocalStorageItem('criterionsList')) {
        listCriterionSetup = JSON.parse(localStorage.getItem('criterionsList') || '');
    }

    if (this.localStorageService.getLocalStorageItem('decimal_places_mask')) {
        decimal_places_mask = JSON.parse(localStorage.getItem('decimal_places_mask') || '');
    }

    if (decimal_places_mask !== null) {
      this.decimal_places = Number(decimal_places_mask);
    }
    if (listCriterionSetup == null || listCriterionSetup.length == 0) {
      this.formPoints.controls.rubricaPointsInput.setValue(0);
      this.listCriterion.push({
        name: '',
        order: 0,
        deleted: false,
        levels: [
          {
            name: '',
            description: '',
            points: 0,
            order: 0,
            deleted: false,
          },
        ],
      });
    } else {

      listCriterionSetup.forEach(criterion => {
        let max_rubric_points = '0';
        if(
            criterion?.max_rubric_points !== null &&
            criterion?.max_rubric_points !== undefined
        ) {
          max_rubric_points = criterion?.max_rubric_points;
        }
        if (this.currentLanguage !== 'en') {
          
          max_rubric_points = max_rubric_points.toString().replace('.', ',')
        }
        this.formPoints.controls.rubricaPointsInput.setValue(max_rubric_points);
        criterion['deleted'] = false;
        if (criterion.hasOwnProperty('levels')) {
          criterion.levels.forEach(level => {
            level['deleted'] = false;
          });
        }
      });
      this.listCriterion = listCriterionSetup;
    }
  }

  // Updates the points in the weighted average
  changeWeightedAverage(): void {
    this.changeMaxPoints()
  }

  receiveRubricPointDecimalPlaces(event){
    
    this.formPoints.controls.rubricaPointsInput.setValue(event);

  }

  receiveLevelPointDecimalPlaces(event, indexCriterion, indexLevels){
    
    setTimeout(() => {
      this.listCriterion[indexCriterion].levels[indexLevels].points = event;

    }, 10);

  }


  returnValuesPoints(): any{
    let sumPoints: number = 0;
    this.listCriterion.forEach(criterion => {
      if (
          criterion?.levels && 
          criterion?.levels.length &&
          !criterion?.deleted
      ) {
        let biggestPoint: number = 0;
        criterion.levels.forEach((level: any, index: number) => {
          if (!level?.deleted) {
            const points: number = this.transformToNumeric(level.points.toString());
            
            if (points > biggestPoint) {
              biggestPoint = points;
            }
          }
        });
        sumPoints += biggestPoint;
      }
    });
    return sumPoints;
  }

  transformToNumeric(value: string): number{
    const decimalIndex = value.search(/[,\.]/);
    let decimal_places;

    if (this.localStorageService.getLocalStorageItem('decimal_places_mask')) {
        decimal_places = JSON.parse(localStorage.getItem('decimal_places_mask') || '');
    }

    if (decimalIndex !== -1 ) {
      const decimalSeparator = value[decimalIndex] === ',' ? '.' : '.';
      const decimalPart = value.substring(decimalIndex + 1);
      let trimmedDecimalPart = '';
      if (decimal_places !== null) {
        trimmedDecimalPart = decimalPart.substring(0,decimal_places);
      }
      value = value.substring(0, decimalIndex) + decimalSeparator + trimmedDecimalPart;
    }

    return parseFloat(value);
  }


  isMaxPointsInvalid: boolean = false;
  hasNoPointsAvailable: boolean = false;
  // Subtract values ​​and validate
  changeMaxPoints(): void {

    let getAllSelectedLevels = this.returnValuesPoints();
    
    getAllSelectedLevels = getAllSelectedLevels == '' ? 0 : getAllSelectedLevels;
    let isNegativeNumber = Math.sign(this.rubrica_points_input - getAllSelectedLevels);

    if (isNegativeNumber !== -1 || getAllSelectedLevels !== 0) {
      this.maxPoints = 1*(this.rubrica_points_input - getAllSelectedLevels);
    }else{
      this.maxPoints = 0;
    }
    this.isMaxPointsInvalid = this.validateMaxPointsCriterions();
    this.hasNoPointsAvailable = this.validateMaxPointsLeft();
  }

  validateMaxPointsCriterions(): boolean{
    if (this.returnValuesPoints() >  this.rubrica_points_input) {
      return true;
    }

    return false;
  }

  validateMaxPointsLeft(): boolean{
    if (this.returnValuesPoints() >=  this.rubrica_points_input) {
      return true;
    }

    return false;
  }
  
}
