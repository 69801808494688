<app-generic-modal [isVisible]="true">
  <div class="container">
    <div class="header-modal px-4">
      <div class="title-modal">
        {{ title }}
      </div>
      <p class="sub-title-modal text-center">
        {{ subTitle }}
      </p>
    </div>
    <div *ngIf="isItInTheTemplates == true">
      <!-- ----------------------- Templates ---------------------->
      <div *ngIf="contentSelectionMode; else disciplineSelection">
        <div class="mx-auto">
          <div class="body-modal flex-col mx-1">
            <div class="input-search-container">
              <label for="search">
                <img src="../../../../../assets/svg/search-gray.svg" />
              </label>
              <input
                id="search"
                class="input-search"
                (keyup)="search($event)"
              />
            </div>
            <div class="explanations-container">
              <div class="text-options-1">{{ available }}</div>
              <div
                class="text-options-0"
                role="button"
                (click)="selectAllSections()"
              >
                {{ selectAll }}
              </div>
            </div>
            <div class="scroller" id="container">
              <ng-container *ngIf="!loading">
                <app-select-option-box
                  [sections]="filteredSections"
                  [textFound]="textFound"
                  [alteredFilter]="alteredFilter"
                  (sendingCheckData)="receivesCheckedContents($event)"
                  (sendingCheckSectionData)="receivesCheckedSection($event)"
                  [receiveControlAllSelect]="controlAllSelect"
                  [receiveExternalIDContent]="receiveExternalIDContent"
                ></app-select-option-box>
                <div
                  class="flex fle-row justify-center items-center w-full h-60"
                  *ngIf="!sections?.length"
                >
                  <div
                    class="flex flex-col justify-center items-center color-gray-icon"
                  >
                    <img
                      src="../../../../../../../assets/svg/attention.svg"
                      alt=""
                    />
                    <p>{{ i18n.reports_empty_data }}</p>
                  </div>
                </div>
              </ng-container>
              <div class="loading-container" *ngIf="loading">
                <div class="loading">
                  <img src="../../../../../assets/svg/loading.svg" />
                  <p>{{ i18n.reports_wait }}</p>
                  <p>{{ i18n.reports_we_are_loading }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <button
            class="button-save"
            (click)="continueImport(); gettingDisciplines(activePage, per_page)"
          >
            {{ buttonSave }}
          </button>
          <button class="button-cancel" (click)="closeModalClassroomImport()">
            {{ i18n.disciplines_cancel }}
          </button>
        </div>
      </div>

      <ng-template #disciplineSelection>
        <div [formGroup]="modelForm">
          <div class="body-modal flex-col mx-1">
            <div class="toogle-group">
              <button
                class="toggle-button-left"
                [class.active]="selectedOption === 'private'"
                (click)="selectOption('private')"
              >
              {{ i18n.disicplines_title_plataforma }}
              </button>
              <button
                class="toggle-button-right"
                [class.active]="selectedOption === 'google_classroom'"
                (click)="selectOption('google_classroom')"
              >
                <span>{{ i18n.disicplines_title_classroom }}</span>
                <div class="tooltip-container">
                  <button
                    (mouseenter)="showTooltip = true"
                    (mouseleave)="showTooltip = false"
                  >
                    <span class="iconify" data-icon="formkit:help"></span>
                  </button>
                  <div class="tooltip" *ngIf="showTooltip">
                    {{ i18n.disciplines_export_tooltip_classroom }}
                  </div>
                </div>
              </button>
            </div>
            <div class="input-search-container">
              <label for="search">
                <img src="../../../../../assets/svg/search-gray.svg" />
              </label>
              <input
                [formControl]="typedData"
                id="search"
                class="input-search"
              />
            </div>
            <div
                *ngIf="selectedOption === 'google_classroom'"
                class="mode_available w-full p-3"
            >
                <label for="mode_available" class="text-16 text-customBlue-default font-semibold">
                    {{ i18n.disciplines_select_mode_available }}
                </label>
                <app-single-choice-dropdown
                    [items]="availabe_modes"
                    [block]="true"
                    (change)="setAvailableMode($event)"
                >
                </app-single-choice-dropdown>
            </div>
            <div class="explanations-container">
              <div class="text-options-1">{{ available }}</div>
            </div>
            <div class="scroller" id="container-import">
              <div *ngIf="!loading">
                <ng-container *ngFor="let discipline of dataDisciplines">
                  <!-- Aqui é a seleção de turmas para exportação -->
                  <app-select-discipline-classroom-box
                    [discipline]="discipline"
                    [selectedDiscipline]="selectedDisciplinesClassroom"
                    [modelForm]="modelForm"
                    (formDisciplineData)="
                      checkImportDisciplineChange($event)
                    "
                    [type] = "selectedOption"
                  ></app-select-discipline-classroom-box>
                </ng-container>
                <div
                  class="flex fle-row justify-center items-center w-full h-60"
                  *ngIf="!dataDisciplines?.length"
                >
                  <div
                    class="flex flex-col justify-center items-center color-gray-icon"
                  >
                    <img
                      src="../../../../../../../assets/svg/attention.svg"
                      alt=""
                    />
                    <p>{{ i18n.reports_empty_data }}</p>
                  </div>
                </div>
              </div>
              <div class="loading-container" *ngIf="loading == true">
                <div class="loading">
                  <img src="../../../../../assets/svg/loading.svg" />
                  <p>{{ i18n.reports_wait }}</p>
                  <p>{{ i18n.reports_we_are_loading }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mb-4 flex justify-center"
          *ngIf="disciplinesRawData?.page != undefined"
        >
          <app-pagination
            #pagination
            [params]="paginationParams"
            (navigation)="goToPage($event)"
          ></app-pagination>
        </div>
        <div class="footer">
          <button class="button-save" (click)="sendEventToExportData()">
            {{ buttonSave }}
          </button>
          <button class="button-cancel" (click)="closeModalClassroomImport()">
            {{ i18n.disciplines_cancel }}
          </button>
        </div>
      </ng-template>
    </div>

    <div *ngIf="isItInTheTemplates == false">
      <!-- ----------------------- TURMAS ---------------------->
      <div *ngIf="templateSelectionMode; else contentSelection">
        <div [formGroup]="modelForm">
          <div class="body-modal flex-col mx-1">
            <div class="input-search-container">
              <label for="search">
                <img src="../../../../../assets/svg/search-gray.svg" />
              </label>
              <input
                id="search"
                class="input-search"
                [formControl]="typedData"
              />
            </div>
            <div class="explanations-container">
              <div class="text-options-1">{{ available }}</div>
            </div>
            <div class="scroller" id="container-import">
              <div *ngIf="!loading">
                <ng-container *ngFor="let discipline of dataDisciplines">
                  <app-select-discipline-classroom-box
                    [discipline]="discipline"
                    [selectedDiscipline]="selectedDisciplinesClassroom"
                    [modelForm]="modelForm"
                    (formDisciplineData)="
                      checkImportDisciplineChange($event)
                    "
                    [type] = "'google_classroom'"
                  ></app-select-discipline-classroom-box>
                </ng-container>
              </div>
              <div class="loading-container" *ngIf="loading">
                <div class="loading">
                  <img src="../../../../../assets/svg/loading.svg" />
                  <p>{{ i18n.reports_wait }}</p>
                  <p>{{ i18n.reports_we_are_loading }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mb-4 flex justify-center"
          *ngIf="disciplinesRawData?.page != undefined"
        >
          <app-pagination
            #pagination
            [params]="paginationParams"
            (navigation)="goToPage($event)"
          ></app-pagination>
        </div>
        <div class="footer">
          <button class="button-save" (click)="sendEnventToImportData()">
            {{ buttonSave }}
          </button>
          <button class="button-cancel" (click)="closeModalClassroomImport()">
            {{ i18n.disciplines_cancel }}
          </button>
        </div>
      </div>

      <ng-template #contentSelection>
        <div>
          <div class="body-modal flex-col mx-1">
            <div class="input-search-container">
              <label for="search">
                <img src="../../../../../assets/svg/search-gray.svg" />
              </label>
              <input
                id="search"
                class="input-search"
                (input)="search($event)"
              />
            </div>
            <div class="explanations-container">
              <div class="text-options-1">{{ available }}</div>
              <div
                class="text-options-0"
                role="button"
                (click)="selectAllSections()"
              >
                {{ selectAll }}
              </div>
            </div>
            <div class="scroller" id="container">
              <ng-container *ngIf="loading == false">
                <app-select-option-box
                  [sections]="filteredSections"
                  [textFound]="textFound"
                  [alteredFilter]="alteredFilter"
                  (sendingCheckData)="receivesCheckedContents($event)"
                  (sendingCheckSectionData)="receivesCheckedSection($event)"
                  [receiveControlAllSelect]="controlAllSelect"
                ></app-select-option-box>
              </ng-container>
              <div class="loading-container" *ngIf="loading == true">
                <div class="loading">
                  <img src="../../../../../assets/svg/loading.svg" />
                  <p>{{ i18n.reports_wait }}</p>
                  <p>{{ i18n.reports_we_are_loading }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="button-save" (click)="sendEnventToImportData()">
            {{ buttonSave }}
          </button>
          <button class="button-cancel" (click)="closeModalClassroomImport()">
            {{ i18n.disciplines_cancel }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</app-generic-modal>
