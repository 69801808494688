import { formatDate } from '@angular/common';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

import { UserAssiduityInterface } from '../../interfaces/user-assiduity.interface';

@Component({
    selector: 'user-assiduity',
    templateUrl: './user-assiduity.component.html',
    styleUrls: ['./user-assiduity.component.scss']
})
export class UserAssiduityComponent implements OnInit {
    @Input() assiduity: UserAssiduityInterface;
    @Input() lastItem: boolean;
    @Output() action = new EventEmitter<string>();
    i18n: any = {};

    constructor(private sharedService: SharedService) { }

    ngOnInit(): void {
        if (this.assiduity.start_date && typeof this.assiduity.start_date === 'string' && this.assiduity.start_date.includes('-')) {
            this.assiduity.start_date = formatDate(this.assiduity.start_date, 'dd/MM/yyyy', 'pt-BR');
        }

        this.getTranslations();
    }

    getTranslations(): void {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Users'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
        };
    }

    actionEvent(lastItem: boolean) {
        if (lastItem) {
            this.action.emit('add');
        } else {
            this.action.emit('delete');
        }
    }
}
