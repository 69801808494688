import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DisciplineSettingsDetailService } from './discipline-settings-detail.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ConfirmationService } from 'primeng/api';


@Component({
  selector: 'app-discipline-settings-details',
  templateUrl: './discipline-settings-details.component.html',
  styleUrls: ['./discipline-settings-details.component.scss'],
  providers: [ConfirmationService],
})
export class DisciplineSettingsDetailsComponent implements OnInit {

  external_id: any;
  settingsDetail: any = [];
  displayBasic: boolean;
  settingsDetailForm: UntypedFormGroup;
  textError: string = "";
  files: any = [];
  maxFiles: number = 1;
  inputType: string;

  fileCover_image:any = [];
  fileCard_image: any = [];
  fileRound_image:any = [];

  cover_imageChanged:boolean = true
  card_imageChanged: boolean = true
  round_imageChanged:boolean = true

  i18n: any = [];
  currentLanguage: any;

  errorImage   = "../../../../../../../../../assets/png/img_default_sett.png";
  defaultImage = "../../../../../../../../../assets/svg/loading.svg";

  modalOpen$ = this.sharedService.modalOpen$;

  canSave: boolean = false;

  constructor(
    private disciplineSettingsDetailService: DisciplineSettingsDetailService,
    private platModalService: PlatformModalsService,
    private sharedService:SharedService,
    private route: ActivatedRoute,
  ) {}


  ngOnInit(): void {
    this.getUrlParams();
    this.getDetailSettings(this.external_id);
    this.getTranslations();
    console.log('DEBUG: ', this.files);
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }


  getUrlParams(){
    console.log('EXTERNAL ID',this.route.params)
    this.route?.parent?.parent?.params?.subscribe((params)=> {
      this.external_id = params.id;
    });
  }


  getDetailSettings(external_id: any) {
    this.disciplineSettingsDetailService.getDisciplineDetailSettings(external_id)
    .subscribe(settingsDetail => {
      this.settingsDetail = settingsDetail;
      console.log('aa', settingsDetail)
      this.loadImgOff()
      },(err)=>{
      this.displayBasic = true;
      this.textError = err.error.error;
      },
      () => {}
    );
  }


  openUploadContent(inputType: string){
    this.inputType = inputType;

    this.sharedService.modalOpen();
  }


  patchDetailSettings(){
    this.canSave = false;
    this.loadImgOn();
    
    // console.log('this.fileCover_image ', this.fileCover_image);
    // console.log('this.fileCover_image ', this.fileCover_image);
    // console.log('this.fileRound_image ', this.fileRound_image);
    

    // let cover_image = this.fileCover_image !==  undefined ? this.fileCover_image?.id : null;
    // let card_image  = this.fileCard_image  !==  undefined ? this.fileCard_image?.id  : null;
    // let round_image = this.fileRound_image !==  undefined ? this.fileRound_image?.id : null;

    
    // const params = {
    //   "cover_image": cover_image,
    //   "card_image":  card_image,
    //   "round_image": round_image
    // }

    let params = {
      cover_image: this.settingsDetail.cover_image ? this.settingsDetail.cover_image.id : null,
      card_image: this.settingsDetail.card_image ? this.settingsDetail.card_image.id : null,
      round_image: this.settingsDetail.round_image ? this.settingsDetail.round_image.id : null
    };

    this.disciplineSettingsDetailService.patchDisciplineDetailSettings(this.external_id, params)
    .subscribe(() =>{
    this.files = []
    this.getDetailSettings(this.external_id);
    this.showModalMessage(this.i18n.disciplines_img_saved);
    this.canSave = false;
    }, (err)=>{
      this.canSave = true;
      this.displayBasic = true;
      this.textError = err.error.error;
    },()=>{}
    );
  }


  setValueForm(settingsDetail: any){
    this.fileCover_image  = settingsDetail?.cover_image == null ? null : settingsDetail?.cover_image;
    this.fileCard_image   = settingsDetail?.card_image  == null ? null : settingsDetail?.card_image;
    this.fileRound_image  = settingsDetail?.round_image == null ? null : settingsDetail?.round_image;
  }


  // receiveFiles($event:any) {
  //   console.log('$event ', $event);
    
  //   this.files = $event
  //   this.sharedService.replaceLinkFile(this.files);

  //   if (this.files.length) {
  //     if (this.inputType == 'cover_image') {
  //       this.files.map((item:any)=> this.fileCover_image = item);
  //       this.isUploadImg(this.files)
  //       this.whatIsChangedImg('cover_image');
  //     }
  //     if (this.inputType == 'card_image') {
  //       this.files.map((item:any)=> this.fileCard_image = item);
  //       this.isUploadImg(this.files)
  //       this.whatIsChangedImg('card_image');
  //     }
  //     if (this.inputType == 'round_image') {
  //       this.files.map((item:any)=> this.fileRound_image = item);
  //       this.isUploadImg(this.files)
  //       this.whatIsChangedImg('round_image');
  //     }
  //   }else{
  //     this.fileCover_image = null;
  //     this.fileCard_image = null;
  //     this.fileRound_image = null;
  //     this.isUploadImg(this.files)

  //   }

  //   this.canSave = true;
  // }

  receiveFiles($event:any) {
    console.log('$event ', $event);

    var file = $event
    this.sharedService.replaceLinkFile(file);
    file[0].url = file[0].public_url;
    this.sharedService.modalClose();

    switch (this.inputType) {
      case "cover_image":
        this.settingsDetail.cover_image = file[0];
        break;
      case "card_image":
        this.settingsDetail.card_image = file[0];
        break;
      case "round_image":
        this.settingsDetail.round_image = file[0];
        break;
    }

    this.canSave = true;
  }


  // isUploadImg(files) {
  //   this.sharedService.modalClose();
  //   this.patchDetailSettings(files);
  // }


  loadImgOff() {
    this.cover_imageChanged == true ? this.cover_imageChanged = false : this.cover_imageChanged = false
    this.coverImageHasChanged(this.cover_imageChanged)
    this.card_imageChanged  == true ? this.card_imageChanged  = false : this.card_imageChanged  = false
    this.round_imageChanged == true ? this.round_imageChanged = false : this.round_imageChanged = false
  }


  loadImgOn() {
    this.cover_imageChanged == true ? this.cover_imageChanged = true : this.cover_imageChanged = false
    this.card_imageChanged  == true ? this.card_imageChanged  = true : this.card_imageChanged  = false
    this.round_imageChanged == true ? this.round_imageChanged = true : this.round_imageChanged = false
  }


  whatIsChangedImg(img){
    switch (img) {
      case 'cover_image':
        this.cover_imageChanged = true
        break;
      case 'card_image':
        this.card_imageChanged = true
        break;
      case 'round_image':
        this.round_imageChanged = true
        break;
      default:
        this.cover_imageChanged = false
        this.card_imageChanged  = false
        this.round_imageChanged = false
        break;
    }
  }


  coverImageHasChanged(cover_imageChanged: boolean) {
    if(cover_imageChanged == false) {
      this.sharedService.isChangedCoverImg();
    }
  }


  imgErr(img: any){
    console.log('img ', img);
    switch (img) {

      case 'cover_image':
          this.settingsDetail.cover_image.url = null;
        break;
      case 'card_image':
          this.settingsDetail.card_image.url  = null;
        break;
      case 'round_image':
          this.settingsDetail.round_image.url  = null;
        break;

      default:
        break;
    }
  }


  showModalMessage(message: string): void {
    this.platModalService.toggle(
      'message',
      {
        message: message,
        custom_icon: 'success-circle-icon-icon',
        icon_color: '#80CC28',
      },
      {
        finally: () => {
          this.platModalService.close('message');
        },
      }
    )
  }
}
