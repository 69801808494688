import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

import { ScheduleResponse, SettingsResponse } from './interfaces/assessment.interface';

@Injectable({
    providedIn: 'root'
})
export class AssessmentService {
    constructor(private http: HttpClient) { }

    getSettings(discipline_external_id: string): Observable<SettingsResponse> {
        return this.http.get<SettingsResponse>(`${API_Routes.URL}/assessments/settings/${discipline_external_id}`);
    }

    getSchedules(class_id: string): Observable<ScheduleResponse[]> {
        return this.http.get<ScheduleResponse[]>(`${API_Routes.URL}/trieduc/get-schedules/${class_id}`);
    }
}
