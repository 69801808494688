import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild
} from '@angular/core';

@Component({
    selector: 'app-filter-planning',
    templateUrl: './filter-planning.component.html',
    styleUrls: ['./filter-planning.component.scss']
})
export class FilterPlanningComponent implements AfterViewInit, OnInit {
    @Input() key: string;
    @Input() items: { id: number, description: string }[] = [];
    @Input() title?: string;
    @Input() activeItem: number;
    @Input() availableFilters: { [key: string]: number[] } = {};
    @Output() selectItemEvent = new EventEmitter();

    @ViewChild('carousel') carousel!: ElementRef;

    showArrows = false;
    itemActive: number;

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.calculateArrows();
    }

    ngOnInit(): void {
        this.itemActive = this.activeItem;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.calculateArrows();
        }, 1000);
    }

    scrollLeft(): void {
        const carousel = this.carousel.nativeElement;
        if (carousel.scrollLeft === 0) {
            carousel.scrollLeft = carousel.scrollWidth;
        } else {
            carousel.scrollLeft -= carousel.offsetWidth;
        }
    }

    scrollRight(): void {
        const carousel = this.carousel.nativeElement;
        if (carousel.scrollLeft + carousel.offsetWidth >= carousel.scrollWidth) {
            carousel.scrollLeft = 0;
        } else {
            carousel.scrollLeft += carousel.offsetWidth;
        }
    }

    private calculateArrows(): void {
        const carousel = this.carousel.nativeElement;
        this.showArrows = carousel.scrollWidth > carousel.clientWidth;
    }

    public selectItem(id: number) {
        console.log('selectItem', id);
        if (this.itemActive === id) {
            this.itemActive = 0;
            return;
        }

        this.itemActive = id;
        this.selectItemEvent.emit({ key: this.key, id });
    }

    public checkDisabled(id: number): boolean {
        if (this.availableFilters[this.key]?.length && (this.key === 'segments' || this.key === 'degrees')) {
            return !this.availableFilters[this.key].includes(id);
        }

        return false;
    }
}
