import { finalize, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { CertificatesSharedService } from 'src/app/shared/certificates-shared.service';
import { DisciplineSettingsCertificatesService } from 'src/app/pages/disciplines/components/settings/discipline-settings-certificates.service';
import { SharedService } from 'src/app/shared/shared.service';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  formatDate,
  formatNumber,
  formatPercent,
  PercentPipe,
} from '@angular/common';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators'; 
import { collapseAnimation } from 'src/app/utils/animations/animations';


@Component({
  selector: 'app-discipline-settings-certificates',
  templateUrl: './discipline-settings-certificates.component.html',
  styleUrls: ['./discipline-settings-certificates.component.scss'],
  animations: [
    collapseAnimation('250')
  ],
})
export class DisciplineSettingsCertificatesComponent implements OnInit {
  modalEditOrAdd$ = this.certificatesSharedService.modalEditOrAdd$;
  modalEmitCertificate$ = this.certificatesSharedService.modalEmitCertificate$;
  modalEditCertificate$ = this.certificatesSharedService.modalEditCertificate$;
  modalConfirmCertificate$ =
    this.certificatesSharedService.modalConfirmCertificate$;
  modalResourcesCertificate$ =
    this.certificatesSharedService.modalResourcesCertificate$;
  modalAddModelCertificate$ =
    this.certificatesSharedService.modalAddModelCertificate$;
  modalDefaultCertificateDel$ =
    this.certificatesSharedService.modalDefaultCertificateDel$;

  modalOpen$ = this.sharedService.modalOpen$;
  isLoaded$ = this.sharedService.isLoadedUpload$;
  modalConfirmation$ = this.sharedService.modalConfirmation$;

  editMode: boolean = false;
  isNotAllowed: boolean = false;
  notAllowedModal: boolean = false;
  collapsedDate: boolean = false;
  collapsedEnabled: boolean = false;
  collapsedCompletion: boolean = false;

  preview: any;
  file_id: any;
  show: boolean;
  files: any = [];
  inputType: string;
  sections: any = [];
  maxFiles: number = 1;

  elegibles: any = [];
  certificates: any = [];
  contentsCertificate: any = [];
  disabledClass: boolean = true;

  isNotHasCertificate: boolean;
  showModalEmit: boolean;
  showNewCertificate = false;
  buttonUnlocked: boolean = false;


  external_id: any;
  discipline_id: any;
  certificateId: any;
  certificateForEdit: any;
  postCertificateForm: UntypedFormGroup;
  editedCertificateForm: UntypedFormGroup;
  disciplineCertificateForm: UntypedFormGroup;

  uri: any;

  newValue;

  i18n: any = [];
  currentLanguage: string | null;

  initialDate: Date = new Date();

  isLoading: boolean = false;
  Loading: boolean = false;
  constructor(
    private disciplineCertificatesService: DisciplineSettingsCertificatesService,
    private certificatesSharedService: CertificatesSharedService,
    public sharedService: SharedService,
    private percentPipe: PercentPipe,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private platModalsService: PlatformModalsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getUriInfraPath();
    this.getUrlParams();
    this.getDisciplineCertificates();
    this.certificateSettingsForm();
    this.getSections();
    this.getTranslations();
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  getUriInfraPath() {
    if (sessionStorage.getItem('gcsInfraPath')) {
      this.uri = sessionStorage.getItem('gcsInfraPath');
    }

    if (localStorage.getItem('gcsInfraPath')) {
      this.uri = localStorage.getItem('gcsInfraPath');
    }
  }

  clearFiles() {
    this.files = [];
  }


  toggleStart_date() {
    //this.collapsedStart_date = !this.collapsedStart_date
    this.collapsedDate = !this.collapsedDate;
  }

  toggleCompletion() {
    this.collapsedCompletion = !this.collapsedCompletion;
  }

  toggleEnabled() {
    this.collapsedEnabled = !this.collapsedEnabled;
  }

  openConfirmModal() {
    this.sharedService.modalOpenConfirmation();
  }

  closeConfirmModal() {
    this.sharedService.modalCloseConfirmation();
  }

  modalEmitCertificateClose() {
    this.certificatesSharedService.modalCloseEmitCertificate();
  }
  modalEmitCertificateOpen() {
    this.certificatesSharedService.modalOpenEmitCertificate();
  }

  modalDefaultDelOpen(external_Id: any) {
    this.certificateId = external_Id;
    this.certificatesSharedService.modalOpenDeleteDefault();
  }
  modalDefaultDelClose() {
    this.certificatesSharedService.modalCloseDeleteDefault();
  }

  modalEditCertificateClose() {
    this.certificatesSharedService.modalCloseEditCertificate();
  }

  modalEditCertificateOpen(certificate: any) {
    this.clearFiles();
    this.certificatesSharedService.editFileMode();
    this.certificateForEdit = certificate;
    this.isLoaded$.subscribe((val) => (this.buttonUnlocked = val));
    this.certificatesSharedService.modalOpenEditCertificate();
    this.certificatesSharedService.modalOpenEditCertificate();
    this.certificatesSharedService.editModalTitleEDIT();
  }

  modalConfirmCertificateOpen(external_Id: any) {
    this.certificateId = external_Id;
    this.certificatesSharedService.modalOpenConfirmCertificate();
  }
  modalConfirmCertificateClose() {
    this.certificatesSharedService.modalCloseConfirmCertificate();
  }

  modalResourcesCertificateOpen() {
    this.certificatesSharedService.modalOpenResourcesCertificate();
  }
  modalResourcesCertificateClose() {
    this.certificatesSharedService.modalCloseResourcesCertificate();
  }

  modalAddModelCertificateOpen() {
    this.certificatesSharedService.modalOpenAddModelCertificate();
  }
  modalAddModelCertificateClose() {
    this.certificatesSharedService.modalCloseAddModelCertificate();
  }

  openUploadContent() {
    this.clearFiles();
    this.certificatesSharedService.addFileMode();
    this.sharedService.modalOpen();
  }

  getUrlParams() {
    this.route?.parent?.parent?.params?.subscribe((params) => {
      this.discipline_id = params.id;
    });
  }

  changeButtonUnlockedTrue() {
    this.buttonUnlocked = true;
  }

  changeButtonUnlockedFalse() {
    this.buttonUnlocked = false;
  }

  editOrAddMode() {
    this.modalEditOrAdd$.subscribe((value) => (this.editMode = value));
  }

  getDisciplineCertificates() {
    this.isLoading = true;
    this.modalEditCertificateClose();
    this.getSections();
    this.disciplineCertificatesService
      .getDisciplineCertificate(this.discipline_id)
      .subscribe({
        next:(certificates) => {
          (this.certificates = certificates),
          this.setValueForm(certificates);
          this.getContentCertificates(certificates);
          this.callLoadingOrClose();
          this.hasCertificate();
          this.isLoading = false;
        },
        error: (err) => {
          let errMsg;
          err?.error?.errors[0]?.map((element) => (errMsg = element));
          if (errMsg == 'Emitting Certificates is not Allowed.') {
            this.isNotAllowed = true;
            this.notAllowedModal = true;
            this.isLoading = false;
          }
          
        }
      });
  }

  hasCertificate() {
    if(this.certificates?.models?.length > 0) {
      this.isNotHasCertificate = false
    } else if (this.certificates?.models?.length == 0)
    this.isNotHasCertificate = true
  }

  callLoadingOrClose() {
    if (this.buttonUnlocked == false) {
      this.isLoading = false;
     } else {
       this.openConfirmModal();
       this.isLoading = true;
     }
     this.buttonUnlocked = false;
     this.clearFiles();
     this.unlockedSaveButtonForm();
  }

  // Escuta mudanças do output da diretiva appCustomPercent
  percentEventOnParent(event) {
    this.newValue = event;
    this.canEmitCertificates(this.newValue);

    
  }

  canEmitCertificates(value){
    if (value != 0) {
      this.showModalEmit = true
    } else {
      this.showModalEmit = false
    }
  }

  onPostCertificatesSettings() {
    this.isLoading = true;

    let completPercent = this.newValue / 100;
    let enabled = this.disciplineCertificateForm.controls.enabled.value;
    let automatic = this.disciplineCertificateForm.controls.automatic.value;
    let grade = this.disciplineCertificateForm.controls.grade.value;
    let startDate = this.disciplineCertificateForm.controls.start_date.value;

    startDate =
    startDate == null || startDate == ''
    ? null
    : formatDate(startDate, 'yyyy-MM-dd HH:mm:ss', 'en-US');

    const params = {
      enabled: enabled,
      automatic: automatic,
      grade: grade,
      completion: completPercent,
      start_date: startDate,
      // "contents":   contents,
    };

    this.postCertificateSettings(params);
  }

  postCertificateSettings(params: any) {
    this.message = []
    this.disciplineCertificatesService
      .patchDisciplineCertificate(this.discipline_id, params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next:() => {this.buttonUnlocked = false, this.isLoading = false},
        error:(error) => console.log(error),
        complete:() => {
          this.isLoading = false;
          this.openConfirmModal();
          
        }
      }
        
      );
  }

  setValueForm(certificateOptions: any) {
    
    const completPercent = certificateOptions.completion !== null ? `${Math.floor(certificateOptions.completion * 100)}%` : null;
    // let completPercent = formatPercent(certificateOptions.completion, 'pt-BR', '3.2-2')

    // no pipe -> number:'4.3-5':'pt-BR'

    /*  let completPercent = formatPercent(certificateOptions.completion, 'pt-BR')  */
    let startDate = certificateOptions.start_date;

    const initProcessedDate = new Date();
    const start_date = new Date(certificateOptions.start_date);

    if (startDate == null) {
      startDate = initProcessedDate;
    } else {
      startDate = start_date;
    }

    this.disciplineCertificateForm.controls['enabled'].setValue(
      certificateOptions.enabled
    );
    this.disciplineCertificateForm.controls['automatic'].setValue(
      certificateOptions.automatic
    );
    this.disciplineCertificateForm.controls['grade'].setValue(
      certificateOptions.grade
    );
    this.disciplineCertificateForm.controls['completion'].setValue(
      completPercent
    );
    this.disciplineCertificateForm.controls['start_date'].setValue(startDate);
    this.disciplineCertificateForm.controls['calendarStartDate'].setValue(startDate);
    this.fnPickDateToInput();

    this.dateToggler(start_date);
    this.activityToggler(certificateOptions.completion);
    this.enabledToggler(certificateOptions.enabled);
  }

  private certificateSettingsForm() {
    this.disciplineCertificateForm = this.formBuilder.group({
      enabled: [''],
      automatic: [''],
      grade: [''],
      /* completion: ['', [Validators.maxLength(3)]], */
      completion: ['', [Validators.required]],
      start_date: [''],
      startDate: [new Date()],
      calendarStartDate: ['', [this.minDateValidator.bind(this)]],
    });

    /* this.disciplineCertificateForm.get('completion')?.valueChanges.subscribe(
      value => {

      }
    ) */

    /* this.disciplineCertificateForm.valueChanges.subscribe( form => {
        if(form.completion) {
            this.disciplineCertificateForm.patchValue({
              completion:
              //completion: this.percentPipe.transform(form.completion.toString().replace(/^(100(\.0{1,2})?|([0-9]?[0-9](\.[0-9]{1,2})))$/), '1.2-2', 'pt-BR')
            }, {emitEvent: false});
        }
      })  */
  }

  formatPatchCertificates(certificateForEdit: any, files: any) {
    let favorite = certificateForEdit.default;
    let name = this.editedCertificateForm.value.name;
    let external_id = certificateForEdit.external_id;
    let file_id = parseInt(certificateForEdit.file_id);

    files.map((element) => (file_id = element.id));

    const params = {
      name: name,
      file_id: file_id,
      default: favorite,
    };

    this.patchCertificates(external_id, params);
  }

  patchCertificates(external_id, params) {
    this.Loading = true;
    this.disciplineCertificatesService
      .patchCertificatesSettings(this.discipline_id, external_id, params)
      .subscribe({
        next: () => {
        //this.sharedService.modalOpenConfirmation();
        },
        error: () => {
        //this.textError = err.error.error;
        },
        complete: () => {
          this.Loading = false;
          this.getDisciplineCertificates();
          this.clearFiles();
        },
      })
  }

  patchCertificatesGlobal(external_id, params) {
    this.disciplineCertificatesService
      .patchCertificatesGlobal(external_id, params)
      .subscribe(
        () => {
          //this.sharedService.modalOpenConfirmation();
        },
        (err) => {
          console.log(err);
          //this.textError = err.error.error;
        },
        () => {
          this.getDisciplineCertificates();
          this.clearFiles();
        }
      );
  }

  formatPostCertificates() {
    this.changeButtonUnlockedFalse();
    let name = this.postCertificateForm.value.name;
    let external_id: any;
    let file_id: any;

    this.files.map((element) => (external_id = element.external_id));
    this.files.map((element) => (file_id = element.id));

    const params = {
      external_id: external_id,
      name: name,
      file_id: file_id,
    };

    this.postCertificates(params);
  }

  postCertificates(params) {
    this.Loading = true;
    this.disciplineCertificatesService
      .postCertificatesModels(this.discipline_id, params)
      .subscribe({
        next: () => {
          //this.sharedService.modalOpenConfirmation();
        },
        error: () => {
          //this.textError = err.error.error;
        },
        complete: () => {
          this.Loading = false;
          this.getDisciplineCertificates();
          this.clearFiles();
        }
      })
  }

  onDelete(external_id: any) {
    this.changeButtonUnlockedTrue();
    this.modalConfirmCertificateClose();
    this.isLoading = true;
    this.disciplineCertificatesService
      .deleteCertificatesModels(external_id, this.discipline_id)
      .subscribe({
        next:(response) => {
          this.isLoading = false;
        },
        error:(error) => {
          this.isLoading = false;
        },
        complete:() => {
          this.getDisciplineCertificates();
        }
      }
        
      );
  }

  onDelDefaultDel(certificateId: any) {
    this.changeButtonUnlockedTrue();
    this.modalDefaultDelClose();
    this.isLoading = true;
    this.disciplineCertificatesService
      .deleteDefaultModels(certificateId)
      .subscribe({
        next:(response) => {
          this.isLoading = false;
        },
        error:(error) => {
          this.isLoading = false;
        },
        complete:() => {
          this.getDisciplineCertificates();
        }
      }
        
      );
  }

  receiveFiles($event: any) {
    this.editOrAddMode();
    this.files = $event;
    if (this.editMode) {
      this.files = $event;
    } else {
      this.files.map((element) => (this.file_id = element.id));
      this.sharedService.modalClose();
      this.modalAddModelCertificateOpen();
    }
  }

  onPostPreviewModel($event) {
    this.certificatesSharedService.previewIsLoadingTrue();

    let file_id = this.file_id;
    let add_student_name = $event.student_name;
    let add_emission_date = $event.emission_date;
    let add_discipline_name = $event.discipline_name;
    let add_discipline_duration = $event.discipline_duration;

    add_emission_date =
      add_emission_date == ''
        ? ''
        : formatDate(add_emission_date, 'yyyy-MM-dd HH:mm:ss', 'en-US');

    const params = {
      strings: {
        student_name: add_student_name,
        discipline_name: add_discipline_name,
        discipline_duration: add_discipline_duration,
        emission_date: add_emission_date,
      },
    };

    this.postPreviwModelCertificate(file_id, params);
  }

  postPreviwModelCertificate(file_id, params) {
    this.certificatesSharedService.previewIsLoadingTrue();
    this.disciplineCertificatesService
      .postCertificatesPreview(file_id, params)
      .subscribe(
        (data) => {
          this.preview = this.uri + data.previewImage;
        },
        (err) => {
          console.log(err);
          //this.textError = err.error.error;
        },
        () => {
          //this.getSettingsCertificates();
          this.certificatesSharedService.previewIsLoadingFalse();
        }
      );
  }

  onDefaultDiscipline(isCertificateDefault: any) {
    let external_id = isCertificateDefault.external_id;
    let defaultCert = isCertificateDefault.default;
    let file_id = isCertificateDefault.file_id;
    let name = isCertificateDefault.name;

    const params = {
      name: name,
      file_id: file_id,
      default: defaultCert,
    };

    if (defaultCert == false) {
      defaultCert = true;
      params.default = defaultCert;
      this.patchCertificates(external_id, params);
    } else {
      defaultCert = false;
      params.default = defaultCert;
      this.patchCertificates(external_id, params);
    }
  }

  onDefaultGlobal(isCertificateDefault: any) {
    let external_id = isCertificateDefault.external_id;
    let defaultCert = isCertificateDefault.default;
    let file_id = isCertificateDefault.file_id;
    let name = isCertificateDefault.name;

    const params = {
      name: name,
      file_id: file_id,
      default: defaultCert,
    };

    if (defaultCert == false) {
      defaultCert = true;
      params.default = defaultCert;
      this.patchCertificatesGlobal(external_id, params);
    } else {
      defaultCert = false;
      params.default = defaultCert;
      this.patchCertificatesGlobal(external_id, params);
    }
  }

  getElegible() {
    this.disciplineCertificatesService
      .getElegibleCertificate(this.discipline_id)
      .subscribe((data) => {
        this.elegibles = data;
      });
  }

  getSections() {
    this.disciplineCertificatesService
      .getSections(this.discipline_id)
      .subscribe((data) => {
        this.sections = data;
        //data.map((data) => this.sections = data.contents)
      });
  }

  modalNeedEnableCertBy() {
    const modalType = 'message';
    const translationKey = 'error_need_enable';
    const functionHandlers = {
      finally: () => {
        this.platModalsService.close(modalType);
      },
    };
    this.platModalsService.toggle(
      modalType,
      translationKey,
      functionHandlers
    );
  }

  modalWarnOrModalEmit() {
    if (this.showModalEmit) {
      this.modalEmitCertificateOpen()
    } else {
      this.modalNeedEnableCertBy()
    }
  }

  message: any = [];
  postEmitCertificates(finalData) {
    this.modalEmitCertificateClose();
    //this.openLoadingModal();
    const modalType = 'message';
    const functionHandlers = {
      finally: () => {
        this.platModalsService.close(modalType);
      },
    };
    this.disciplineCertificatesService
      .postEmit(this.discipline_id, finalData)
      .subscribe({
        next:(response) => {
          this.message = this. i18n.disciplines_certificate_emitted;
        },
       // (err) => console.log('ERRO', err.error),


        error:() => {
          const translationKey = 'error_dont_default';
          this.platModalsService.toggle(
            modalType,
            translationKey,
            functionHandlers
          );
        },
        complete:() => {
          this.getDisciplineCertificates();
        }
      }
        
      );
  }

  getContentCertificates(certificates) {
    this.contentsCertificate = certificates.contents;
  }

  patchResources($event) {
    this.modalResourcesCertificateClose();
    this.isLoading = true;

    let contents = $event;
    const params = {
      contents: contents,
    };

    this.disciplineCertificatesService
      .patchDisciplineCertificate(this.discipline_id, params)
      .subscribe({
        next:(response) => {
          this.isLoading = false;
        },
        error:(error) => {
          this.isLoading = false;
          
        },
        complete:() => {
          this.getSections();
          this.getDisciplineCertificates();
        }
      }
      );
  }

  dateToggler(startDate) {
    if (startDate != '') {
      this.collapsedDate = true;
    } else if (startDate == '') {
      this.collapsedDate = false;
    }
  }

  activityToggler(completPercent) {
    if (completPercent != null) {
      this.collapsedCompletion = true;
      return;
    } 

    this.collapsedCompletion = false;
  }

  enabledToggler(enabled) {
    if (enabled != '') {
      this.collapsedEnabled = true;
    } else if (enabled == '') {
      this.collapsedEnabled = false;
    }
  }

  unlockedSaveButtonForm() {
    setTimeout(() => {
      this.disciplineCertificateForm.valueChanges.subscribe((val) => {
        this.buttonUnlocked = true;
      });
    }, 1000);
  }

  closeNotAllowed() {
    this.notAllowedModal = false;
  }

  textCut(limit: any, textToCut: any) {
    let text = textToCut;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.disciplineCertificateForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.disciplineCertificateForm.controls['calendarStartDate'].setValue(format);
    }
  }

  get startDate() {
    return this.disciplineCertificateForm.get('startDate');
  }

  clearFieldOfZero(){
    console.log('zero');
    
    let completion =  this.disciplineCertificateForm.controls.completion.value;
    console.log('completion ', completion);
    

    if (completion === '0%' || completion === null) {
        this.disciplineCertificateForm.controls.completion.setValue('');
    }
  }
}
