import { NgModule } from '@angular/core';
import { HeaderModule } from 'src/app/components/header/header.module';
import { TranslateButtonModule } from 'src/app/components/translate-button/translate-button.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { HomeLayoutComponent } from './home.component';

@NgModule({
    declarations: [
        HomeLayoutComponent
    ],
    imports: [
        SharedModule,
        HeaderModule,
        TranslateButtonModule,
    ]
})
export class HomeModule { }
