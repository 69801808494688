<div id="under-header" class="relative" style="--primary-color: #203346; --secundary-color: #fff">
  <div class="invisible-full-page-div" (click)="openSwitchRoleSection()"></div>

  <header id="header">
    <section class="header">
      <button class="header__expand-btn" tabindex="1" (click)="toggleMenu()">
        <span class="expand-btn__line line-1"></span>
        <span class="expand-btn__line line-2"></span>
        <span class="expand-btn__line line-3"></span>
      </button>
    </section>

    <main class="main">
      <div class="company-logo">
        <div class="h-full">
             <img class="object-contain h-full" src="{{errorImage}}" *ngIf="settingsGeneral?.main_logo?.public_url == null">

            <div class="h-full" *ngIf="settingsGeneral?.main_logo?.public_url != null">
                <img class="rounded w-24 object-contain h-full" style="max-height: 76px;"  
                [defaultImage]="defaultImage" [lazyLoad]="settingsGeneral?.main_logo?.public_url" 
                  [errorImage]="errorImage" >
            </div>
        </div>
      </div>

      <!-- <div class="search-bar">
        <input class="search-bar__input" type="text" placeholder="O que você está procurando?">
        <button class="search-bar__icon magnifier-icon"></button>
      </div> -->
     <div  class="search-form hidden_icon_header" >
        <app-global-search [i18n]="i18n"></app-global-search>
      </div>


      <div class="user-stats">

      <div class="flex justify-center items-center gap-3">

        <div *ngIf="currentLanguage == 'pt-BR'" class="flex justify-center items-center" (click)="toogleAccessibility()">
          <span class="question-mark-icon__icon iconify text-customOrange-default" data-icon="ion:accessibility"></span>
        </div>


        <div class="flex justify-center items-center" *appCheckPermissions="'support'; type: 'create'">
          <i class="question-mark-icon__icon color-blue-icon question-mark-icon" routerLink="/support"></i>
        </div>
        <span class="notification" *appCheckPermissions="'notifications'; type: 'list'">
          <div class="flex justify-center items-center" (click)="notificationToggle()">
            <i  *ngIf="!hasVizualized" class="notification-icon__icon img-notification-icon"></i>
            <i  *ngIf="hasVizualized" class="notification-icon__icon img-notification-vizualized-icon"></i>
          </div>
          <app-notification-tooltip
          *ngIf="isNotification == true"
          [i18n]="i18n"
          [notificationsData]="notificationsData"
          (closeNotifications)="closeNotifications($event)"
          ></app-notification-tooltip>
        </span>
        <button class="user-profile opacity-100" (click)="profiles.length > minimumRoleNumber && displayPanel.toggle()"
          [ngClass]="{ 'cursor-not-allowed': userIsImpersonating }"
          [disabled]="userIsImpersonating">

          <div class="user-frame-container">
            <app-user-frame [adaptable_size]="true" [img_src]="user?.image" [user_name]="user.name"></app-user-frame>
          </div>
          <div class="user-profile__user">
            <p class="user-profile__name truncate"><span>{{ user.name }}</span></p>

            <div class="user-profile__role" [class.active]="profiles.length > minimumRoleNumber">
              <div class="user-profile__role-name" [ngClass]="{ 'cursor-not-allowed-m': userIsImpersonating }"
              [class.active]="displayPanel.visible">
                {{ user.role.name | titlecase}}
              </div>

              <app-display-panel #displayPanel [style]="{'z-index': '1000', right: display_panel.right, top: display_panel.top, transform: display_panel.trans}" [arrow_deslocation]="display_panel.arrow" [custom_radius]="'20px'" [custom_padding]="'30px 20px'" [breakpoint_X]="'944px'" class="display-panel">
                <div class="switch-role__roles">
                  <h3 class="switch-role__title">{{ i18n.header_change_profile }}</h3>

                  <div class="switch-role__role" *ngFor="let profile of profiles" (click)="selectProfile($event, profile?.external_id); openSwitchRoleSection()">
                    <ng-container *ngIf="profileSelected === profile?.external_id">
                      <div class="switch-role__active-label-diamond"></div>
                      <div class="switch-role__active-label">{{ i18n.header_active_label }}</div>
                    </ng-container>
                    <div class="switch-role__role-name">{{ profile?.name | titlecase}}</div>
                  </div>
                </div>
              </app-display-panel>
            </div>
          </div>
        </button>
      </div>
      <button class="user-logout" (click)="logOut()">
        <div class="user-logout__icon logout-icon"></div>
        <p class="user-logout__text">{{ i18n.header_btn_exit }}</p>
      </button>
      </div>
    </main>
  </header>
  <app-accessibility
    [isVisible]="isAccessibilityVisible"
  >
  </app-accessibility>
</div>


<app-translate *ngIf="modalOpenTranslate$ | async"></app-translate>

