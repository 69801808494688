import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class SportsGuard implements CanActivate {
    constructor(
        private sharedService: SharedService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const i18n = this.sharedService.getTranslationsOf('Errors');
        const message = i18n.permission_unauthorized;

        const currentPath = route.routeConfig?.path;

        const paths = [
            { url: '', permission: 'sport', permissionType: 'list' },
        ];

        // Verify if the route path has the necessary permissions
        const pathPermission: any = paths.find((path) => path.url === currentPath) || {};
        const userHasPermission: boolean = this.sharedService.checkPermission(pathPermission.permission, pathPermission.permissionType);
        if (!userHasPermission) this.sharedService.showUnauthorizedModal(message, 'disciplines');

        return userHasPermission;
    }
}
