<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 pb-9 sm:p-0 sm:border-none border border-customGray-lighterGray rounded">
    <fieldset class="flex flex-col gap-1">
        <label for="timeCard">{{ i18n.users_time_card }}</label>

        <input type="text" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16" id="timeCard" required [(ngModel)]="assiduity.time_card"
        />
    </fieldset>
    
    <div class="flex justify-center relative items-end gap-2">
        <fieldset class="flex flex-col gap-1 w-full">
            <label for="startDate">{{ i18n.users_start_date }}</label>
    
            <input type="text" class="border border-customGray-mediumGray rounded-sm py-2 px-4 text-16 w-full" id="startDate" required [(ngModel)]="assiduity.start_date" [mask]="'00/00/0000'"
            />
        </fieldset>

        <div class="absolute -bottom-14 sm:relative sm:bottom-0">
            <ng-container *ngIf="!assiduity.notDeletable">
                <button class="py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default" (click)="actionEvent(lastItem)" [ngClass]="{'add': lastItem === true, 'delete': lastItem === false }">
                    <i *ngIf="lastItem" class="plus-icon color-white-icon w-3 h-3"></i>
                    <i *ngIf="!lastItem"class="x-icon color-white-icon w-3 h-3"></i>
              </button>
            </ng-container>

            <ng-container *ngIf="assiduity.notDeletable">
                <div class="py-3.5 px-3.5 right-16 rounded-full add shadow-lg text-customWhite-default disabled" [ngClass]="{'add': lastItem === true, 'delete': lastItem === false }">
                    <i *ngIf="lastItem" class="plus-icon color-white-icon w-3 h-3"></i>
                    <i *ngIf="!lastItem"class="x-icon color-white-icon w-3 h-3"></i>
                </div>
            </ng-container>
        </div>
    </div>
</div>