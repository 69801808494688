import { NgModule } from '@angular/core';

/* QUESTION BANK COMPONENTS */
import { CreateScaleComponent } from './components/create-scale/create-scale.component';
import { CreateMultipleChoiceComponent } from './components/create-multiple-choice/create-multiple-choice.component';
import { CreateCheckboxComponent } from './components/create-checkbox/create-checkbox.component';
import { CreateListComponent } from './components/create-list/create-list.component';
import { CreateGridMultipleChoiceComponent } from './components/create-grid-multiple-choice/create-grid-multiple-choice.component';
import { CreateGridCheckboxComponent } from './components/create-grid-checkbox/create-grid-checkbox.component';
import { CreateTextComponent } from './components/create-text/create-text.component';
import { CreateParagraphTextComponent } from './components/create-paragraph-text/create-paragraph-text.component';

import { ViewGridMultipleChoiceComponent } from './components/view-grid-multiple-choice/view-grid-multiple-choice.component';
import { ViewGridCheckboxComponent } from './components/view-grid-checkbox/view-grid-checkbox.component';
import { ViewMultipleChoiceComponent } from './components/view-multiple-choice/view-multiple-choice.component';
import { ViewCheckboxComponent } from './components/view-checkbox/view-checkbox.component';
import { ViewListComponent } from './components/view-list/view-list.component';
import { ViewScaleComponent } from './components/view-scale/view-scale.component';

import { QuestionBankComponent } from './question-bank.component';
import { NewQuestionComponent } from './components/new-question/new-question.component';
import { QuestionBankQuestionComponent } from './components/question-bank-question/question-bank-question.component';
import { EditQuestionComponent } from './components/edit-question/edit-question.component';

/* IMPORTED MODULES */
import { LoadingModalModule } from 'src/app/components/modals/loading-modal/loading-modal.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { DecisionModalModule } from 'src/app/components/modals/decision-modal/decision-modal.module';
import { FormModalModule } from 'src/app/components/modals/form-modal/form-modal.module';
import { DropdownsModule } from 'src/app/components/dropdowns/dropdowns.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserFrameModule } from 'src/app/components/user-frame/user-frame.module';
import { FormsModule } from '@angular/forms';
import { EmptyDataModule } from 'src/app/components/empty-data/empty-data.module';
import { SubmissionsCommentModule } from '../content/components/homework-submissions/components/submission-comment/submission-comment.module';
import { AppEditorModule } from 'src/app/components/editor/editor.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { QuestionBankTrieducComponent } from './components/question-bank-trieduc/question-bank-trieduc.component';

@NgModule({
  declarations: [
    QuestionBankComponent,
    NewQuestionComponent,
    CreateScaleComponent,
    CreateMultipleChoiceComponent,
    CreateCheckboxComponent,
    CreateListComponent,
    CreateGridMultipleChoiceComponent,
    CreateGridCheckboxComponent,
    ViewGridMultipleChoiceComponent,
    ViewGridCheckboxComponent,
    CreateTextComponent,
    CreateParagraphTextComponent,
    QuestionBankQuestionComponent,
    ViewScaleComponent,
    ViewMultipleChoiceComponent,
    ViewCheckboxComponent,
    ViewListComponent,
    EditQuestionComponent,
    QuestionBankTrieducComponent,
  ],
  imports: [
    LoadingModalModule,
    MessageModalModule,
    DecisionModalModule,
    FormModalModule,
    DropdownsModule,
    SharedModule,
    UserFrameModule,
    EmptyDataModule,
    SubmissionsCommentModule,
    //Libs
    AppEditorModule,
    PermissionModule
  ],
  exports: [
    QuestionBankComponent,
    NewQuestionComponent,
    CreateScaleComponent,
    CreateMultipleChoiceComponent,
    CreateCheckboxComponent,
    CreateListComponent,
    CreateGridMultipleChoiceComponent,
    CreateGridCheckboxComponent,
    ViewGridMultipleChoiceComponent,
    ViewGridCheckboxComponent,
    CreateTextComponent,
    CreateParagraphTextComponent,
    QuestionBankQuestionComponent,
    ViewScaleComponent,
    ViewMultipleChoiceComponent,
    ViewCheckboxComponent,
    ViewListComponent,
    EditQuestionComponent,
    QuestionBankTrieducComponent
  ],
})
export class QuestionBankModule {}
