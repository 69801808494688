import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_Routes } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private  route: ActivatedRoute,
    private   http: HttpClient,
    private router: Router,
  ) { }

  loadingTrue() {
    this.isLoading$.next(true);
    console.log('LOADING TRUE');
  }

  loadingFalse() {
    console.log('LOADING FALSE');
    this.isLoading$.next(false);
  }

  getDash(): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/dashboard`);
  }

  getDashDisciplines(disciplines: string): Observable<any> {
    return this.http.get<any>(`${API_Routes.URL}/dashboard?disciplines=${disciplines}`);
  }

  toTheSectionAndType( discipline_id: any,externalId: any, sectionExternalId: any, contentType: any) {
    this.router.navigate(
      [
        `/disciplines/${discipline_id}/content-view/${externalId}`,
        { section: sectionExternalId, type: contentType },
      ],
    );
  }

  toAnnouncements(discipline_id: any) {
    this.router.navigate(
      [`/disciplines/${discipline_id}/announcements`],
    );
  }

  toContent(discipline: string, section: string, content: string, type: string) {
    this.router.navigate(['/disciplines', discipline, 'content-view', content], {
      queryParams: {
        section: section,
        type: type
      }
    });
  }


  toDisciplineOverview(discipline_id: any) {
    this.router.navigate(
      [`/disciplines/${discipline_id}/overview`],
    );
  }

  toDisciplines() {
    this.router.navigate(
      [`/disciplines`],
    );
  }

  toSettings() {
    this.router.navigate(
      [`/settings`],
    );
  }

  toUsers() {
    this.router.navigate(
      [`/users`],
    );
  }

  toTheContent( discipline_id: any,externalId: any, sectionExternalId: any, sectionTitle: any, contentType: any) {
    this.router.navigate(
      [
        `../disciplines/${discipline_id}/content-view/${externalId}`,
        { section: sectionExternalId, section_title: sectionTitle, type: contentType },
      ],
    );
  }

}
