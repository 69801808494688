import { Injectable } from '@angular/core';
import {
    BehaviorSubject, Subject
} from 'rxjs';

import { LoginService } from '../pages/login/login.service';
import { PlatformModalsService } from './modals/platform-modals.service';

declare const gapi: any;
declare const google: any;

@Injectable({
    providedIn: 'root'
})

export class GoogleDrivePickerService {
    private scope = 'https://www.googleapis.com/auth/drive.file'; // By Di -> O escopo deve ser apenas no drive file
    public clientId = '';
    public apiKey = '';
    public appId = '';
    public backendAccount = '';
    private oauthAccessToken:any = null;
    private pickerApiLoaded = false;
    private pickerCallback = null;
    private selectOption: any;
    private fileType: any;
    private view: any;
    private feature: any;
    private tokenClient: any;
    private currentLanguage: any;
    private isShowUploadTab: any;

    callbackReponse$ : Subject<any> = new BehaviorSubject<any>({});
    updatedTokenPicker = '';

    constructor(
        private loginService: LoginService,
        public platModalService: PlatformModalsService,
    ) {
        this.clientId = JSON.parse(localStorage.getItem('clientId') || 'null');
        this.apiKey = JSON.parse(localStorage.getItem('apiKey') || 'null');
        this.appId = JSON.parse(localStorage.getItem('appId') || 'null');
        this.backendAccount = JSON.parse(localStorage.getItem('mail') || 'null');
    }

    public open(isShowUploadTab, select, fileType, callback) {
        this.getGoogleAuthTokenPicker();
        this.onAuthApiLoad(callback);
        gapi.load('client');

        this.pickerCallback = callback;
        this.selectOption = select;
        this.fileType = fileType;
        this.isShowUploadTab = isShowUploadTab;
    }

    public callAddScopeAuthGoogle(callback: Function) {
        gapi.load('client');
        this.onAuthApiLoad(callback);
        this.getRequestCode();
    }

    public setPicker(isShowUploadTab, select, fileType, tokenPicker, callback) {
        console.log('tokenPicker ', tokenPicker);

        this.pickerCallback = callback;
        this.selectOption = select;
        this.fileType = fileType;
        this.isShowUploadTab = isShowUploadTab;

        if (tokenPicker) {
            this.gapiLoad(tokenPicker);
        }
    }

    // Adiciona scopo do drive para o usuário google do cliente
    private onAuthApiLoad(returnToken: Function) {
        this.tokenClient = google.accounts.oauth2.initCodeClient({
            client_id: this.clientId,
            scope: this.scope,
            callback: (response) => {
                console.log('response ', response);
                if (response.hasOwnProperty('error')) {
                    this.callbackReponse$.next(response);
                } else {
                    // Transforma o novo código gerado depois da permissões em um token válido
                    this.loginService.loginWithGoogle({ code: response.code }).subscribe({
                        next: (res) => {
                            this.loginService.setGoogleCredencials(res);
                            console.log('returnToken ', returnToken);
                            // Atualiza (googleAuthTokenPicker,googleAuthToken) localStorega
                            if (returnToken instanceof Function) {
                                returnToken(res.token_picker);
                            }
                            this.gapiLoad(res.token_picker);
                        },
                        error: (err) => {
                            if (err.status == 401) {
                                response = { error: { errCode: 400, error: 'invalid_grant' } };
                                this.callbackReponse$.next(response);
                            }
                        }
                    });
                }
            },
        });
    }

    getRequestCode() {
        this.tokenClient.requestCode();
    }

    gapiLoad(token) {
        console.log('gapiLoad(token) ', token);
        console.log('gapi.hasOwnProperty(client) ', gapi.hasOwnProperty('client'));

        this.updatedTokenPicker = token;
        if (gapi.hasOwnProperty('client')) {
            gapi.client.setToken({ access_token: token }); // Atualiza o novo token (permissions)
            gapi.load('client:picker', this.initializePicker.bind(this));
        } else {
            gapi.load('client');
            setTimeout(() => {
                this.gapiLoad(token);
            }, 500);
        }
    }

    /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
  */
    async initializePicker() {
        await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
            .then((response) => {
                console.log('response ', response);

                console.log('GAPI client loaded for API');
            }, (err) => {
                console.log('GAPI ERROR ', err);

                console.error('Error loading GAPI client for API', err);
            });

        this.pickerApiLoaded = true;
        // this.getGoogleAuthTokenPicker();
        this.getTokenToBuildPicker(this.updatedTokenPicker);
    }

    authRefreshToken() {
        this.loginService.refreshTokenGoogle().subscribe({
            next: (response) => {
                if (response.hasOwnProperty('token_picker')) {
                    localStorage.setItem('googleAuthTokenPicker', JSON.stringify(response.token_picker));
                    // this.getTokenToBuildPicker(response.token_picker);
                    this.getRequestCode();
                }
            },
            error: (err) => {
                console.log('CALLBACK HAS ERROR authRefreshToken', err);
                this.callbackReponse$.next(err);
            }
        });
    }

    checkTokenValid(token) {
        this.loginService.tokenInfoGoogle(token).subscribe({
            next: (tokenInfo) => {
                console.log('checkTokenValid tokenInfo', tokenInfo);
                if (this.checkScopeDrive(tokenInfo)) {
                    this.gapiLoad(token);
                } else {
                    this.authRefreshToken();
                }
            },
            error: (err) => {
                console.log('checkTokenValid ERRO ', err);
                this.authRefreshToken();
            }
        });
    }

    checkScopeDrive(tokenInfo) {
        if (tokenInfo.scope.includes('auth/drive.file')) {
            return true;
        }

        return false;
    }

    getGoogleAuthTokenPicker() {
        if (localStorage.getItem('googleAuthTokenPicker') !== null) {
            const googleAuthTokenPicker = JSON.parse(localStorage.getItem('googleAuthTokenPicker') || '');
            if (googleAuthTokenPicker !== null && googleAuthTokenPicker !== undefined && googleAuthTokenPicker !== '') {
                this.checkTokenValid(googleAuthTokenPicker);
            }
        } else {
            this.authRefreshToken();
        }
    }

    getTokenToBuildPicker(googleAuthTokenPicker) {
        this.oauthAccessToken = googleAuthTokenPicker;
        console.log('this.isPickerAlreadyCreated ', this.isPickerAlreadyCreated);

        if (!this.isPickerAlreadyCreated) {
            this.createPicker();
        }
    }

    viewGroup: any;
    isPickerAlreadyCreated = false;
    private createPicker(): void {
        const viewGroup = new google.picker.ViewGroup(google.picker.ViewId.DOCS);

        let defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS);
        let Teamdrivesview = new google.picker.DocsView();

        if (this.pickerApiLoaded && this.oauthAccessToken) {
            console.log('this.fileType ', this.fileType);

            switch (this.fileType) {
                case 'photo':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS);
                    break;
                case 'images':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES);
                    break;
                case 'video':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS_VIDEOS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.DOCS_VIDEOS);
                    break;
                case 'presentation':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.PRESENTATIONS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.PRESENTATIONS);
                    break;
                case 'document':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCUMENTS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.DOCUMENTS);
                    break;
                case 'sheets':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);
                    break;
                case 'forms':
                    defaultView = new google.picker.DocsView(google.picker.ViewId.FORMS);
                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.FORMS);
                    break;
                case 'noForms':
                    const mimeTypesConfig = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/plain,'
            + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,'
            + 'image/jpeg,image/png,image/gif,image/bmp,image/heif,image/heic,'
            + 'application/vnd.google-apps.folder,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
            + 'audio/mpeg,audio/x-wav,audio/ogg,'
            + 'application/vnd.google-apps.spreadsheet,'
            + 'application/vnd.google-apps.presentation,'
            + 'application/vnd.google-apps.drawing,'
            + 'application/vnd.google-apps.document,'
            + 'application/vnd.google-apps.photo,'
            + 'application/vnd.google-apps.video,'
            + 'application/vnd.google-apps.file,'
            + 'video/mp4,video/x-msvideo,video/quicktime';

                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS);
                    defaultView.setMimeTypes(mimeTypesConfig);
                    viewGroup.addView(defaultView);

                    Teamdrivesview = new google.picker.DocsView(google.picker.ViewId.DOCS);
                    Teamdrivesview.setMimeTypes(mimeTypesConfig);
                    viewGroup.addView(Teamdrivesview);

                    break;

                default:
                    defaultView = new google.picker.DocsView(google.picker.ViewId.DOCS);
                    Teamdrivesview = new google.picker.DocsView();
                    break;
            }

            defaultView.setIncludeFolders(true);
            defaultView.setEnableDrives(false);
            defaultView.setSelectFolderEnabled(false);
            defaultView.setOwnedByMe(true);

            Teamdrivesview.setOwnedByMe(false);
            Teamdrivesview.setIncludeFolders(true);
            Teamdrivesview.setSelectFolderEnabled(false);
            Teamdrivesview.setEnableDrives(true);

            if (this.selectOption == 'multiple') {
                this.feature = google.picker.Feature.MULTISELECT_ENABLED;
            } else {
                this.feature = '';
            }

            const currentLanguage = localStorage.getItem('currentLanguage');

            if (currentLanguage != null && currentLanguage != undefined && currentLanguage != '') {
                this.currentLanguage = currentLanguage;
            }

            let uploadView;
            if (this.isShowUploadTab) {
                uploadView = new google.picker.DocsUploadView();
            } else {
                uploadView = '';
            }

            const picker = new google.picker.PickerBuilder()
                .enableFeature(this.feature)
                .addViewGroup(defaultView)
                .addViewGroup(Teamdrivesview)
                .addView(uploadView)
                .setLocale(this.currentLanguage)
                .setAppId(this.appId || JSON.parse(localStorage.getItem('appId') || 'null'))
                .setOAuthToken(this.oauthAccessToken)
                .setDeveloperKey(this.apiKey || JSON.parse(localStorage.getItem('apiKey') || 'null'))
                .setCallback(this.pickerCallback)
                .build();
            picker.setVisible(true);
            this.isPickerAlreadyCreated = true;
        }
    }

    async insertPermission(file_id: any, callback:any) {
        console.log('file_id ', file_id);

        const getFeedbackPermission = await gapi.client.drive.permissions.create({
            fileId: file_id,
            supportsAllDrives: true,
            sendNotificationEmail: false,
            resource: {
                role: 'writer',
                type: 'user',
                emailAddress: this.backendAccount,
            }
        }).then((response) => {
            console.log(response, 'permission ok');
            return response;
        }, (error: any) => {
            console.log('error Insert Permission Drive ', error);
            return error;
        });

        // getFeedbackPermission commented for testing purposes
        // const getFeedbackPermission = {
        //     result: {
        //         kind: 'drive#permission',
        //         id: '14772850054921604201',
        //         type: 'user',
        //         role: 'writer'
        //     },
        //     body: '{\n  "kind": "drive#permission",\n  "id": "14772850054921604201",\n  "type": "user",\n  "role": "writer"\n}\n',
        //     headers: {
        //         'cache-control': 'no-cache, no-store, max-age=0, must-revalidate',
        //         'content-encoding': 'gzip',
        //         'content-length': '103',
        //         'content-type': 'application/json; charset=UTF-8',
        //         date: 'Mon, 17 Jun 2024 18:02:24 GMT',
        //         expires: 'Mon, 01 Jan 1990 00:00:00 GMT',
        //         pragma: 'no-cache',
        //         server: 'ESF',
        //         vary: 'Origin, X-Origin'
        //     },
        //     status: 403,
        //     statusText: null
        // };

        if (getFeedbackPermission) {
            callback(getFeedbackPermission);
        }
    }
}
