import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(
        private http: HttpClient
    ) { }

    uploadFile(params:any) {
        return this.http.post(`${API_Routes.URL}/files`, params);
    }

    postFile(file: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/files`, file);
    }
}
