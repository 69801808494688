import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GradesService } from '../../grades.service';
import { finalize } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { MessengerToastBaseService } from 'src/app/components/messenger-toast/messenger-toast-base/messenger-toast-base.service';


@Component({
  selector: 'app-grades-table',
  templateUrl: './grades-table.component.html',
  styleUrls: ['./grades-table.component.scss']
})
export class GradesTableComponent implements OnInit, OnChanges {
  @Input() grades: any = {};
  @Input() countCategories: any;
  @Input() periodId: any;
  @Input() dad: string = '';
  i18n: any = {};
  isSaving: boolean = false;
  decimal_places_mask: string = '';
  inputType: string = 'text';
  type: string = '';
  category_weight: any;


  constructor(
    private gradesService: GradesService,
    private platModalService: PlatformModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedSerivce: SharedService,
    private toastBaseMessenger: MessengerToastBaseService,

  ) { }

  ngOnInit(): void {
    this.getTranslations();

    if (this.grades?.students) {
        this.type = this.grades.students[0].grades[0].calculation_type;
        this.category_weight = +this.grades.students[0].grades[0].category_weight;

        if (this.type === 'sum' || this.type === 'weighted_average') {
            this.inputType = 'number';
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.grades && this.grades) {
      this.grades.students.map((item)=>{
        if (item.average) {
          item.average = this.sharedSerivce.fnReplaceDotForComa(item.average);
        }
      });
      // Defines a mask receiving decimal places from backend
      this.decimal_places_mask = 'separator.'+this.grades.decimal_places;
    }
  }

  getTranslations(){
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades')
  }

  fnGetCategoryPercent(grade_weight: any){
    let translation = '';
    return translation = this.i18n['grades_grade_category_percentage'].replace('#', grade_weight)
  }

  goToStudentDetail(student:any, period: any){
    this.route?.params?.subscribe((params)=> {
      if (params && (Object.keys(params).length === 0)) {
        this.router.navigate([`../overview/student/${student.external_id}`, {open: period.id}], { relativeTo: this.route });
      }else{
        this.router.navigate([`../${params.id}/student/${student.external_id}`], { relativeTo: this.route });
      }
    });
  }

  lastCategoryId: number;
  checkLastCategoryName(category_id: any){

    if (this.lastCategoryId !== category_id) {
      this.lastCategoryId = category_id;
      return true;
    }
  }

  fnColSpan(grade_category_id: any){
    return this.countCategories[grade_category_id]
  }

  fnGetSubistitutiveName(content_substitutive_id: any, grades: any){
    let substitutive_name: any;
    if (content_substitutive_id !== null) {

      grades.forEach((grade:any)=>{
        if (grade.content_id == content_substitutive_id) {
          substitutive_name = grade.content_title
          return;
        }

      });
      return substitutive_name;

    }
  }

  patchGrade(grade: any, gradeValue: any){
    this.isSaving = true;
    if (gradeValue.includes(',')) {
      gradeValue = gradeValue.replace(',','.');
    }

    const weigth = parseFloat(grade.content_weight);
    const gradeValueFloat = parseFloat(gradeValue);

    console.log(gradeValueFloat, weigth, grade);

    if (gradeValueFloat > weigth && this.type === 'sum') {
      grade.evaluation = '';
      this.isSaving = false;
      this.platModalService.toggle('message', this.i18n.grades_grade_cannot_be_higher_than_max_grade, 'close');
      return;
    }

    if (gradeValueFloat > 100 && this.type === 'weighted_average') {
      grade.evaluation = '';
      this.isSaving = false;
      this.platModalService.toggle('message', this.i18n.grades_grade_cannot_be_higher_than_max_grade, 'close');
      return;
    }

    const params = {
      grade: gradeValueFloat,
    }
    this.gradesService.patchGrade(grade?.coursework_id, params)
    .pipe(
      finalize(()=>{
        this.isSaving = false;
        this.gradesService.checkForUpdatesOnGrades(this.periodId);
      })
    )
    .subscribe({
      next: () => {
        this.toastBaseMessenger.messengerToast('base-success', this.i18n.grades_saved, true);
      },
      error: (err) => {
        const keyTranslation = err.error.error ?? err.error.message;
        let message = this.i18n[keyTranslation]
        this.platModalService.toggle('message', message, 'close');
      }
    })
  }
}
