import {
    Directive, EventEmitter, HostListener, Input, Output
} from '@angular/core';

@Directive({
    selector: '[appDecimalPlaces]'
})
export class DecimalPlacesDirective {
    @Input('appDecimalPlaces') decimalPlaces: number;

  @Output() onValueChange = new EventEmitter();
  // Allow only numbers, dot and comma
  private OnlyNumbersRegex: RegExp = new RegExp(/^[0-9.,]+$/);

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const decimalIndex = value.search(/[,\.]/);
    
    if (decimalIndex !== -1) {
      const decimalSeparator = value[decimalIndex] === ',' ? ',' : '.';
      const decimalPart = value.substring(decimalIndex + 1);
      const trimmedDecimalPart = decimalPart.substring(0, this.decimalPlaces);
      value = value.substring(0, decimalIndex) + decimalSeparator + trimmedDecimalPart;
    }

    if (value.length > 9) {
      if (!(decimalIndex !== -1)) {
        value = value.slice(0,9);
      }
    }
    
    const onlyNumbers = this.getOnlyNumber(value);
    
    value = onlyNumbers;
    this.onValueChange.emit(value);
  
  }

  private getOnlyNumber(inputValue: string): string {
    
    let newValue = '';
    for (const char of inputValue) {
      
      if (this.OnlyNumbersRegex.test(char)) {
        newValue += char;
      }
    }
    return newValue;
  }
}
