<section class="text-customBlue-default">
    <h1 class="text-22 font-bold font-Montserrat">{{ isEditMode ? i18n.planner_edit : i18n.planner_new }} {{ i18n.planner_planning }}</h1>

    <header class="mt-8 mb-5">
        <h2 class="font-extrabold font-Montserrat">{{ i18n.planner_step_one_basic_insertion }}</h2>
        <p>{{ i18n.planner_for_start_fill_the_fields }}:</p>
    </header>

    <form [formGroup]="form" class="grid grid-cols-1 sm:grid-cols-2 gap-6 bg-customWhite-default p-7 pb-12 rounded-2xl border border-dashed border-customGray-mediumGray">
        <fieldset class="flex flex-col gap-1">
            <label class="font-semibold font-Montserrat" for="segment">{{ i18n.planner_segment }}</label>
            <select class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-2.5" formControlName="segment" id="segment" (change)="getNextColumn('segment', 'degree')">
                <option value="" disabled>{{ i18n.planner_select }}</option>
                <option [value]="segment.id" *ngFor="let segment of segmentsList">{{ segment.value }}</option>
                <option value="error" *ngIf="!segmentsList.length" disabled>{{ i18n.planner_do_not_have_segment_to_select }}</option>
            </select>

            <div *ngIf="form.get('segment')?.invalid && (form.get('segment')?.dirty || form.get('segment')?.touched)">
              <span class="text-inicieColors-orange text-16 italic font-roboto">{{ i18n.planner_error_field_required }}</span>
            </div>
        </fieldset>

        <fieldset class="flex flex-col gap-1">
            <label class="font-semibold font-Montserrat" for="degree">{{ i18n.planner_degree_or_serie }}</label>
            <select class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-2.5" formControlName="degree" id="degree" (change)="getNextColumn('degree', 'area')">
                <option value="" *ngIf="degreesList.length" disabled>{{ i18n.planner_select }}</option>
                <option [value]="degree.id" *ngFor="let degree of degreesList">{{ degree.value }}</option>
                <option value="error" *ngIf="!degreesList.length" disabled>{{ i18n.planner_do_not_have_degree_to_select }}</option>
            </select>
            <div *ngIf="form.get('degree')?.invalid && (form.get('degree')?.dirty || form.get('degree')?.touched)">
              <span class="text-inicieColors-orange text-16 italic font-roboto">{{ i18n.planner_error_field_required }}</span>
            </div>
        </fieldset>

        <fieldset class="flex flex-col gap-1">
            <label class="font-semibold font-Montserrat" for="area">{{ i18n.planner_area }}</label>
            <select class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-2.5" formControlName="area" id="area" (change)="getNextColumn('area', 'component')">
                <option value="" *ngIf="areasList.length" disabled>{{ i18n.planner_select }}</option>
                <option [value]="area.id" *ngFor="let area of areasList">{{ area.value }}</option>
                <option value="error" *ngIf="!areasList.length" disabled>{{ i18n.planner_do_not_have_area_to_select }}</option>
            </select>
            <div *ngIf="form.get('area')?.invalid && (form.get('area')?.dirty || form.get('area')?.touched)">
              <span class="text-inicieColors-orange text-16 italic font-roboto">{{ i18n.planner_error_field_required }}</span>
            </div>
        </fieldset>

        <fieldset class="flex flex-col gap-1">
            <label class="font-semibold font-Montserrat" for="component">{{ i18n.planner_curricular_component }}</label>
            <select class="truncate w-full text-customGray-grayThree bg-customWhite-default border border-customGray-mediumGray px-4 py-2.5" formControlName="component" id="component">
                <option value="" *ngIf="componentsList.length" disabled>{{ i18n.planner_select }}</option>
                <option [value]="curricular.id" *ngFor="let curricular of componentsList">{{ curricular.value }}</option>
                <option value="error" *ngIf="!componentsList.length" disabled>{{ i18n.planner_do_not_have_curricular_component_to_select }}</option>
            </select>
            <div *ngIf="form.get('component')?.invalid && (form.get('component')?.dirty || form.get('component')?.touched)">
              <span class="text-inicieColors-orange text-16 italic font-roboto">{{ i18n.planner_error_field_required }}</span>
            </div>
        </fieldset>
    </form>
    <footer class="flex flex-col sm:flex-row justify-end gap-3 mt-5">
        <a [routerLink]="['/class-planner']" class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded">
            {{ i18n.planner_back }}
        </a>

        <button type="button" (click)="onSubmit()" class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded">
          {{ isEditMode ? i18n.planner_edit : i18n.planner_continue }}
        </button>
    </footer>
</section>
