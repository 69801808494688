<section>
    <header
        class="bg-customHeader-default sm:h-52 text-customBlue-default p-8 mb-12 flex flex-col sm:flex-row justify-between gap-4"
    >
        <div>
            <p class="text-19 font-medium">{{ i18n.planner }}</p>
            <p>{{ i18n.planner_create_custom_planners }}</p>
            <div class="flex gap-3">
                <a
                    [routerLink]="['/class-planner/start-planning']"
                    class="inline-block text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded mt-4"
                    *ngIf="activeRole.includes('admin') && planningList.length"
                    >{{ i18n.planner_create_new }}</a
                >
                <a
                    [routerLink]="['/class-planner/naviglable-curriculum']"
                    class="inline-block text-customBlue-default border border-customBlue-default py-1.5 px-10 rounded mt-4"
                    *ngIf="activeRole.includes('admin') && planningList.length"
                >
                    {{ i18n.planner_navigable_curriculum }}
                </a>
            </div>
        </div>

        <img
            src="./assets/png/content-planner/img-illustration-content-planner.png"
            class="w-max mx-auto sm:mx-0"
            [alt]="i18n.planner_illustration_planner"
        />
    </header>

    <article
        class="flex items-center justify-end gap-4 text-customGray-default mb-8"
    >
        <div
            class="flex items-center rounded-lg border bg-customWhite-default border-customWhite-bWhite w-full sm:w-auto"
        >
            <label class="hidden" for="search-planning">{{
                i18n.planner_looking_for_planners
            }}</label>
            <input
                class="px-4 py-2 rounded-lg w-full outline-none"
                type="text"
                name="search-planning"
                id="search-planning"
                autocomplete="off"
                [(ngModel)]="search_planning_filter"
            />
            <button class="cursor-pointer p-2" type="button">
                <span
                    class="iconify text-24"
                    data-icon="mingcute:search-line"
                ></span>
            </button>
        </div>

        <button
            type="button"
            (click)="displayPanel.toggle()"
            class="relative border bg-customWhite-default border-customWhite-bWhite h-10 p-2 w-max rounded-lg flex items-center"
        >
            <span class="iconify text-24" data-icon="carbon:filter"></span>

            <app-display-panel
                #displayPanel
                [style]="{ right: '-3.625rem', top: '3.5rem' }"
                [arrow_deslocation]="'50%'"
                [hide_arrow]="false"
            >
                <button
                    type="button"
                    class="p-2"
                    (click)="filterByTime('firsts')"
                >
                    {{ i18n.planner_firsts }}
                </button>
                <button
                    type="button"
                    class="p-2"
                    (click)="filterByTime('lasts')"
                >
                    {{ i18n.planner_lasts }}
                </button>
            </app-display-panel>
        </button>
    </article>

    <div class="text-customBlue-default mb-12">
        <h1 class="text-22 font-semibold font-Montserrat">
            {{ i18n.planner_my_planners }}
        </h1>
        <p *ngIf="planningList.length">
            {{ filteredPlanningList.length }}
            {{
                filteredPlanningList.length > 1
                    ? i18n.planner_results_found
                    : i18n.planner_results_found_one
            }}
        </p>
    </div>

    <article
        class="flex flex-col items-center gap-12"
        *ngIf="!planningList.length"
    >
        <div class="text-18 font-medium text-center text-customGray-default">
            <p>{{ i18n.planner_list_empty }}.</p>
        </div>

        <a
            [routerLink]="['/class-planner/start-planning']"
            class="flex items-center gap-4 w-max"
            *ngIf="activeRole.includes('admin')"
        >
            <div
                class="w-10 h-10 bg-customOrange-default text-customWhite-default rounded-full flex justify-center items-center font-bold text-18"
            >
                +
            </div>
            <span class="font-medium text-customBlue-default">{{
                i18n.planner_add_new_planner
            }}</span>
        </a>
    </article>

    <ul class="flex flex-col gap-6 mt-8" *ngIf="filteredPlanningList.length">
        <li
            class="bg-customWhite-default p-4 border-customBlue-royal rounded-2xl flex flex-col md:flex-row justify-between items-center gap-4 border-l-16"
            *ngFor="let planning of filteredPlanningList"
        >
            <div class="text-customBlue-default flex flex-col">
                <p class="text-19 font-semibold font-Montserrat">
                    {{ planning.degree_name + " - " + planning.component_name }}
                </p>
                <small
                    class="text-13 text-customBlue-default font-Roboto font-normal"
                    >{{ i18n.planner_created_by }}
                    {{ planning.created_by.name }}</small
                >
                <small
                    class="text-13 text-customBlue-default font-Roboto font-normal"
                    >{{ i18n.planner_last_update }}</small
                >
                <div
                    class="flex gap-1 font-Roboto bg-customGray-gainsboro py-1 px-2 rounded-sm text-customBlue-royal justify-start items-center"
                >
                    <div
                        class="bg-customGray-gainsboro h-6"
                        style="width: 2.25rem"
                    >
                        <app-user-frame
                            [img_src]="planning.updated_by.image"
                            [user_name]="planning.updated_by.name"
                            [adaptable_size]="true"
                        ></app-user-frame>
                    </div>
                    <span class="text-13">
                        <span class="font-medium">{{
                            planning.updated_by.name
                        }}</span>
                        {{ i18n.planner_in }}
                        {{
                            planning.updated_at
                                | date
                                    : "longDate"
                                    : ""
                                    : (currentLanguage === "en"
                                          ? "en-US"
                                          : "pt-BR")
                        }}
                    </span>
                </div>
            </div>
            <div class="flex items-center gap-2">
                <div class="flex flex-col gap-2 items-end">
                    <div class="flex items-center gap-3">
                        <a
                            class="border border-customBlue-default bg-customWhite-default py-1.5 px-8 rounded text-15 text-customBlue-default w-44 text-center lineheight-15"
                            [routerLink]="[getRouteLinkDiscipline(planning)]"
                            >{{ i18n.planner_go_to_discipline_model }}</a
                        >
                    </div>
                    <div
                        class="flex items-stretch flex-wrap md:flex-nowrap justify-end gap-3"
                    >
                        <a
                            class="bg-transparent border border-customBlue-default text-customBlue-default py-1 px-8 rounded whitespace-nowrap"
                            [routerLink]="[
                                '/class-planner/current-year',
                                planning.external_id
                            ]"
                            *ngIf="activeRole.includes('teacher')"
                            >{{ i18n.planner_current_year }}</a
                        >
                        <a
                            class="bg-customBlue-default text-customWhite-default py-1.5 px-8 rounded w-44 text-center"
                            [routerLink]="[
                                '/class-planner/my-planning',
                                planning.external_id
                            ]"
                            >{{ i18n.planner_visualize }}</a
                        >
                    </div>
                </div>
                <button
                    type="button"
                    class="p-2 text-customBlue-default relative"
                    (click)="displayPanelPlanning.toggle()"
                    *ngIf="activeRole.includes('admin')"
                >
                    <span
                        class="iconify text-19"
                        data-icon="mdi:dots-vertical"
                    ></span>

                    <app-display-panel
                        #displayPanelPlanning
                        [style]="{ right: '-1rem', top: '3.5rem' }"
                        [arrow_deslocation]="'50%'"
                        [hide_arrow]="false"
                    >
                        <a
                            [routerLink]="[
                                '/class-planner/start-planning',
                                planning.external_id
                            ]"
                            class="p-1"
                            >{{ i18n.planner_edit }}</a
                        >
                        <button
                            type="button"
                            class="p-1"
                            (click)="
                                decisionDeletePlanning(planning.external_id)
                            "
                        >
                            {{ i18n.planner_delete }}
                        </button>
                    </app-display-panel>
                </button>
            </div>
        </li>
    </ul>
</section>
