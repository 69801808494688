import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-modal-confirm-classroom',
    templateUrl: './modal-confirm-classroom.component.html',
    styleUrls: ['./modal-confirm-classroom.component.scss']
})
export class ModalConfirmClassroomComponent {
    @Input() message = '';
    @Input() btnText = '';
    @Input() isVisible = true;

    @Output() closeEmit = new EventEmitter();

    close(event: any) {
        this.closeEmit.emit(event);
    }
}
