import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
//import jwt_decode from 'jwt-decode';
import { DashboardService } from '../dashboard.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-teacher',
    templateUrl: './teacher.component.html',
    styleUrls: ['./teacher.component.scss'],
})
export class TeacherComponent implements OnInit {
    @Input() user: any = [];
    @Input() dashData: any = [];
    @Input() i18n: any = [];
    isLoading$ = this.dashboardService.isLoading$;
    showLoading: boolean;
    isMyTasksActive = false;
    isMobile = false;
    userName: string;

    constructor(
        private dashboardService: DashboardService,
    ) { }

    ngOnInit(): void {
        this.getLoading();
        this.checkWindowSize();
        this.getUserName();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.checkWindowSize();
    }

    getUserName(): void {
        this.userName = this.user?.name;

        const arrayName = this.user?.name.split(' ');

        if (arrayName.length > 4) {
            this.userName = `${arrayName[0]} ${arrayName[1]} ${arrayName[2]} ${arrayName[3]}...`;
        }
    }

    checkWindowSize(): void {
        if (window.innerWidth < 640) {
            this.isMyTasksActive = true;
            this.isMobile = true;
        } else {
            this.isMyTasksActive = false;
            this.isMobile = false;
        }
    }

    getLoading() {
        this.isLoading$.subscribe((data) => { this.showLoading = data; });
    }

    toggleTabs(activeTab: string): void {
        if (activeTab === 'my-tasks') {
            this.isMyTasksActive = true;
        } else {
            this.isMyTasksActive = false;
        }
    }
}
