import {
    animate,
    AUTO_STYLE,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { DisciplinesModelsService } from 'src/app/shared/disciplines-models.service';

import { PaginationComponent } from '../../pagination/pagination.component';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-import-classroom-modal',
    templateUrl: './import-classroom-modal.component.html',
    styleUrls: ['./import-classroom-modal.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true => false',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false => true',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class ImportClassroomModalComponent implements OnInit {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    typedData = new FormControl();

    paginationParams: any = {};
    activePage = 1;
    per_page = 5;
    selectedDiscipline: any = null;

    query = '';

    title: string;
    loading: boolean;
    subTitle: string;
    selectAll: string;
    available: string;
    buttonSave: string;
    modelForm: UntypedFormGroup;
    contentsFormEvent: any = [];
    collapseSection = true;
    filteredSections: any = [];
    textFound: any = [];
    alteredFilter = false;
    controlAllSelect = false;
    templateSelectionMode = true;
    templateCode: any = [];
    targetDisciplinesToInput: any[] = [];
    isSearch = false;

    dataDisciplines: any = [];

    filtered: any = [];

    selectedOption: 'private' | 'google_classroom' = 'private';

    selectedDisciplinesClassroom: string[] = [];

    @Input() type: type = { type: 'template' };

    @Input() isItInTheTemplates: boolean;
    @Input() contentSelectionMode = true;
    @Input() disciplinesRawData: any = [];
    @Input() disciplineCode: any = [];
    @Input() sections: any = [];
    @Input() i18n: any = [];
    @Input() receiveExternalIDContent = '';

    @Output() sendingImportTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() sendingDisciplineData: EventEmitter<boolean> = new EventEmitter();
    @Output() sendingExportDisciplineData: EventEmitter<boolean> = new EventEmitter();
    @Output() getSectionsTemplate: EventEmitter<boolean> = new EventEmitter();
    @Output() getDisciplines: EventEmitter<boolean> = new EventEmitter();
    @Output() importTemplateInDiscipline: EventEmitter<boolean> = new EventEmitter();

    isImportModelOpen$ = this.disciplinesModelsService.isImportModelOpen$;
    isImportModelClassroom$ = this.disciplinesModelsService.isImportModelClassroomOpen$;
    isLoadingModal$ = this.disciplinesModelsService.isLoadingModal$;

    showTooltip = false;
    availabe_mode = 'PUBLISHED';
    availabe_modes: any;

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        private formBuilder: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.createContentsForm();
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
        this.getLoading();
        this.receiveTypedData();
        this.setAvailableModes();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filteredSections = changes?.sections?.currentValue;
        const rawData = changes?.disciplinesRawData?.currentValue;
        this.receiveDisciplineRawData(rawData);
    }

    selectAllSections() {
        this.controlAllSelect = !this.controlAllSelect;
        if (this.controlAllSelect) {
            this.selectAll = this.i18n.disciplines_unselect_all;
        } else {
            this.selectAll = this.i18n.disciplines_select_all;
        }
    }

    closeModalImport() {
        this.disciplinesModelsService.addModelModalClose();
    }

    closeModalClassroomImport() {
        this.firstClasrrom = 1;
        this.first = 1;

        this.disciplinesModelsService.addModelModalClassroomClose();
    }

    toggleSection() {
        this.collapseSection = !this.collapseSection;
    }

    getLoading() {
        this.isLoadingModal$.subscribe((loading) => (this.loading = loading));
    }

    continueImport() {
        this.contentSelectionMode = !this.contentSelectionMode;
        this.templateSelectionMode = !this.templateSelectionMode;
        this.selectedOption = 'private';
        this.first = 1;
        this.firstClasrrom = 1;
        this.modalMode(this.contentSelectionMode, this.templateSelectionMode);
    }

    gettingSectionsTemplate() {
        this.getSectionsTemplate?.emit();
    }

    gettingDisciplines(page, per_page) {
        const params: any = {
            page,
            per_page,
            type: this.selectedOption,
        };
        this.getDisciplines?.emit(params);
    }

    first = 1;
    firstClasrrom = 1;

    receiveDisciplineRawData(disciplineData) {
        this.dataDisciplines = disciplineData?.items;

        if (this.selectedOption != 'google_classroom' && this.dataDisciplines?.length && this.first == 1 && !this.isSearch) {
            this.refreshPagination(disciplineData);
            this.first = 0;
        }

        if (this.selectedOption == 'google_classroom' && this.dataDisciplines?.length && this.firstClasrrom == 1 && !this.isSearch) {
            this.refreshPagination(disciplineData);
            this.firstClasrrom = 0;
        }

        if (this.isSearch) {
            this.refreshPagination(disciplineData);
        }

        this.isSearch = false;
    }

    search(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value) {
            const { value } = target;
            this.alteredFilter = true;

            this.sections.forEach((section, index) => {
                section.contents.filter((content, i) => {
                    if (content.title.toLowerCase().includes(value.toLocaleLowerCase())) {
                        this.filteredSections = [this.sections[index]];
                        this.textFound = i;
                    }
                });
            });
        } else {
            this.filteredSections = this.sections;
            this.alteredFilter = false;
        }
    }

    modalMode(contentSelectionMode, templateSelectionMode) {
        if (this.isItInTheTemplates) {
            if (contentSelectionMode == true) {
                (this.title = this.i18n.disciplines_export_content),
                    (this.subTitle = this.i18n.disciplines_select_to_export),
                    (this.available = this.i18n.disciplines_contents_available),
                    (this.selectAll = this.i18n.disciplines_select_all),
                    (this.buttonSave = this.i18n.disciplines_continue);
            } else if (contentSelectionMode == false) {
                (this.title = this.i18n.disciplines_export_discipline_model),
                    (this.subTitle = this.i18n.disciplines_select_classes_to_export),
                    (this.available = this.i18n.disciplines_classes_available),
                    (this.selectAll = this.i18n.disciplines_select_all),
                    (this.buttonSave = this.i18n.disciplines_export);
            }
        } else if (templateSelectionMode == false) {
            (this.title = this.i18n.disciplines_import),
                (this.subTitle = this.i18n.disciplines_select_to_import),
                (this.available = this.i18n.disciplines_contents_available),
                (this.selectAll = this.i18n.disciplines_select_all),
                (this.buttonSave = this.i18n.disciplines_import);
        } else if (templateSelectionMode == true) {
            this.selectedOption = 'google_classroom';
            (this.title = this.i18n.disicplines_title_import_classroom),
                (this.subTitle = this.i18n.disciplines_subtitle_modal_import_classroom),
                (this.available = this.i18n.disciplines_classes_available),
                (this.selectAll = this.i18n.disciplines_select_all),
                (this.buttonSave = this.i18n.disciplines_import);
        }
    }

    sendEventToExportData() {
        // vai chamar o post para a exportação
        const targetDiscipline = this.modelForm?.value?.targetDisciplines[0];

        if (!targetDiscipline) {
            this.disciplinesModelsService.showMessage(
                'Erro',
                'Selecione uma turma',
                'toast-error'
            );

            return;
        }

        const data = this.modelForm?.value;
        data.type = this.selectedOption;
        data.availabe_mode = this.availabe_mode;

        this.sendingExportDisciplineData.emit(data);
    }

    sendEnventToImportData() {
        // vai chamar o post para a importação
        const targetDiscipline = this.modelForm?.value?.targetDisciplines;

        console.log('target-discipline', targetDiscipline);

        if (!targetDiscipline) {
            return this.disciplinesModelsService.showMessage(
                'Erro',
                'Selecione uma turma',
                'toast-error'
            );
        }

        this.sendingDisciplineData.emit(targetDiscipline);
    }

    sendEnventImportTemplate() {
        this.sendingImportTemplate.emit(this.modelForm.value);
    }

    createContentsForm() {
        if (this.isItInTheTemplates) {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            this.modelForm?.valueChanges.subscribe(() => {
                this.sendingImportTemplate.emit(this.modelForm.value);
            });
        } else {
            this.modelForm = this.formBuilder.group({
                sourceContents: [],
                sourceSections: [],
                targetDisciplines: this.formBuilder.array([]),
                keepDisciplineInSync: [false],
            });
            this.modelForm?.valueChanges.subscribe(() => {
                this.sendingImportTemplate.emit(this.modelForm.value);
            });
        }
    }

    receivesCheckedContents(dataEvent) {
        this.contentsFormEvent = dataEvent;
        this.modelForm.controls.sourceContents.setValue(this.contentsFormEvent);
    }

    receivesCheckedSection(dataEvent) {
        this.modelForm.controls.sourceSections.setValue(dataEvent);
    }

    checkDisciplineChange(event: any) {
        const targetDisciplines: UntypedFormArray = this.modelForm?.get(
            'targetDisciplines'
        ) as UntypedFormArray;
        if (event?.target?.checked) {
            targetDisciplines.push(new UntypedFormControl(event?.target?.value));
            this.targetDisciplinesToInput.push(event?.target?.value);
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                if (item.value == event?.target?.value) {
                    this.targetDisciplinesToInput.splice(
                        this.targetDisciplinesToInput.indexOf(event?.target?.value),
                        1
                    );
                    targetDisciplines.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    checkImportDisciplineChange(disciplineId: string) {
        const index = this.selectedDisciplinesClassroom.indexOf(disciplineId);

        if (index === -1) {
            // Adicionar disciplina selecionada
            this.selectedDisciplinesClassroom.push(disciplineId);
            this.addDisciplineToForm(disciplineId);
        } else {
            // Remover disciplina desmarcada
            this.selectedDisciplinesClassroom = this.selectedDisciplinesClassroom.filter((item) => item !== disciplineId);
            this.removeDisciplineFromForm(disciplineId);
        }
    }

    addDisciplineToForm(disciplineId: string): void {
        const control = new UntypedFormControl(disciplineId);
        (this.modelForm.get('targetDisciplines') as UntypedFormArray).push(control);
    }

    removeDisciplineFromForm(disciplineId: string): void {
        const formArray = this.modelForm.get('targetDisciplines') as UntypedFormArray;
        const index = formArray.controls.findIndex((control) => control.value === disciplineId);

        if (index !== -1) {
            formArray.removeAt(index);
        }
    }

    isDisciplineSelected(disciplineId: string) {
        return this.selectedDisciplinesClassroom.includes(disciplineId);
    }

    checkTemplateChange(event: any, discipline: any) {
        const targetDisciplines: UntypedFormArray = this.modelForm?.get(
            'targetDisciplines'
        ) as UntypedFormArray;
        if (event?.target?.checked) {
            targetDisciplines.push(new UntypedFormControl(event?.target?.value));
        } else {
            let i = 0;
            targetDisciplines.controls.forEach((item: any) => {
                if (item.value == event?.target?.value) {
                    targetDisciplines.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    goToPage({ activePage }): void {
        const page = activePage;

        this.gettingDisciplines(page, this.per_page);

        if (!this.loading) {
            this.dataDisciplines = this.disciplinesRawData.items;
            this.activePage = this.disciplinesRawData.page;
        }
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    receiveTypedData() {
        this.typedData.valueChanges
            .pipe(
                map((value) => value.trim()),
                distinctUntilChanged(),
                debounceTime(500),
                map((value: any) => this.getSearch(value))
            )
            .subscribe();
    }

    getSearch(typedData) {
        typedData == undefined ? (typedData = '') : (typedData = typedData);
        this.query = typedData;
        this.isSearch = true;

        if (typedData.length >= 3) {
            this.gettingDisciplinesSearch(this.activePage, this.per_page);
        } else if (typedData.length <= 1) {
            this.query = '';
            this.gettingDisciplines(this.activePage, this.per_page);
        }
    }

    gettingDisciplinesSearch(page, per_page) {
        const params: any = {
            page,
            per_page,
            query: this.query,
            type: this.selectedOption,
        };
        this.getDisciplines?.emit(params);
    }

    selectOption(option: 'private' | 'google_classroom') {
        this.selectedOption = option as 'private' | 'google_classroom';
        // Limpar o array de disciplinas selecionadas
        this.selectedDisciplinesClassroom = [];

        // Limpar o FormArray de disciplinas selecionadas
        const formArray = this.modelForm.get('targetDisciplines') as UntypedFormArray;
        formArray.clear();

        if (this.selectedOption == 'private') {
            this.firstClasrrom = 1;
        }

        if (this.selectedOption == 'google_classroom') {
            this.first = 1;
        }

        this.gettingDisciplines(this.activePage, this.per_page);

        if (this.pagination) {
            this.pagination.params = {};
            this.pagination.initPaginationLib(true);
        }
    }

    getLabelModeAvailable() {
        if (this.availabe_mode === 'DRAFT') {
            return 'Publicar conteúdo como rascunho';
        }

        return 'Publicar conteúdo como publicado';
    }

    setAvailableMode(mode: any) {
        const availableMode = mode[0].id;

        if (availableMode) {
            this.availabe_mode = availableMode;
        }
    }

    setAvailableModes() {
        this.availabe_modes = [
            { id: 'PUBLISHED', translation: this.i18n.disciplines_content_published },
            { id: 'DRAFT', translation: this.i18n.disciplines_content_draft },
        ];
    }
}

export interface type {
    type: 'private' | 'template' | 'google_classroom';
}
