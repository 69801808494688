import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-last-announcements',
    templateUrl: './last-announcements.component.html',
    styleUrls: ['./last-announcements.component.scss'],
})
export class LastAnnouncementsComponent implements OnInit {
    @Input() dashData: any = [];
    @Input() i18n: any = [];

    constructor(
        private dashboardService: DashboardService,
    ) { }

    ngOnInit(): void { }

    textCutAnnouncements(lengthLimit: number, textAnnouncement: string) {
        let text: string = textAnnouncement;
        const elipsisOrEmpty = text.length > lengthLimit ? '...' : '';
        text = text.substring(0, lengthLimit) + elipsisOrEmpty;
        return text;
    }

    cutName(fullName: string) {
        let arr: string[] = fullName?.split(' ');
        if (arr.length <= 1) return fullName;
        let keep: boolean = arr[1][0]?.toUpperCase() !== arr[1][0];
        let names: string = arr.slice(0, keep ? 3 : 2).join(' ');
        return names;
    }

    tagsRemove(txtHtml) {
        const data = new DOMParser().parseFromString(txtHtml, 'text/html');
        return data.body.textContent || "";
    }

    removeTags(htmlTxt) {
        const data = new DOMParser().parseFromString(htmlTxt, 'text/html');
        return data.body.textContent || "";
    }

    routeToLink(resume_data: any) {
        let sectionExternalId = resume_data.object_properties.section_external_id
        let disciplineId = resume_data.object_properties.discipline_external_id
        let contentType = resume_data.object_properties.content_type
        let externalId = resume_data.object_properties.external_id
        let objectType = resume_data.object_type

        switch (objectType) {
            case 'Announcement':
                this.dashboardService
                    .toAnnouncements(disciplineId)
                break;
            case 'Content':
                this.dashboardService
                    .toTheSectionAndType(disciplineId, externalId, sectionExternalId, contentType);
                break;
        }
    }
}
