import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { getMessaging, getToken, onMessage, Messaging } from "firebase/messaging";
import { HttpClient } from '@angular/common/http';
import { API_Routes } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private http: HttpClient,
  ) { }

  firebaseMessagingSeviceWorker(): Observable<any>{
    return this.http.get(`${API_Routes.URL}/firebase/firebase-messaging`);
  }

  firebaseManifest(): Observable<any>{
    return this.http.get(`${API_Routes.URL}/firebase/manifest`);
  }

  firebaseManifestFile(): Observable<any>{
    return this.http.get(`${API_Routes.URL}/firebase/file/manifest`);
  }

  firebaseCreateDevice(params): Observable<any>{
    return this.http.post(`${API_Routes.URL}/firebase/createDevice`, params);
  }
  

}
