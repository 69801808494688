import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SportsService {
    constructor(
        private http: HttpClient
    ) { }

    getUrlIframe(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/sports/visualization`);
    }
}
