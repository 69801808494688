import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { DashboardService } from '../../dashboard.service';
import { data } from 'autoprefixer';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-card-teacher',
    templateUrl: './card-teacher.component.html',
    styleUrls: ['./card-teacher.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1, })),
            state('true', style({ height: '0px', visibility: 'hidden', opacity: 0, })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`))
        ]),
        trigger('rotatedState', [
            state('false', style({ transform: 'rotate(0)' })),
            state('true', style({ transform: 'rotate(-180deg)' })),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ]
})
export class CardTeacherComponent implements OnInit, OnChanges {
    @Input() dashData: any = [];
    @Input() user: any = [];
    @Input() i18n: any = [];

    isExpanded: boolean[] = [];
    filteredContents: any = [];
    isDropdownOpen = false;
    checkedList: any[];
    allSelected = false;
    currentLanguage: any;

    constructor(
        private dashboardService: DashboardService,
        private cdr: ChangeDetectorRef
    ) {
        this.checkedList = [];
    }

    ngOnInit(): void {
        this.filteredContents = this.dashData.contents;
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.dashData && this.dashData) {
                // this.orderAlphabetic(this.dashData.disciplines);
                this.disciplinesFilter();
            }
        }
    }

    // orderAlphabetic(disciplines) {
    //     disciplines.sort((a, b) => a.name.localeCompare(b.name));
    // }

    routeToDisciplines(discipline_id: any) {
        this.dashboardService.toDisciplineOverview(discipline_id);
    }

    toggleExpand(index: string) {
        const tempState = this.isExpanded[index];
        this.isExpanded.fill(false);
        this.isExpanded[index] = !tempState;
    }

    selectAll() {
        this.checkedList = [];
        if (this.allSelected) {
            this.dashData.disciplines.forEach((item) => {
                const obj = item;
                obj.checked = true;
                this.checkedList.push(item);
            });
        } else {
            this.dashData.disciplines.forEach((item) => {
                const obj = item;
                obj.checked = false;
            });
        }
        this.disciplinesFilter();
    }

    disciplinesFilter() {
        if (this.checkedList.length) {
            const filteredContentsCopy = Object.assign({}, this.dashData.contents);

            Object.keys(filteredContentsCopy).forEach((status) => {
                filteredContentsCopy[status] = this.dashData.contents[status]
                    .filter((content) => this.checkedList
                        .some((checkedDiscipline) => checkedDiscipline.external_id === content.discipline_external_id));
            });

            this.filteredContents = filteredContentsCopy;
            this.cdr.detectChanges();
            return;
        }

        this.filteredContents = this.dashData.contents;
    }

    getSelectedValue(discipline) {
        if (discipline.checked) {
            this.checkedList.push(discipline);
        } else {
            const index = this.checkedList.indexOf(discipline);
            this.checkedList.splice(index, 1);
        }
        this.verifyAllSelectedDisciplines();
        this.disciplinesFilter();
    }

    verifyAllSelectedDisciplines() {
        if (this.checkedList.length === this.dashData.disciplines.length) {
            this.allSelected = true;
            return;
        }
        this.allSelected = false;
    }

    removeFilter(discipline) {
        const disciplineObj = discipline;
        disciplineObj.checked = !disciplineObj.checked;
        this.getSelectedValue(discipline);
    }

    formateData(dataString: string): string {
        const lang = this.currentLanguage === 'en' ? 'en-US' : 'pt-BR';

        const [dateString] = dataString.split(' ');
        // Parse a string de data para garantir que seja interpretada corretamente
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);

        return date.toLocaleDateString(lang, { month: 'long', day: 'numeric' });
    }

    formatTime(timeString: string): string {
        const time = new Date(timeString);
        const hours = time.getHours();
        const minutes = time.getMinutes();

        // Adiciona um zero à esquerda se os minutos forem menores que 10
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

        // Adiciona um zero à esquerda se a hora for menor que 10
        const formattedHour = hours < 10 ? `0${hours}` : `${hours}`;

        // Formata a hora no formato desejado: 10h30
        return `${formattedHour}h${formattedMinutes}`;
    }

    routeToLink(task: any) {
        this.dashboardService.toTheContent(
            task.discipline_external_id,
            task.external_id,
            task.section_external_id,
            task.section_title,
            task.content_type
        );
    }
}
