import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() title: string;
  @Input() description: string;
  @Input() overflow = true;

  constructor() { }

  ngOnInit(): void {
    console.log('olha o overflow', this.overflow);
  }

}
