<section class="flex flex-col gap-4" #currilumDomElement>
    <!-- Header  -->
    <header class="flex flex-col">
        <h1 class="text-customBlue-default font-bold text-22">
            {{ i18n.planner_navigable_curriculum }}
        </h1>
        <p class="text-customBlue-default text-16 font-Roboto font-normal">
            {{ i18n.planner_navigable_curriculum_description }}
        </p>
        <a
            class="bg-transparent text-customGray-default py-1 flex items-center gap-1 mt-5"
            [routerLink]="['/class-planner']"
        >
            <span class="iconify text-18" data-icon="ion:chevron-back"></span>
            <span>{{ i18n.planner_go_back_planning }}</span>
        </a>
    </header>

    <ng-container *ngIf="isLoading">
        <div class="flex justify-center items-center w-full h-vh">
            <app-bullets-loader></app-bullets-loader>
        </div>
    </ng-container>

    <section *ngIf="!isLoading">
        <div class="flex flex-col gap-3" *ngIf="filters">
            <ng-container *ngFor="let filter of filterTypes">
                <app-filter-planning
                    *ngIf="filters[filter.key].length > 0"
                    [key]="filter.key"
                    [items]="filters[filter.key]"
                    [activeItem]="selectedFilters[filter.key]"
                    [availableFilters]="availableFilters"
                    [title]="getKeyName(filter.key)"
                    (selectItemEvent)="selectItem($event)"
                ></app-filter-planning>
            </ng-container>
        </div>
        <div class="text-center mt-20" *ngIf="!planning && !isLoadingPlanning">
            <span class="text-18 text-customGray-default font-medium">
                {{ i18n.planner_empty_plannig_for_filters }}
            </span>
        </div>

        <div class="text-center mt-20" *ngIf="isLoadingPlanning">
            <app-bullets-loader></app-bullets-loader>
        </div>

        <div class="mt-10" *ngIf="planning">
            <app-my-planning [planning]="planning"></app-my-planning>
        </div>
    </section>

    <footer class="flex justify-center mt-4" *ngIf="planning">
        <button
            type="button"
            class="border border-customBlue-default rounded p-2 text-customBlue-default"
            (click)="goToStartScreen()"
        >
            {{ i18n.planner_back_top }}
        </button>
    </footer>
</section>
