<div class="card-exam" *ngIf="isOpen">
    <header>
        <div class="p-4 text-center">
            <h3
                class="sm:text-26 text-22 font-semibold text-customBlue-default font-Montserrat"
            >
                {{ data?.title }}
            </h3>
        </div>
        <div class="border-t border-customBlue-glass mb-4"></div>
    </header>
    <section class="content p-4 flex flex-col gap-7">
        <div class="text-14 sm:text-16 font-Roboto font-normal text-customGray-dark">
            {{ i18n?.disciplines_assessments_preview_description }}
        </div>
        <div
            class="bg-customBlue-glass p-2 rounded-md flex gap-2 justify-center items-center"
        >
            <span
                class="iconify text-customBlue-royal"
                data-icon="solar:calendar-broken"
                data-width="20"
                data-height="20"
            ></span>
            <span
                class="text-14 sm:text-16 font-semibold text-customBlue-royal font-Roboto"
            >
                {{ formatDateToExam(data?.exam?.application_date) }}
            </span>
        </div>
        <div
            class="font-Roboto text-15 text-customGray-default"
            [innerHTML]="data?.description"
        ></div>
        <div
            class="flex flex-col gap-7 text-14 sm:text-16 font-normal text-customGray-dark"
        >
            <div class="flex gap-2">
                <span class="font-bold">
                    {{ i18n?.disciplines_assessments_preview_duration }}:
                </span>
                <span>
                    {{ formatTime(data?.exam?.duration) }}
                </span>
            </div>
            <div class="flex gap-2">
                <span class="font-bold">
                    {{ i18n?.disciplines_assessments_preview_local }}:
                </span>
                <span>
                    {{ data?.exam?.local }}
                </span>
            </div>
            <div class="flex gap-2">
                <span class="font-bold">
                    {{ i18n?.disciplines_assessments_preview_resources }}:
                </span>
                <span>
                    {{ data?.exam?.resources }}
                </span>
            </div>
            <div class="flex flex-col">
                <span class="font-bold">
                    {{
                        i18n?.disciplines_assessments_preview_date_publish_notes
                    }}:
                </span>
                <span>
                    {{ formatDateToExam(data?.exam?.publish_grade_date) }}
                </span>
            </div>
        </div>
    </section>
    <footer>
        <div class="w-full flex justify-center items-center mb-8">
            <button
                class="text-14 sm:text-16 rounded-md border border-customBlue-default bg-customWhite-default btn-gobak text-customBlue-default"
                (click)="close()"
            >
                {{ i18n?.disciplines_emit_disabled_goback }}
            </button>
        </div>
    </footer>
</div>
