import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { LoginModule } from 'src/app/pages/login/login.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { SidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        SidebarComponent,
    ],
    exports: [
        SidebarComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        LoginModule,
        PermissionModule
    ]
})

export class SidebarModule { }
