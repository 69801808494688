import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

import { Criterion } from '../../../section.service';

@Component({
    selector: 'app-card-rubric',
    templateUrl: './card-rubric.component.html',
    styleUrls: ['./card-rubric.component.scss'],
})
export class CardRubricComponent implements OnInit, OnChanges {
    @ViewChildren('level_element') levelElementsList: any;
    @Output() sendIDLevel = new EventEmitter();
    @Input() receiveCriterion: any;
    i18n: any = {};
    currentLanguage: string | null = '';
    constructor(
        private sharedSerivce: SharedService,
    ) {}

    ngOnInit(): void {
        this.getTranslations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes ', changes);
    }

    replaceDotForComma(value: any) {
        if (this.currentLanguage !== 'en') {
            value = value.toString().replace('.', ',');
        }
        return value;
    }

    getTranslations() {
        this.i18n = { ...this.sharedSerivce.getTranslationsOf('Disciplines') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
        if (this.currentLanguage == null) {
            this.currentLanguage = 'pt-BR';
        }
    }

    selectLevel(levelID?: number, criterion?: Criterion): void {
        criterion?.levels.forEach((level: any) => {
            if (level?.id == levelID) {
                level.selected = !level.selected;
            } else {
                level.selected = false;
            }
        });

        const emitter = {
            level_id: levelID,
            criterion_id: criterion?.id
        };
        this.sendIDLevel.emit(emitter);
    }
}
