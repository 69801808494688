import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

export interface EvaluativeResourceValue {
    period_id: number;
    category_id: number;
    weight: number;
    composition_type: string;
}

@Injectable({
    providedIn: 'root'
})
export class SubstituteActivityService {
    evaluativeResourceDisabled$ = new BehaviorSubject<boolean>(false);
    evaluativeResourceValue$ = new BehaviorSubject<EvaluativeResourceValue>({} as EvaluativeResourceValue);

    constructor(private http: HttpClient) { }

    getSubstituteActivityViewGrade(disciplineExternalID: string, contentExternalID: string): Observable<EvaluativeResourceValue> {
        return this.http.get<EvaluativeResourceValue>(`${API_Routes.URL}/disciplines/${disciplineExternalID}/content/${contentExternalID}/view-grade`);
    }
}
