<!-- MODAL HISTÓRICO DE EDIÇÃO -->
<app-generic-modal [minWidth]="'30%'" [width]="'540px'" [isVisible]="isOpen">
    <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
        <!--header-->
        <div
            class="flex items-center justify-center p-5 border-b border-solid border-customBlue-light rounded-t"
        >
            <div class="text-center">
                <h3
                    class="font-bold text-26 text-customBlue-default font-Roboto"
                >
                    {{ i18n.disciplines_content_exam_bank }}
                </h3>
            </div>
        </div>
        <!--body-->
        <ng-container>
            <div
                *ngIf="isLoading"
                class="flex justify-center items-center content-body"
            >
                <app-bullets-loader></app-bullets-loader>
            </div>

            <section
                *ngIf="!isLoading"
                class="relative p-2 px-5 mb-4 content-body"
            >
                <div
                    *ngIf="!schedules.length"
                    class="relative p-6 scroller-y scroller-x flex items-center justify-center"
                >
                    <div
                        class="text-center text-18 text-customGray-default font-Roboto font-medium line-height-19"
                    >
                        {{ i18n.disciplines_assessments_empty }}
                    </div>
                </div>
                <div *ngIf="schedules.length">
                    <h3
                        class="text-16 font-medium text-customBlue-default mb-5"
                    >
                        {{ i18n.disciplines_assessments_schedule_description }}
                    </h3>
                    <!-- Schedules -->
                    <div class="flex flex-col gap-2">
                        <ng-container *ngFor="let schedule of schedules">
                            <div
                                class="flex items-center justify-between p-4 border rounded-md border-customGray-mediumGray"
                            >
                                <div>
                                    <div
                                        class="text-16 text-customBlue-default font-Roboto line-height-16"
                                    >
                                        {{ schedule.agendamentoNome }}
                                    </div>
                                    <div
                                        class="text-12 text-customGray-default font-Roboto"
                                    >
                                        {{ schedule.realizacaoNome }}
                                    </div>
                                </div>
                                <app-check-button
                                    [active]="schedule.checked"
                                    [iconSize]="22"
                                    (change)="selectSchedule(schedule)"
                                ></app-check-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </section>
            <!--footer-->
            <footer>
                <div
                    class="flex items-center justify-center p-6 rounded-b gap-2"
                    *ngIf="schedules.length"
                >
                    <button
                        class="bg-customBlue-default text-customWhite-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        style="width: 152px"
                        (click)="submit()"
                    >
                        {{ i18n.disciplines_save }}
                    </button>

                    <button
                        class="bg-customWhite-default text-customBlue-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        style="width: 152px"
                        (click)="close()"
                    >
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
                <div
                    class="flex items-center justify-center p-6 rounded-b gap-2"
                    *ngIf="!schedules.length"
                >
                    <button
                        class="bg-customWhite-default text-customBlue-default border border-customBlue-default rounded-md font-semibold px-6 py-2 text-16 outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        style="width: 152px"
                        (click)="close()"
                    >
                        {{ i18n.disciplines_emit_disabled_goback }}
                    </button>
                </div>
            </footer>
        </ng-container>
    </div>
</app-generic-modal>
