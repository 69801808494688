import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize } from 'rxjs';
import { filter } from 'rxjs/operators';
import { QuestionBankTrieducService } from 'src/app/pages/disciplines/components/question-bank/components/question-bank-trieduc/question-bank-trieduc.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-pick-question-from-trieduc',
    templateUrl: './pick-question-from-trieduc.component.html',
    styleUrls: ['./pick-question-from-trieduc.component.scss']
})
export class PickQuestionFromTrieducComponent implements OnInit, AfterViewInit {
    @Input() selectedQuestions: any[] = [];

    @Output() addQuestions = new EventEmitter();
    @Output() closePicker = new EventEmitter();

    @ViewChild('pipeDropdown') pipeDropdown: ElementRef;
    @ViewChild('tagSugestion') tagSugestion: ElementRef;
    @ViewChild('searchInputElement', { static: false }) searchInputElement: ElementRef;

    pipeDropdownExpanded: boolean;
    pipeDropdownKey: symbol;

    searchTextInput = '';

    questions: any[] = [];
    disciplineExternalId: string;
    filtersOn: boolean;
    loadFilters: boolean;
    noQuestionsFound: boolean;
    i18n: any;

    params = {
        pageNumber: 1,
        pageSize: 10,
        tags: '',
        tipoQuestao: '',
        etapaEscolar: '',
        disciplina: '',
        conteudo: '',
        topico: '',
        subtopico: '',
        especificacao: '',
        detalhe: '',
        bnccEtapa: '',
        bnccAreaConhecimento: '',
        bnccDisciplina: '',
        bnccAnoEscolar: '',
        bnccHabilidade: '',
        saebArea: '',
        saebAno: '',
        saebEixoConhecimento: '',
        saebEixoCognitivo: '',
        saebHabilidade: ''
    };

    paramsDefault = {
        pageNumber: 1,
        pageSize: 25,
        tags: '',
        tipoQuestao: '',
        etapaEscolar: '',
        disciplina: '',
        conteudo: '',
        topico: '',
        subtopico: '',
        especificacao: '',
        detalhe: '',
        bnccEtapa: '',
        bnccAreaConhecimento: '',
        bnccDisciplina: '',
        bnccAnoEscolar: '',
        bnccHabilidade: '',
        saebArea: '',
        saebAno: '',
        saebEixoConhecimento: '',
        saebEixoCognitivo: '',
        saebHabilidade: ''
    };

    isLoading = true;

    questionsTrieduc: any = [];

    // Variáveis para armazenar os dados dos filtros
    questionTypes: any[] = [];
    typeQuestionSelected: string | null = null;
    stages: any[] = [];
    stageSelected: string | null = null;
    disciplines: any[] = [];
    disciplineSelected: string | null = null;
    contents: any[] = [];
    contentSelected: string | null = null;
    topics: any[] = [];
    topicSelected: string | null = null;
    subtopics: any[] = [];
    subtopicSelected: string | null = null;
    specifications: any[] = [];
    specificationSelected: string | null = null;
    details: any[] = [];
    detailSelected: string | null = null;
    bases: any[] = [
        { id: 'COMMON', name: 'Comun' },
        { id: 'BNCC', name: 'BNCC' },
        { id: 'SAEB', name: 'SAEB' }
    ];
    baseSelected: string | null = 'COMMON';
    bnccStages: any[] = [];
    bnccStageSelected: string | null = null;
    bnccKnowledgeAreas: any[] = [];
    bnccKnowledgeAreaSelected: string | null = null;
    bnccDisciplines: any[] = [];
    bnccDisciplineSelected: string | null = null;
    bnccSchoolYears: any[] = [];
    bnccSchoolYearSelected: string | null = null;
    bnccSkills: any[] = [];
    bnccSkillSelected: string | null = null;
    knowledgeAreas: any[] = [];
    knowledgeAreaSelected: string | null = null;
    schoolYears: any[] = [];
    schoolYearSelected: string | null = null;
    knowledgeAxis: any[] = [];
    knowledgeAxisSelected: string | null = null;
    cognitiveAxis: any[] = [];
    cognitiveAxisSelected: string | null = null;
    cognitiveSkills: any[] = [];
    cognitiveSkillSelected: string | null = null;

    searchControl: FormControl = new FormControl();
    previousSearchText = '';
    loadingScroll: boolean;

    countselectedQuestions = 0;

    constructor(
        private sharedService: SharedService,
        private questionBankService: QuestionBankTrieducService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.setTranslations();
        this.selectedQuestions = JSON.parse(JSON.stringify(this.selectedQuestions));
        console.log('selectedQuestions', this.selectedQuestions);
        this.getQuestions();
    }

    ngAfterViewInit(): void {
        this.getDisciplineExternalId();
        this.cd.detectChanges();
        this.getQuestionTypes();
        this.getStages();
        this.setupSearch();

        this.cd.detectChanges();
    }

    setupSearch() {
        this.searchControl.valueChanges.pipe(
            debounceTime(300),
            filter((value: string) => value.length >= 3 && value !== this.previousSearchText)
        ).subscribe((value) => {
            this.previousSearchText = value;
            this.searchQuestions(value);
        });
    }

    searchQuestions(value: string): void {
        this.params = { ...this.paramsDefault, tags: value };
        this.getQuestions();
    }

    getQuestionTypes() {
        this.questionBankService.getQuestionTypes().subscribe({
            next: (res: any) => {
                this.questionTypes = res;
            },
            error: (res) => {
                console.error(res);
            },
        });
    }

    getStages() {
        this.questionBankService.getStages().subscribe({
            next: (res: any) => {
                this.stages = res;
            },
            error: (res) => {
                console.error(res);
            },
        });
    }

    getDisciplineExternalId() {
        this.disciplineExternalId = sessionStorage.getItem('discipline_external_id') || '';
    }

    setTranslations() {
        const translationModuleName = 'QuestionBank';
        this.sharedService.setI18n(this, translationModuleName);
    }

    getQuestions(load = true, filtered = false) {
        this.pipeDropdownExpanded = false;
        this.noQuestionsFound = false;

        if (filtered) {
            this.filtersOn = true;
        } else {
            this.filtersOn = false;
        }

        if (load) {
            this.params.pageNumber = 1;
        } else {
            this.loadingScroll = true;
        }

        this.isLoading = load;
        this.questionBankService.getQuestions(this.params).subscribe({
            next: (res: any) => {
                this.isLoading = false;

                console.log('filtered', filtered);

                if (!load) {
                    this.loadingScroll = false;
                    this.questionsTrieduc = [...this.questionsTrieduc, ...res];
                    return;
                }
                this.questionsTrieduc = res;
                this.pipeDropdownExpanded = false;

                if (filtered) {
                    this.filtersOn = true;
                } else {
                    this.filtersOn = false;
                }

                if (res.length === 0) {
                    this.noQuestionsFound = true;
                }
            },
            error: (res) => {
                this.isLoading = false;
                this.loadingScroll = true;
                this.noQuestionsFound = true;
                this.closePicker.emit();
                this.sharedService.openInfoModal('error', 'Erro ao carregar questões');
            },
        });
    }

    getDisciplines(stageId: string | null) {
        this.loadFilters = true;
        if (!stageId) {
            this.disciplines = [];
            return;
        }

        this.questionBankService.getDisciplines(stageId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.disciplines = res;
                },
                error: (res) => {
                    console.error(res);
                }
            });
    }

    getContents(disciplineId: string | null) {
        this.loadFilters = true;
        if (!disciplineId) {
            this.contents = [];
            return;
        }

        this.questionBankService.getContents(disciplineId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.contents = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getTopics(contentId: string | null) {
        this.loadFilters = true;
        if (!contentId) {
            this.topics = [];
            return;
        }

        this.questionBankService.getTopics(contentId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.topics = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getSubtopics(topicId: string | null) {
        this.loadFilters = true;
        if (!topicId) {
            this.subtopics = [];
            return;
        }

        this.questionBankService.getSubtopics(topicId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.subtopics = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getSpecifications(subtopicId: string | null) {
        this.loadFilters = true;
        if (!subtopicId) {
            this.specifications = [];
            return;
        }

        this.questionBankService.getSpecifications(subtopicId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.specifications = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getDetails(especificationId: string | null) {
        this.loadFilters = true;
        if (!especificationId) {
            this.details = [];
            return;
        }

        this.questionBankService.getDetails(especificationId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.details = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getStagesBNCC() {
        this.loadFilters = true;
        this.questionBankService.getBNCCSteps()
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.bnccStages = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getKnowledgeAreasBNCC(stepId: string | null) {
        this.loadFilters = true;
        if (!stepId) {
            this.bnccKnowledgeAreas = [];
            return;
        }

        this.questionBankService.getBNCCKnowledgeAreas(stepId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.bnccKnowledgeAreas = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getDisciplinesBNCC(knowledgeAreaId: string | null) {
        this.loadFilters = true;
        if (!knowledgeAreaId) {
            this.bnccDisciplines = [];
            return;
        }

        this.questionBankService.getBNCCDisciplines(knowledgeAreaId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.bnccDisciplines = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getSchoolYearsBNCC(disciplineId: string | null) {
        this.loadFilters = true;
        if (!disciplineId) {
            this.bnccSchoolYears = [];
            return;
        }

        this.questionBankService.getBNCCSchoolYears(disciplineId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    if (res.length > 0) {
                        this.bnccSchoolYears = res.sort((a, b) => a.nome.localeCompare(b.nome));
                    }
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getBNCCSkills(schoolYearId: string | null) {
        this.loadFilters = true;
        if (!schoolYearId) {
            this.bnccSkills = [];
            return;
        }

        this.questionBankService.getBNCCSkills(schoolYearId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.bnccSkills = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getKnowledgeAreas(base: string) {
        this.loadFilters = true;
        this.questionBankService.getKnowledgeAreas(base)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.knowledgeAreas = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getSchoolYears(base: string, knowledgeAreaId: string | null) {
        this.loadFilters = true;
        if (!knowledgeAreaId) {
            this.schoolYears = [];
            return;
        }
        this.questionBankService.getSchoolYears(base, knowledgeAreaId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.schoolYears = res.sort((a, b) => a.nome.localeCompare(b.nome));
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getKnowledgeAxis(base: string, schoolYearId: string | null) {
        this.loadFilters = true;
        if (!schoolYearId) {
            this.knowledgeAxis = [];
            return;
        }
        this.questionBankService.getKnowledgeAxis(base, schoolYearId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.knowledgeAxis = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getCognitiveAxis(base: string, knowledgeAxisId: string | null) {
        this.loadFilters = true;
        if (!knowledgeAxisId) {
            this.cognitiveAxis = [];
            return;
        }
        this.questionBankService.getCognitiveAxis(base, knowledgeAxisId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.cognitiveAxis = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    getCognitiveSkills(base: string, cognitiveAxisId: string | null) {
        this.loadFilters = true;
        if (!cognitiveAxisId) {
            this.cognitiveSkills = [];
            return;
        }
        this.questionBankService.getCognitiveSkills(base, cognitiveAxisId)
            .pipe(
                finalize(() => {
                    this.loadFilters = false;
                })
            )
            .subscribe({
                next: (res: any) => {
                    this.cognitiveSkills = res;
                },
                error: (res) => {
                    console.error(res);
                },
            });
    }

    toggleDropdown(type: string) {
        const dropdownState = this[`${type}DropdownExpanded`];

        this[`${type}DropdownExpanded`] = !dropdownState;
        this.cd.detectChanges();
    }

    selectQuestion(question, index) {
        const isSelected = this.selectedQuestions.findIndex(
            (q) => q.external_id === question.external_id
        );

        if (isSelected >= 0) {
            const questionIndex = isSelected;
            this.selectedQuestions.splice(questionIndex, 1);
        } else {
            this.selectedQuestions.push(question);
        }

        this.questions[index].selected = !this.questions[index].selected;
    }

    setQuestions(questions: any[]) {
        this.selectedQuestions = questions;
        this.countselectedQuestions = this.selectedQuestions.length;
    }

    onScroll() {
        this.params.pageNumber += 1;
        this.getQuestions(false);
    }

    toggleTypeQuestion(typeId: string | null) {
        if (this.typeQuestionSelected === typeId) {
            this.typeQuestionSelected = null;
        } else {
            this.typeQuestionSelected = typeId;
        }
        this.params.tipoQuestao = this.typeQuestionSelected || '';
        this.cd.detectChanges();
    }

    toggleStage(typeId: string | null) {
        if (this.stageSelected === typeId) {
            this.stageSelected = null;
        } else {
            this.stageSelected = typeId;
        }
        this.params.etapaEscolar = this.stageSelected || '';

        this.getDisciplines(this.stageSelected);
    }

    toggleDiscipline(typeId: string | null) {
        if (this.disciplineSelected === typeId) {
            this.disciplineSelected = null;
        } else {
            this.disciplineSelected = typeId;
        }
        this.params.disciplina = this.disciplineSelected || '';

        this.getContents(this.disciplineSelected);
    }

    toggleContent(typeId: string | null) {
        if (this.contentSelected === typeId) {
            this.contentSelected = null;
        } else {
            this.contentSelected = typeId;
        }
        this.params.conteudo = this.contentSelected || '';
        this.getTopics(this.contentSelected);
    }

    toggleTopic(typeId: string | null) {
        if (this.topicSelected === typeId) {
            this.topicSelected = null;
        } else {
            this.topicSelected = typeId;
        }
        this.params.topico = this.topicSelected || '';
        this.getSubtopics(this.topicSelected);
    }

    toggleSubtopic(typeId: string | null) {
        if (this.subtopicSelected === typeId) {
            this.subtopicSelected = null;
        } else {
            this.subtopicSelected = typeId;
        }
        this.params.subtopico = this.subtopicSelected || '';
        this.getSpecifications(this.subtopicSelected);
    }

    toggleSpecification(typeId: string | null) {
        if (this.specificationSelected === typeId) {
            this.specificationSelected = null;
        } else {
            this.specificationSelected = typeId;
        }
        this.params.especificacao = this.specificationSelected || '';
        this.getDetails(this.specificationSelected);
    }

    toggleDetail(typeId: string | null) {
        if (this.detailSelected === typeId) {
            this.detailSelected = null;
        } else {
            this.detailSelected = typeId;
        }
        this.params.detalhe = this.detailSelected || '';
    }

    toggleBase(typeId: string | null) {
        if (this.baseSelected === typeId) {
            this.baseSelected = null;
        } else {
            this.baseSelected = typeId;
            this.params = { ...this.paramsDefault };
        }

        if (this.baseSelected === 'BNCC') {
            this.getStagesBNCC();
        }

        if (this.baseSelected === 'SAEB') {
            this.getKnowledgeAreas('saeb');
        }
    }

    toggleBNCCStage(typeId: string | null) {
        if (this.bnccStageSelected === typeId) {
            this.bnccStageSelected = null;
        } else {
            this.bnccStageSelected = typeId;
        }
        this.params.bnccEtapa = this.bnccStageSelected || '';

        this.getKnowledgeAreasBNCC(this.bnccStageSelected);
    }

    toggleBNCCKnowledgeArea(typeId: string | null) {
        if (this.bnccKnowledgeAreaSelected === typeId) {
            this.bnccKnowledgeAreaSelected = null;
        } else {
            this.bnccKnowledgeAreaSelected = typeId;
        }
        this.params.bnccAreaConhecimento = this.bnccKnowledgeAreaSelected || '';

        this.getDisciplinesBNCC(this.bnccKnowledgeAreaSelected);
    }

    toggleBNCCDiscipline(typeId: string | null) {
        if (this.bnccDisciplineSelected === typeId) {
            this.bnccDisciplineSelected = null;
        } else {
            this.bnccDisciplineSelected = typeId;
        }
        this.params.bnccDisciplina = this.bnccDisciplineSelected || '';

        this.getSchoolYearsBNCC(this.bnccDisciplineSelected);
    }

    toggleBNCCSchoolYear(typeId: string | null) {
        if (this.bnccSchoolYearSelected === typeId) {
            this.bnccSchoolYearSelected = null;
        } else {
            this.bnccSchoolYearSelected = typeId;
        }
        this.params.bnccAnoEscolar = this.bnccSchoolYearSelected || '';

        this.getBNCCSkills(this.bnccSchoolYearSelected);
    }

    toggleBNCCSkill(typeId: string | null) {
        if (this.bnccSkillSelected === typeId) {
            this.bnccSkillSelected = null;
        } else {
            this.bnccSkillSelected = typeId;
        }
        this.params.bnccHabilidade = this.bnccSkillSelected || '';
    }

    toggleKnowledgeArea(typeId: string | null) {
        if (this.knowledgeAreaSelected === typeId) {
            this.knowledgeAreaSelected = null;
        } else {
            this.knowledgeAreaSelected = typeId;
        }
        this.params.saebArea = this.knowledgeAreaSelected || '';

        this.getSchoolYears('saeb', this.knowledgeAreaSelected);
    }

    toggleSchoolYear(typeId: string | null) {
        if (this.schoolYearSelected === typeId) {
            this.schoolYearSelected = null;
        } else {
            this.schoolYearSelected = typeId;
        }
        this.params.saebAno = this.schoolYearSelected || '';

        this.getKnowledgeAxis('saeb', this.schoolYearSelected);
    }

    toggleKnowledgeAxis(typeId: string | null) {
        if (this.knowledgeAxisSelected === typeId) {
            this.knowledgeAxisSelected = null;
        } else {
            this.knowledgeAxisSelected = typeId;
        }
        this.params.saebEixoConhecimento = this.knowledgeAxisSelected || '';

        this.getCognitiveAxis('saeb', this.knowledgeAxisSelected);
    }

    toggleCognitiveAxis(typeId: string | null) {
        if (this.cognitiveAxisSelected === typeId) {
            this.cognitiveAxisSelected = null;
        } else {
            this.cognitiveAxisSelected = typeId;
        }
        this.params.saebEixoCognitivo = this.cognitiveAxisSelected || '';

        this.getCognitiveSkills('saeb', this.cognitiveAxisSelected);
    }

    toggleCognitiveSkill(typeId: string | null) {
        if (this.cognitiveSkillSelected === typeId) {
            this.cognitiveSkillSelected = null;
        } else {
            this.cognitiveSkillSelected = typeId;
        }
        this.params.saebHabilidade = this.cognitiveSkillSelected || '';
    }

    createQuestions() {
        const data = {
            questions: this.selectedQuestions,
            discipline_external_id: this.disciplineExternalId,
        };

        this.questionBankService
            .createQuestions(data)
            .subscribe({
                next: (res: any) => {
                    this.addQuestions.emit(res.data);
                    this.closePicker.emit();
                },
                error: (res) => {
                    console.error('Erro ao carregar questões -------->', res);
                },
            });
    }
}
