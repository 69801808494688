import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { CacheUtil } from 'src/app/utils/cache/cache-util';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class QuestionBankTrieducService {
    constructor(private http: HttpClient) { }

    private cacheTTL = 24 * 60 * 60 * 1000; // 1 day in milliseconds

    private getWithCache(key: string, url: string): Observable<any> {
        const cachedData = CacheUtil.getCache(key);
        if (cachedData) {
            return of(cachedData);
        }

        return this.http.get(url).pipe(
            map((response) => {
                CacheUtil.setCache(key, response, this.cacheTTL);
                return response;
            }),
            catchError((error) => {
                console.error(`Failed to fetch ${url}`, error);
                throw error;
            })
        );
    }

    // TRIEDUC

    getQuestions(params: any): Observable<any> {
        let queryParams = new HttpParams();

        Object.keys(params).forEach((key) => {
            if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
                queryParams = queryParams.set(key, params[key]);
            }
        });

        const getUrl = `${API_Routes.URL}/trieduc/list-questions`;
        return this.http.get(getUrl, { params: queryParams });
    }

    getQuestionTypes(): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-question-types`;
        return this.getWithCache('question_types', getUrl);
    }

    getStages(): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-stages`;
        return this.getWithCache('stages', getUrl);
    }

    getDisciplines(stageId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-disciplines/${stageId}`;
        return this.getWithCache(`disciplines_${stageId}`, getUrl);
    }

    getContents(disciplineId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-contents/${disciplineId}`;
        return this.getWithCache(`contents_${disciplineId}`, getUrl);
    }

    getTopics(contentId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-topics/${contentId}`;
        return this.getWithCache(`topics_${contentId}`, getUrl);
    }

    getSubtopics(topicId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-subtopics/${topicId}`;
        return this.getWithCache(`subtopics_${topicId}`, getUrl);
    }

    getSpecifications(subtopicId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-specifications/${subtopicId}`;
        return this.getWithCache(`specifications_${subtopicId}`, getUrl);
    }

    getDetails(especificationId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-details/${especificationId}`;
        return this.getWithCache(`details_${especificationId}`, getUrl);
    }

    getKnowledgeAreas(base: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-knowledge-areas/${base}`;
        return this.getWithCache(`knowledge_areas_${base}`, getUrl);
    }

    getSchoolYears(base: string, knowledgeAreaId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/school-years/${base}/${knowledgeAreaId}`;
        return this.getWithCache(`school_years_${base}_${knowledgeAreaId}`, getUrl);
    }

    getKnowledgeAxis(base: string, schoolYearId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-knowledge-axis/${base}/${schoolYearId}`;
        return this.getWithCache(`knowledge_axis_${base}_${schoolYearId}`, getUrl);
    }

    getCognitiveAxis(base: string, knowledgeAxisId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-cognitive-axis/${base}/${knowledgeAxisId}`;
        return this.getWithCache(`cognitive_axis_${base}_${knowledgeAxisId}`, getUrl);
    }

    getCognitiveSkills(base: string, cognitiveAxisId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-cognitive-skills/${base}/${cognitiveAxisId}`;
        return this.getWithCache(`cognitive_skills_${base}_${cognitiveAxisId}`, getUrl);
    }

    getBNCCSteps(): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-bncc-steps`;
        return this.getWithCache('bncc_steps', getUrl);
    }

    getBNCCKnowledgeAreas(stepId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-bncc-knowledge-areas/${stepId}`;
        return this.getWithCache(`bncc_knowledge_areas_${stepId}`, getUrl);
    }

    getBNCCDisciplines(knowledgeAreaId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-bncc-disciplines/${knowledgeAreaId}`;
        return this.getWithCache(`bncc_disciplines_${knowledgeAreaId}`, getUrl);
    }

    getBNCCSchoolYears(disciplineId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-bncc-school-years/${disciplineId}`;
        return this.getWithCache(`bncc_school_years_${disciplineId}`, getUrl);
    }

    getBNCCSkills(schoolYearId: string): Observable<any> {
        const getUrl = `${API_Routes.URL}/trieduc/list-bncc-skills/${schoolYearId}`;
        return this.getWithCache(`bncc_skills_${schoolYearId}`, getUrl);
    }

    createQuestions(data: any): Observable<any> {
        const postUrl = `${API_Routes.URL}/trieduc/create-questions`;
        return this.http.post(postUrl, data);
    }
}
