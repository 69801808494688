import { Injectable } from '@angular/core';
import {
    Subject, Subscription
} from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class BeforeUnloadService {
    private unloadNotification = new Subject<boolean>();

    i18n: any;
    subscriptions: Subscription[] = [];
    hasUnsavedChanges = false;

    constructor(
        private sharedService: SharedService,
    ) {
        this.getMessageTranslated();
    }

    private getMessageTranslated() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines')
        };
    }

    getUnloadNotification() {
        return this.unloadNotification.asObservable();
    }

    canDeactivate(): boolean {
        // if doesnt have any changes return true to not ask if user can leave page
        if (!this.hasUnsavedChanges) {
            return true;
        }
        const confirmation = window.confirm(this.i18n.disciplines_confirmation_leave);
        this.unloadNotification.next(confirmation);
        return confirmation;
    }

    init() {
        window.addEventListener('beforeunload', this.beforeUnloadHandler.bind(this));
    }

    destroy() {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
        this.hasUnsavedChanges = false;
        if (this.subscriptions.length) {
            this.subscriptions.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    private beforeUnloadHandler(event: BeforeUnloadEvent) {
        const message = this.i18n.disciplines_confirmation_leave;
        event.preventDefault();
        const $event = event;
        // Even deprecated, it still triggers the confirmation modal
        $event.returnValue = message;
        // this.canDeactivate();
        return event;
    }
}
