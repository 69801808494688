import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-empty-data',
    templateUrl: './empty-data.component.html',
    styleUrls: ['./empty-data.component.scss'],
})
export class EmptyDataComponent implements OnInit {
    @Input() text = '';
    i18n: any = {};

    constructor(private sharedService: SharedService) {
        this.text = '';
    }

    ngOnInit(): void {
        if (this.text && !this.text.length) {
            this.getTraductions();
        }
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('Disciplines');
        this.text = this.i18n.disciplines_warning_empty;
    }
}
