import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SingleChoiceDropdownComponent } from 'src/app/components/dropdowns/single-choice-dropdown/single-choice-dropdown.component';
import { SharedService } from 'src/app/shared/shared.service';

import { IQuestionData } from '../../models/IQuestionData';
import { QuestionBankService } from '../../question-bank.service';

@Component({
    selector: 'app-edit-question',
    templateUrl: './edit-question.component.html',
    styleUrls: ['./edit-question.component.scss'],
})
export class EditQuestionComponent implements OnInit, OnDestroy {
    @ViewChild('titleInput') $titleInput: ElementRef;
    @ViewChild('alternatives') $alternatives: ElementRef;
    @ViewChild('tagSugestion') tagSugestion: ElementRef;
    @ViewChild('typeDropdown') typeDropdown: SingleChoiceDropdownComponent;
    @ViewChild('questionCreation') questionCreation: any;

    @Input() isVisible = false;
    @Input() data: any = {
        allTags: [''],
    };

    @Output() close = new EventEmitter();
    @Output() edit = new EventEmitter();

    form: FormGroup;

    down = false;
    hasFeedbackSupport = false;
    activeQuestionType = '';
    activeQuestionTypeTranslation: any = '';
    disciplineExternalId: string;
    tagAlreadySelected = false;
    fadeoutCanvas = false;
    editedSuccessfully = false;
    loadingEdition = false;
    i18n: any;

    // Dropdowns controls
    tagsSugestionExpanded = false;
    tagsSugestionKey: symbol;

    sugestionTags: any[] = [];
    selectedTags: any[] = [];

    questionData: IQuestionData = {
        ready: false,
        payload: {},
    };

    tags = [
        { id: 'hey', translation: 'Hey', active: false },
        { id: 'you', translation: 'You', active: false },
        {
            id: 'can_i_come_around',
            translation: 'Can i come around',
            active: false,
        },
    ];

    types = [
        { id: 'SCALE', translation: 'Escala', active: false },
        { id: 'LIST', translation: 'Lista', active: false },
        { id: 'TEXT', translation: 'Resposta curta', active: false },
        { id: 'PARAGRAPH_TEXT', translation: 'Parágrafo', active: false },
        { id: 'MULTIPLE_CHOICE', translation: 'Multipla escolha', active: false },
        { id: 'CHECKBOX', translation: 'Caixa de seleção', active: false },
        { id: 'GRID', translation: 'Grade', active: false },
        { id: 'CHECKBOX_GRID', translation: 'Grade de seleção', active: false },
    ];

    editorTitle: any = {
        mobile: true,
        menubar: 'insert',
        resize: false,
        autoresize_bottom_margin: 0,
        paste_data_images: true,
        setup: (editor) => {
            editor.on('keydown', (e) => {
                this.forceReadyUpdateStatus();
            });

            editor.on('change', (e) => {
                this.forceReadyUpdateStatus();
            });
        },
        content_style: `
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color: #cdcdcd;
            cursor: text;
        }
        `,

        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'emoticons autoresize',
        ],
        toolbar:
      'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | lineheight bullist numlist | copy | removeformat |  fontselect | fontsizeselect | formatselect',
    };

    editorDescription: any = {
        mobile: true,
        resize: false,
        menubar: 'insert',
        autoresize_bottom_margin: 0,
        paste_data_images: true,
        setup: (editor) => {
            editor.on('keydown', (_) => {
                this.forceReadyUpdateStatus();
            });

            editor.on('change', (_) => {
                this.forceReadyUpdateStatus();
            });
        },
        content_style: `
            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                color: #cdcdcd;
                cursor: text;
            }
        `,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste help wordcount',
            'emoticons autoresize',
        ],
        toolbar:
      'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | lineheight bullist numlist | copy | code | removeformat |  fontselect | fontsizeselect | formatselect',
    };

    editorFeedback: any = {
        mobile: true,
        menubar: 'insert',
        resize: false,
        autoresize_bottom_margin: 0,
        paste_data_images: true,
        placeholder: '(opcional)',
        content_style: `
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color: #cdcdcd;
            cursor: text;
        }
        `,
        setup: (editor) => {
            editor.on('keydown', (e) => {
                this.forceReadyUpdateStatus();
            });

            editor.on('change', (e) => {
                this.forceReadyUpdateStatus();
            });
        },
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'emoticons autoresize',
        ],
        toolbar:
      'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | lineheight bullist numlist | copy | removeformat |  fontselect | fontsizeselect | formatselect',
    };

    constructor(
        private questionBank: QuestionBankService,
        private shared: SharedService,
        private element: ElementRef,
        private fb: FormBuilder
    ) {
        this.createForm();
    }

    ngOnInit(): void {
        this.getDisciplineExternalId();
        this.initDOOM();

        this.setTranslations();
    }

    createForm(): void {
        this.form = this.fb.group({
            title: [''],
            description: [''],
            positiveFeedback: [''],
            negativeFeedback: [''],
            searchTagsInput: ['']
        });
    }

    setTranslations() {
        const translationModuleName = 'QuestionBank';
        this.shared.setI18n(this, translationModuleName);

        this.types = [
            {
                id: 'SCALE',
                translation: this.i18n.question_bank_scale,
                active: false,
            },
            { id: 'LIST', translation: this.i18n.question_bank_list, active: false },
            {
                id: 'TEXT',
                translation: this.i18n.question_bank_short_answer,
                active: false,
            },
            {
                id: 'PARAGRAPH_TEXT',
                translation: this.i18n.question_bank_paragraph,
                active: false,
            },
            {
                id: 'MULTIPLE_CHOICE',
                translation: this.i18n.question_bank_multiple_choice,
                active: false,
            },
            {
                id: 'CHECKBOX',
                translation: this.i18n.question_bank_checkbox,
                active: false,
            },
            { id: 'GRID', translation: this.i18n.question_bank_grid, active: false },
            {
                id: 'CHECKBOX_GRID',
                translation: this.i18n.question_bank_checkbox_grid,
                active: false,
            },
        ];
    }

    initEdition() {
    // EVERY CREATION COMPONENT SHOULD HAVE A 'sendData ()' FUNCTION
    // TITLE
        this.form.controls.title.setValue(this.data.title);

        // DESCRIPTION
        this.form.controls.description.setValue(this.data.text);

        // SELECTED TYPE
        const questionType = [{ id: this.data.type }];
        const noAnimation = true;
        this.selectType(questionType, noAnimation);

        const editionType = this.types.find(
            (type) => type.id === questionType[0].id
        );
        this.activeQuestionTypeTranslation = editionType?.translation;

        // SELECTED TAGS
        this.selectedTags = this.data.tags;

        // FEEDBACK
        if (Array.isArray(this.data.options) && this.data.options.length) {
            const fallback = '';
            this.form.controls.positiveFeedback.setValue(this.data?.options[0]?.correct_feedback?.text || fallback);
            this.form.controls.negativeFeedback.setValue(this.data?.options[0]?.wrong_feedback?.text || fallback);
        }

        // READY STATE RESET
        if (this.data.type.match(/SCALE|GRID/)) {
            setTimeout(() => (this.questionData.ready = false), 0);
        } else if (this.data.type.match(/TEXT|PARAGRAPH_TEXT/)) {
            setTimeout(() => (this.questionData.ready = true), 0);
        }

        setTimeout(() => this.forceReadyUpdateStatus(), 1000);
    }

    getDisciplineExternalId() {
        this.disciplineExternalId = sessionStorage.getItem('discipline_external_id') || '';
    }

    initDOOM() {
        this.ngOnDestroy();

        setTimeout(() => {
            const _this = this;

            const tagSugestionClassName = '.search-tags';
            this.tagsSugestionKey = this.shared.toCloseWhenClickOutside(
                tagSugestionClassName,
                this.tagSugestion,
                { _this, propertyToBeToggled: 'tagsSugestionExpanded' }
            );
        }, 0);
    }

    /*
   * When we "close" the modal,
   * the component intenionally it's not destroyed,
   * so we need to this manually
   * that's what this fn called 'destroy' do
   */
    destroy() {
        this.close.emit();
        this.reload();
    }

    reload() {
        const animationTime = 350;

        setTimeout(() => {
            // The component is not destroyed, it just becomes invisible.
            // So we are going to reset all those properties manually
            this.down = true;
            this.tags.forEach((type) => (type.active = false));
            this.form.controls.title.setValue('');
            this.form.controls.description.setValue('');
            this.activeQuestionType = '';
            this.form.controls.searchTagsInput.setValue('');
            this.sugestionTags = [];
            this.selectedTags = [];
            this.questionData.ready = false;
            this.questionData.payload = {};
            this.fadeoutCanvas = false;
            this.editedSuccessfully = false;
            this.loadingEdition = false;

            setTimeout(() => (this.down = false), 150);
        }, animationTime);
    }

    selectType(data: Array<{ id: string }>, noAnimation?: boolean) {
        if (data.hasOwnProperty('isTrusted')) return;

        if (!noAnimation) {
            this.$alternatives?.nativeElement.animate(
                [
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                    },
                ],
                {
                    duration: 600,
                    easing: 'ease',
                }
            );
        }

        this.activeQuestionType = data[0].id;

        this.hasFeedbackSupport = !data[0].id.match(/SCALE|TEXT|GRID/);
    }

    retriveQuestionData(data) {
        if (!data.hasOwnProperty('isTrusted')) this.questionData = data; // checks if it is the data I need or a browser event
    }

    getTitle($event) {
        $event.stopPropagation();
        this.form.controls.title.setValue($event.target.innerHTML);
    }

    editQuestion() {
        if (!this.questionData.ready || !this.form.controls.title) return;

        this.loadingEdition = true;

        const payload = {
            discipline_external_id: this.disciplineExternalId,
            external_id: this.data.external_id, // question external_id
            title: this.form.controls.title.value,
            type: this.activeQuestionType,
            tags: this.selectedTags.map((tag) => ({ text: tag.text })),
            text: this.form.controls.description.value,
            options: [],
            ...this.questionData.payload,
        };

        if (payload.options.length) {
            const fallback = '';
            payload.options[0].correct_feedback = this.form.controls.positiveFeedback.value || fallback;
            payload.options[0].wrong_feedback = this.form.controls.negativeFeedback.value || fallback;
        }

        this.questionBank.updateQuestion(payload).subscribe({
            next: () => (this.edit.emit(), this.fadeout()),
        });
    }

    generateTagsSugestions($event?: any, firstLoad?: boolean) {
        if ($event) $event.stopPropagation();

        const sugestions = this.data.allTags.filter((originalTag) => originalTag.text
            .toLocaleLowerCase()
            .includes(this.form.controls.searchTagsInput.value.toLocaleLowerCase()));
        const nonRepetitiveSugestions = sugestions.filter(
            (sug) => !this.selectedTags.some((tag) => tag.text === sug.text)
        );
        this.sugestionTags = nonRepetitiveSugestions;

        if (firstLoad) return;

        this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
        this.tagAlreadySelected = this.selectedTags.some(
            (tag) => tag.text.toLocaleLowerCase().trim()
        === this.form.controls.searchTagsInput.value.toLocaleLowerCase().trim()
        );
    }

    selectTag(tag: { text: string }, index?: number) {
        const selectedTag = {
            text: tag.text,
        };

        this.forceReadyUpdateStatus();

        setTimeout(() => {
            if (index !== undefined) this.sugestionTags.splice(index, 1);
            else this.form.controls.searchTagsInput.setValue('');

            this.selectedTags.push(selectedTag);
            this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
        }, 0);
    }

    unselectTag(index: number) {
        this.selectedTags.splice(index, 1);

        this.forceReadyUpdateStatus();
    }

    forceReadyUpdateStatus() {
        try {
            const questionCreationComponentRef = this.questionCreation;

            questionCreationComponentRef.sendData();
        } catch (error) {}
    }

    fadeout(successfully = true) {
        const $formModalBody = this.element.nativeElement.querySelector('.form-body');
        $formModalBody.scrollTop = 0;
        $formModalBody.style.overflow = 'hidden';

        setTimeout(() => {
            this.fadeoutCanvas = true;
        }, 0);

        if (successfully) {
            setTimeout(() => {
                this.editedSuccessfully = true;
            }, 400);
        }

        setTimeout(() => {
            this.destroy();
        }, 2900);

        setTimeout(() => {
            $formModalBody.scrollTop = 0;
            $formModalBody.style.overflow = 'auto';
        }, 3400);
    }

    ngOnDestroy() {
        if (this.tagsSugestionKey) this.shared.toCloseWhenClickOutside(this.tagsSugestionKey);
    }
}
