<ng-container>
    <div
        class="flex flex-col bg-customWhite-default rounded-t-lg pt-4 pl-4 px-2 gap-6 shadow-lg h-full"
    >
        <div class="flex justify-between items-center">
            <h1 class="font-semibold text-26 text-customBlue-default font-Roboto">
                {{ i18n.dash_my_announcements }}
            </h1>
        </div>
        <div
            class="flex flex-col gap-3 overflow-y-scroll overflow-x-hidden"
            id="container"
        >
            <ng-container *ngFor="let announcement of dashData?.announcements">
                <a
                    class="flex flex-col rounded border border-customGray-lighterGray gap-4 p-2 mr-2"
                    role="link"
                    (click)="routeToLink(announcement)"
                >
                    <div class="p-1 overflow-x-hidden">
                        <span
                            class="text-customBlue-default text-16 font-Roboto font-medium"
                        >
                            {{ announcement.title }}
                        </span>
                        <p
                            *ngIf="announcement?.text"
                            class="text-16 text-customGray-dark font-Roboto font-normal overflow-x-hidden line-clamp-4"
                            [innerHTML]="announcement?.text | safeHTML"
                        ></p>
                    </div>
                    <div class="flex justify-between">
                        <span
                            class="text-14 text-customBlue-default font-Roboto font-normal"
                        >
                            {{ announcement?.discipline_name }}
                        </span>
                        <span
                            class="text-14 text-customGray-default font-Roboto font-normal italic"
                        >
                            {{ i18n.dash_published }}
                            {{ getTime(announcement.published_at) }}
                        </span>
                    </div>
                </a>
            </ng-container>

            <ng-container *ngIf="!dashData?.announcements?.length">
                <div class="flex justify-center items-center p-6">
                    <span
                        class="text-18 text-center text-customGray-iconGray font-semibold font-Roboto"
                    >
                        {{ i18n.dash_empty_message_announcements }}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
