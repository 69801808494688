import {
    AfterContentChecked, Component, OnInit
} from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { HomeService } from '../home/home.service';
import { SportsService } from './sports.service';

@Component({
    selector: 'app-sports',
    templateUrl: './sports.component.html',
    styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit, AfterContentChecked {
    constructor(
        private homeService: HomeService,
        private sportsService: SportsService,
        private platformModalService: PlatformModalsService
    ) {}

    srcIframe;
    isLoading = false;
    ngOnInit(): void {
        this.getUrlIframe();
    }

    ngAfterContentChecked(): void {
        this.homeService.setExpanded$.next(true);
    }

    getUrlIframe() {
        this.isLoading = true;
        this.sportsService.getUrlIframe().subscribe({
            next: (res) => {
                this.srcIframe = res.url;
                this.isLoading = false;
            },
            error: (err) => {
                this.platformModalService.toggle('message', err.error.error, 'close');
                this.isLoading = false;
            }
        });
    }
}
