import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GoogleAuthCheckService } from 'src/app/services/google-auth-check-service.service';

import { HomeService } from './home.service';

@Component({
    selector: 'app-home-layout',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeLayoutComponent implements OnInit, AfterViewInit {
    constructor(
        private googleAuthCheckService:GoogleAuthCheckService,
        private homeService: HomeService
    ) { }

    ngOnInit(): void {
        // timeout de 500ms para esperar carregar o a Api do Google (gapi)
        setTimeout(() => this.checkStatus(), 500);
    }

    ngAfterViewInit(): void {
        this.controlExpandedArea();
    }

    checkStatus(): void {
        // Checa a autenticação via Google do usuário
        this.googleAuthCheckService.checkStatusSignedGoogle();
    }

    controlExpandedArea(): void {
        this.homeService.getExpanded$.subscribe({
            next: (boolean: boolean) => {
                this.expandedArea(boolean);
            }
        });

        this.homeService.setExpanded$.subscribe({
            next: (boolean: boolean) => {
                this.expandedArea(boolean);
            }
        });
    }

    expandedArea(boolean: boolean): void {
        const component = Array.from(document.querySelectorAll('app-home-layout'))[0];
        if (component) {
            if (boolean) {
                component.classList.add('expanded-100');
            } else if (component.classList.contains('expanded-100')) component.classList.remove('expanded-100');
        }
    }
}
