import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
    FormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GoogleSpacesService } from 'src/app/services/googleSpaces/google-spaces.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { DisciplineDetailService } from '../../../disciplines-detail/discipline-detail.service';

@Component({
    selector: 'app-modal-create-space-google-chat',
    templateUrl: './modal-create-space-google-chat.component.html',
    styleUrls: ['./modal-create-space-google-chat.component.scss']
})
export class ModalCreateSpaceGoogleChatComponent implements OnInit {
    @Input() isVisible = false;
    @Input() i18n: any = {};

    @Output() closeModal_output = new EventEmitter();
    @Output() createSpace = new EventEmitter();

    spaceGoogleChatForm: UntypedFormGroup;
    isShowingEnableSyncTooltip = false;
    disciplineName: string;
    platformName: string;
    disciplineExternalId: string;

    constructor(
        private formBuilder: FormBuilder,
        private disciplineDetailService: DisciplineDetailService,
        private route: ActivatedRoute,
        private googleSpacesService: GoogleSpacesService,
        public platformModalsService: PlatformModalsService
    ) { }

    ngOnInit(): void {
        this.createSpaceGoogleChatForm();
        this.getDisciplineAndPlatformInfo();
        this.getDisciplineExternalId();
    }

    // Forms
    createSpaceGoogleChatForm() {
        this.spaceGoogleChatForm = this.formBuilder.group({
            space_display_name: ['', [Validators.required]],
            description: ['', [Validators.required]],
            external_user_allowed: [false],
            enable_sync: [false],
            only_teacher_can_post: [false],
        });
    }

    getDisciplineAndPlatformInfo() {
        this.disciplineDetailService
            .disciplineBasic$
            .subscribe((discipline: any) => {
                let siteMetadata;
                if (localStorage.getItem('site_metadata') !== null) {
                    siteMetadata = JSON.parse(localStorage.getItem('site_metadata') || '');
                    this.platformName = `${this.i18n.disciplines_placeholder_space_description} ${siteMetadata.settingsGeneral.site_title}`;
                    this.disciplineName = `${discipline.name} - [${siteMetadata.settingsGeneral.site_title}]`;
                }
            });
    }

    getDisciplineExternalId() {
        this.route?.parent?.params.subscribe((param) => {
            this.disciplineExternalId = param.id;
        });
    }

    requestScopeGoogleSpaces() {
        // this.platformModalsService.toggle('loading');
        this.googleSpacesService.requestChatScope(this.returnToken);
    }

    returnToken = (token) => {
        // this.platformModalsService.toggle('loading');
        this.setupDataToCreateSpace(token);
    };

    setupDataToCreateSpace(token: string) {
        const params = {
            code: token,
            disc_external_id: this.disciplineExternalId,
            space_display_name: this.spaceGoogleChatForm.controls.space_display_name.value,
            description: this.spaceGoogleChatForm.controls.description.value,
            external_user_allowed: this.spaceGoogleChatForm.controls.external_user_allowed.value,
            enable_sync: this.spaceGoogleChatForm.controls.enable_sync.value,
            only_teacher_can_post: this.spaceGoogleChatForm.controls.only_teacher_can_post.value,
        };
        this.createSpace.emit(params);
    }

    get space_display_name() {
        return this.spaceGoogleChatForm.get('space_display_name');
    }

    get description() {
        return this.spaceGoogleChatForm.get('description');
    }

    get external_user_allowed() {
        return this.spaceGoogleChatForm.get('external_user_allowed');
    }

    get enable_sync() {
        return this.spaceGoogleChatForm.get('enable_sync');
    }

    get only_teacher_can_post() {
        return this.spaceGoogleChatForm.get('only_teacher_can_post');
    }

    submit() {
        this.requestScopeGoogleSpaces();
    }

    closeModal(): void {
        this.closeModal_output.emit();
    }
}
