<section
style="display: flex; align-items: center; flex-direction: column; gap: 25px"
>

  <!-- LOADING -->
  <div *ngIf="isLoading"
    class="flex justify-center items-center background-config relative w-full">
    <app-bullets-loader></app-bullets-loader>
  </div>
  <!-- DISCIPLINE COVER IMAGE  -->
  <div
    *ngIf="disciplineDetail"
    [style.background]="'url(' + disciplineDetail?.cover_image + ')'"
    class="flex justify-between background-config relative w-full"
  >
    <ng-container>
      <img
        [src]="disciplineDetail?.cover_image" style="display: none"
        onerror="{if (this.parentElement !== null) {
          this.parentElement.style.background = '#eef2fea5',
          this.parentElement.style.border = '2px solid #ced9ff'
        }}">
      <div
        class="flex flex-col mt-6 ml-8"
        style="animation: var(--fadein); cursor: default; position: absolute; max-width: 70%;"
      >
        <h2
          class="line-clamp-3 lg:line-clamp-1"
          pTooltip="{{ disciplineDetail?.name }}"
          tooltipPosition="top"
          [style.color]="disciplineDetail?.header_text_color"
        >
          {{ disciplineDetail?.name }}
        </h2>

        <ng-container *ngIf="disciplineDetail?.read_only">
          <div class="mt-8">
            <button
              (click)="subscribeDiscipline(disciplineDetail?.external_id)"
              class="border rounded-md py-2 px-6"
              style="
                color: #ffffff;
                background-color: #e8532c;
                font-size: 0.8em;
              "
            >
              {{ i18n.disciplines_subscribe }}
            </button>
          </div>
        </ng-container>

      </div>
    </ng-container>
  </div>

  <!-- TABS -->
  <div class="flex xs:grid justify-center overflow-hidden round-childs">
    <!-- OVERVIEW -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.dashboard_visible">
      <ng-container *appCheckUrlToHide="'disciplines-models'">
        <button
          *appCheckPermissions="'disciplines'; type: 'list'"
          class="tab bg-disciplines p-2 px-4"
          (click)="changeTab('overview')"
          [ngClass]="{ 'button-disciplines-selected': selected === 'overview' }"
        >
          {{ i18n.disciplines_content_select_tab_overview }}
        </button>
      </ng-container>
    </ng-container>

    <!-- CONTENTS -->
    <ng-container *ngIf="!isLoading">
          <button
            *appCheckPermissions="'sections'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('content-tab')"
            [ngClass]="{
              'button-disciplines-selected':
                selected === 'content-tab' || selected === 'content-assignment'
            }"
          >
            {{ i18n.disciplines_select_tab_content }}
          </button>
    </ng-container>

    <!-- assessments -->
    <ng-container *ngIf="!isLoading && disciplineDetail?.tabs_settings?.assessments_visible">
          <button
            *appCheckPermissions="'trieduc'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('assessments')"
            [ngClass]="{
              'button-disciplines-selected':
                selected === 'assessments'
            }"
          >
            {{ i18n.disciplines_assessments }}
          </button>
    </ng-container>

    <!-- GRADES -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.grades_visible">
      <ng-container *ngIf="!disciplineDetail?.read_only">
        <ng-container *appCheckUrlToHide="'disciplines-models'">
          <button
            *appCheckPermissions="'grades'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('grades')"
            [ngClass]="{ 'button-disciplines-selected': selected === 'grades' }"
          >
            {{ i18n.disciplines_select_tab_grades }}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- REPORTS -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.reports_visible">
      <ng-container *ngIf="!disciplineDetail?.read_only">
        <ng-container *appCheckUrlToHide="'disciplines-models'">
          <button
          *appCheckPermissions="'reports'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('reports')"
            [ngClass]="{ 'button-disciplines-selected': selected === 'reports' }"
          >
            {{ i18n.disciplines_reports_}}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- ANNOUNCEMENTS -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.announcements_visible">
      <ng-container *ngIf="!disciplineDetail?.read_only">
        <ng-container *appCheckUrlToHide="'disciplines-models'">
          <button
            *appCheckPermissions="'announcements'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('announcements')"
            [ngClass]="{
              'button-disciplines-selected': selected === 'announcements'
            }"
          >
            {{ i18n.disciplines_select_tab_waning }}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- QUESTION-BANK -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.bank_questions_visible">
      <ng-container *ngIf="!disciplineDetail?.read_only">
        <button
          *appCheckPermissions="'quiz'; type: 'list'"
          class="tab bg-disciplines p-2 px-4"
          (click)="changeTab('question-bank')"
          [ngClass]="{
            'button-disciplines-selected': selected === 'question-bank'
          }"
        >
          {{ i18n.disciplines_select_tab_bank_question }}
        </button>
      </ng-container>
    </ng-container>

    <!-- FORUM -->
    <ng-container *ngIf="disciplineDetail?.tabs_settings?.forums_visible">
      <ng-container *ngIf="!disciplineDetail?.read_only">
        <ng-container *appCheckUrlToHide="'disciplines-models'">
          <button
            *appCheckPermissions="'forum'; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('forum')"
            [ngClass]="{ 'button-disciplines-selected': selected === 'forum' }"
          >
            {{ i18n.disciplines_select_tab_forum }}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- DISCIPLINE SETTINGS -->
    <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="!disciplineDetail?.read_only">
          <button
            *appCheckPermissions="''; groups: ['disciplines_settings']; type: 'list'"
            class="tab bg-disciplines p-2 px-4"
            (click)="changeTab('discipline-settings')"
            [ngClass]="{
              'button-disciplines-selected': selected === 'discipline-settings'
            }"
          >
            {{ i18n.disciplines_select_tab_settings }}
          </button>
        </ng-container>
    </ng-container>
  </div>

  <div style="width: 100%" *ngIf="!isLoading">
    <router-outlet></router-outlet>
  </div>

   <!-- <div class='w-full text-center p-10' style="color: #233674">
        Mais dúvidas? Visite o  <a href=""><b>Cental de Ajuda ao Estudante</b></a>
    </div> -->
</section>
