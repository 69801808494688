import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';

import { HomeLayoutComponent } from '../home/home.component';
import { UsersComponent } from './users.component';
import { UsersTitleResolver } from './users-title.resolver';

const routes: Routes = [
    {
        path: '',
        component: HomeLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: UsersComponent,
                resolve: {
                    title: UsersTitleResolver
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes),
    ],
    exports: [RouterModule]
})
export class UsersRoutingModule { }
