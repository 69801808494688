<div class="box-options">
  <div class="box-options__option">
      <div class="text-options-1">
          <img *ngIf="type === 'google_classroom'" src="../../../../assets/png/classroom_icon.png" alt="icon classroom" style="width: 20px;">
          <div class="flex flex-col">
                <div
                    pTooltip="{{ discipline.title }}" tooltipPosition="top"
                    tooltipStyleClass="tooltip-discipline"
                >
                  {{ disciplineTitle(discipline.title) }}
                </div>
                <div class="text-12 font-normal">{{ discipline?.section_classroom }}</div>
          </div>
      </div>
      <label class="container">
          <input [id]="discipline.external_id" class="{{discipline.external_id}}" type="checkbox"
                 [value]="discipline.external_id" [checked]="isSelected()"
                 (change)="checkDisciplineChange()"/>
          <span class="check"></span>
      </label>
  </div>
</div>
