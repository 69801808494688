import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-question-bank-trieduc',
    templateUrl: './question-bank-trieduc.component.html',
    styleUrls: ['./question-bank-trieduc.component.scss']
})
export class QuestionBankTrieducComponent {
    @Input() questions: any;
    @Input() selectedQuestions: any;
    @Input() i18n: any;

    @Output() setQuestions = new EventEmitter();

    questionsSelected: any = [];

    constructor(private sanitizer: DomSanitizer) { }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    selectQuestion($question: any) {
        if ($question.isSelected) {
            this.questionsSelected.push($question);
        } else {
            this.questionsSelected = this.questionsSelected
                .filter((question: any) => question.id !== $question.id);
        }

        this.setQuestions.emit(this.questionsSelected);
    }
}
