export class CacheUtil {
    static setCache(key: string, data: any, ttl: number): void {
        const expiration = new Date().getTime() + ttl;
        const cacheData = {
            data,
            expiration
        };
        localStorage.setItem(key, JSON.stringify(cacheData));
    }

    static getCache(key: string): any {
        const cacheData = localStorage.getItem(key);
        if (!cacheData) return null;

        const parsedData = JSON.parse(cacheData);
        if (new Date().getTime() > parsedData.expiration) {
            localStorage.removeItem(key);
            return null;
        }

        return parsedData.data;
    }
}
