<app-generic-modal [isVisible]="isVisible">
    <div class="pb-6 pt-4 sm:w-screen max-w-lg">
        <header class="text-customBlue-default  border-b border-customBlue-glass pb-4 px-6">
            <p class="text-center font-bold text-26">{{ i18n.disciplines_create_google_chat }}</p>
            <p class="text-center">{{ i18n.disciplines_create_google_chat_description }}</p>
        </header>
        <form [formGroup]="spaceGoogleChatForm">
            <section class="p-6">
                <div class="w-full border border-customBlue-royal bg-customBlue-hawkesBlue rounded-md " >
                    <div class="flex justify-center items-center gap-2 p-2">
                        <div>
                            <span class="iconify w-6 h-6 text-customBlue-royal " data-icon="vaadin:exclamation-circle"></span>
                        </div>
                        <span class="text-12 text-customGray-dark font-montserrat font-medium">
                            {{ i18n.disciplines_create_google_chat_warn }}
                        </span>
                    </div>
                </div>

                <div class="text-customGray-dark">
                    <div class="py-2 " >
                        <h2 class="my-2 text-customBlue-default font-16 font-medium">{{ i18n.disciplines_google_chat_space_name }}</h2>
                        <input
                            required
                            formControlName="space_display_name"
                            type="text"
                            class="w-full border border-customGray-default rounded-md h-10 pl-2"
                            [placeholder]="disciplineName"
                        >
                        <div class="absolute" *ngIf="!space_display_name?.pristine && space_display_name?.errors">
                            <p class="text-sm " style="color: #E8532C;" *ngIf="space_display_name?.errors?.required">
                                {{ i18n.disciplines_google_chat_space_name_required }}
                            </p>
                        </div>
                    </div>

                    <div class="py-2 " >
                        <h2 class="my-2 text-customBlue-default font-16 font-medium">{{ i18n.disciplines_space_description }}</h2>
                        <input
                            required
                            formControlName="description"
                            type="text"
                            class="w-full border border-customGray-default rounded-md h-10 pl-2"
                            [placeholder]="platformName"
                        >
                        <div class="absolute" *ngIf="!description?.pristine && description?.errors">
                            <p class="text-sm " style="color: #E8532C;" *ngIf="description?.errors?.required">
                                {{ i18n.disciplines_space_description_required }}
                            </p>
                        </div>
                    </div>

                    <div class="flex items-center my-4 gap-4">
                        <app-toggle-button
                            [labelText]="i18n.disciplines_enable_outsiders_toggle"
                            [control]="external_user_allowed"
                        ></app-toggle-button>
                    </div>

                    <div class="flex items-center my-4 gap-4">
                        <app-toggle-button
                            [labelText]="i18n.disciplines_only_teachers_can_post"
                            [control]="only_teacher_can_post"
                        ></app-toggle-button>
                    </div>

                    <div class="relative flex items-center my-4 gap-4">
                        <app-toggle-button
                            [labelText]="i18n.disciplines_enable_sync_toggle"
                            [control]="enable_sync"
                        ></app-toggle-button>
                        <button
                            class="w-5 h-5 relative cursor-pointer tooltip"
                        >
                            <span class="iconify w-5 h-5 cursor-pointer text-customGray-default" data-icon="bi:question-circle"></span>
                            <div
                                class="tooltip_text p-4 lg:top-6 bottom-6 lg:left-0 right-0"
                            >
                                <span>
                                    {{ i18n.disciplines_enable_sync_tooltip_description }}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </section>
            <footer class="flex items-center justify-center gap-4 px-6">
                <button
                    type="button" (click)="submit()"
                    [disabled]="spaceGoogleChatForm.invalid"
                    [ngClass]="{'disabled:opacity-20 cursor-not-allowed': spaceGoogleChatForm.invalid}"
                    class="px-10 py-2 text-customWhite-default font-bold bg-customBlue-default rounded"
                >
                    {{ i18n.disciplines_create_button }}
                </button>
                <button
                    type="button" (click)="closeModal()"
                    class="px-6 py-2 text-customBlue-default font-bold"
                >
                    {{ i18n.disciplines_cancel }}
                </button>
            </footer>
        </form>
    </div>
</app-generic-modal>
