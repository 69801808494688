<app-generic-modal [isVisible]="isVisible">
    <header class="header">
        <div class="title">{{ title }}</div>

        {{ description }}
    </header>

    <div
        class="form-body"
        [ngClass]="{
            'overflow-y-auto': overflow,
            'overflow-x-auto': overflow,
            'height-auto': !overflow,
            'height-default': overflow
        }"
    >
        <ng-content></ng-content>
    </div>
</app-generic-modal>
