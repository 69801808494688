<app-generic-modal [isVisible]="modalOpen">
    <div class="container flex items-center justify-center flex-col p-7 gap-7 max-w-sm">
        <!--content-->
        <div class="flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--body-->
            <div class="px-6 pt-6">
                <div [ngSwitch]="status">
                    <div
                        *ngSwitchCase="'success'"
                        class="flex items-center justify-center filter pb-3"
                    >
                        <img src="../../../assets/svg/toastr/success.svg" />
                    </div>
                    <div
                        *ngSwitchCase="'error'"
                        class="flex items-center justify-center filter pb-3"
                    >
                        <img src="../../../assets/svg/toastr/error.svg" />
                    </div>
                    <div
                        *ngSwitchCase="'info'"
                        class="flex items-center justify-center filter pb-3"
                    >
                        <img src="../../../assets/svg/toastr/info.svg" />
                    </div>
                    <div
                        *ngSwitchDefault
                        class="flex items-center justify-center filter pb-3"
                    >
                        <img src="../../../assets/svg/toastr/success.svg" />
                    </div>
                </div>
                <div class="flex text-center items-center justify-center flex-nowrap">
                    <h3 class="text-16 font-medium font-roboto text-customGray-dark">
                        {{ message }}
                    </h3>
                </div>
            </div>

            <!--footer-->
            <ng-container>
                <div class="flex items-center justify-center p-6 rounded-b">
                    <button
                        class="bg-customBlue-default text-customWhite-default active:bg-emerald-600 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        (click)="closeModal()"
                    >
                        {{ i18n.disciplines_modal_confirmation_back }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</app-generic-modal>
