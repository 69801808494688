import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Router } from '@angular/router';
import { DetailedPlanning } from 'src/app/pages/content-planner/interfaces/planning.interface';

@Component({
    selector: 'app-modal-detailed-planning',
    templateUrl: './modal-detailed-planning.component.html',
    styleUrls: ['./modal-detailed-planning.component.scss']
})
export class ModalDetailedPlanningComponent implements OnInit {
    @Input() isVisibleModalDetailedPlanning = false;
    @Input() planningExternalId = '';
    @Input() i18n: any = {};
    @Output() closeModalDetailedPlanning = new EventEmitter();
    detailedPlanningList: DetailedPlanning[] = [];
    selectedPlanning: 'class_to_class' | 'estimated' | '' = '';

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.getDetailedPlanning();
    }

    getDetailedPlanning(): void {
        this.detailedPlanningList = [
            {
                type: 'estimated',
                title: this.i18n.planner_planning_with_estimated_class,
                description: this.i18n.planner_create_planning_with_estimated_class,
                isSelected: false,
            },
            {
                type: 'class_to_class',
                title: this.i18n.planner_for_class_to_class,
                description: this.i18n.planner_create_planning_quantity_class_defined,
                isSelected: false,
            }
        ];
    }

    closeModal(): void {
        this.closeModalDetailedPlanning.emit(false);
    }

    resetModalData(): void {
        this.selectedPlanning = '';
        this.detailedPlanningList.forEach((e) => { e.isSelected = false; });
    }

    selectPlanning(type: 'class_to_class' | 'estimated') {
        this.selectedPlanning = this.selectedPlanning === type ? '' : type;

        this.detailedPlanningList.forEach((e) => {
            e.isSelected = e.type === this.selectedPlanning;
        });
    }

    onSubmit(): void {
        if (this.selectedPlanning) {
            const page = this.selectedPlanning.includes('class_to_class') ? 'detailed-class-to-class' : 'detailed-estimated';
            this.router.navigate([`class-planner/${page}`, this.planningExternalId, this.selectedPlanning]);
            this.resetModalData();
            this.closeModal();
        }
    }
}
