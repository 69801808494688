<div class="search-bar">
  <img src="assets/svg/search.svg" alt="Icon search" class="search_icon" />
  <input
    class="search-bar__input"
    type="text"
    [placeholder]="placeholder"
    [value]="search"
    #box
    (keyup)="onSearchChange(box.value)"
  />
</div>
