import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SidebarService implements OnDestroy {
    getLangs$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(private http: HttpClient) { }

    getLangs(): Observable<{ languages: string[] }> {
        return this.http.get<{ languages: string[] }>(`${API_Routes.URL}/settings/langs`);
    }

    setLangs(langs: string[]): void {
        if (langs && Array.isArray(langs)) {
            this.getLangs$.next(langs);
        }
    }

    returnLangs(): Observable<string[]> {
        return this.getLangs$.asObservable();
    }

    ngOnDestroy(): void {
        this.getLangs$.complete();
    }
}
