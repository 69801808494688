import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from 'src/app/services/pushNotificationService/push-notification.service';
import { getMessaging, getToken, onMessage, isSupported  } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  settings_firebase;
  messaging;
  constructor(
    private pushNotificationService: PushNotificationService,
    private platformModalsService: PlatformModalsService,
  ) { }

  ngOnInit(): void {
    this.setManifestFirebase();
    this.getFirebaseMessagingSettings();
  }

  setManifestFirebase(){
    this.pushNotificationService.firebaseManifest()
      .subscribe({
        next:(res)=>{
          let link = document.createElement('link');
          const manifestString = JSON.stringify(res);
          link.rel = "manifest";
          link.href = URL.createObjectURL(new Blob([manifestString], { type: 'application/json' }));
          document?.getElementsByTagName('head')[0].appendChild(link);
        },
        error:(err)=>{
          this.platformModalsService.toggle('message', JSON.stringify(err.error), 'close');
        }
      });
  }

  getFirebaseMessagingSettings(){
    // checks if serviceWorker is available
    if (!navigator) {
      return;
    }
    if (!("serviceWorker" in navigator)) {
      console.log('ServiceWorker não disponível');
      return;
    }
    isSupported().then((supported: boolean)=>{
      if (supported) {
        this.pushNotificationService.firebaseMessagingSeviceWorker()
        .subscribe({
          next:(res)=>{
            if (Object.values(res)) {
            
              this.settings_firebase = {
                apiKey: res?.apiKey,
                authDomain: res?.authDomain,
                projectId: res?.projectId,
                storageBucket: res?.storageBucket,
                messagingSenderId: res?.messagingSenderId,
                appId: res?.appId,
                measurementId: res?.measurementId,
                vapidKey: res?.vapidKey
              }

              const app = initializeApp(this.settings_firebase);
              this.messaging = getMessaging(app);
              this.requestPermission(this.settings_firebase?.vapidKey, res);
              
            }
          },
          error:(err)=>{
            this.platformModalsService.toggle('message', JSON.stringify(err.error), 'close');
          }
        });
      }

    })
      
    
    
  }

  url: string;
  requestPermission(vapidKey: string, res: string) {
    let url = window.location.origin;
    navigator.serviceWorker?.register(url+'/api/firebase/file/firebase-messaging')
      .then((registration) => {
        this.messaging.swRegistration = registration;
        getToken(this.messaging, 
          { vapidKey: vapidKey}).then(
            (currentToken) => {
                if (currentToken) {
                const params = {
                  "device":currentToken
                }
                this.firebaseCreateDevice(params)
              } else {
                  console.info('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                  console.error('An error occurred while retrieving token. ', err);
          });
        // console.log('Custom service worker registered.');
      })
      .catch((err) => {
        console.error('Failed to register custom service worker:', err);
      });
    

  }

  firebaseCreateDevice(token){
    this.pushNotificationService.firebaseCreateDevice(token)
      .subscribe({
        next:(res)=>{          
          this.listen();
        },
        error:(err)=>{
          this.platformModalsService.toggle('message', JSON.stringify(err.error), 'close');
        }
      });
  }

  listen() {
    onMessage(this.messaging, (payload) => {
      console.info('Message received. ', payload);
    });
  }

}
