import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    user$: Subject<any> = new BehaviorSubject<any>([]);

    constructor(private http: HttpClient) { }

    getUsers(params?, query?: string): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/users?page=${params.page}&per_page=${params.per_page}&q=${query || ''}`);
    }

    getUsersAcceptedTerms(external_id: any): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/users/${external_id}/accepted-terms-date`);
    }

    postUser(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/users/`, params);
    }

    patchUser(external_id: any, params: any): Observable<any> {
        return this.http.patch(`${API_Routes.URL}/users/${external_id}`, params);
    }

    delUser(external_id: any): Observable<any> {
        return this.http.delete(`${API_Routes.URL}/users/${external_id}`);
    }

    addEnrollment(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/enrollments`, params);
    }

    activateUser(external_id: any): Observable<any> {
        const params: any = [];
        return this.http.patch(`${API_Routes.URL}/users/${external_id}/activate`, params);
    }

    deactivateUser(external_id: any): Observable<any> {
        const params: any = [];
        return this.http.patch(`${API_Routes.URL}/users/${external_id}/deactivate`, params);
    }

    removeEnrollment(params: any):Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: params
        };

        return this.http.delete(`${API_Routes.URL}/enrollments`, options);
    }

    impersonate(params:any):Observable<any> {
        return this.http.post(`${API_Routes.URL}/impersonate/impersonificate`, params);
    }

    depersonificate():Observable<any> {
        const params = [];
        return this.http.post(`${API_Routes.URL}/impersonate/depersonificate`, params);
    }

    getDisicplineEnrollments(role_external_id: any, user_external_id: any, params?, query?: string): Observable<any> {
        return this.http.get(`${API_Routes.URL}/enrollments/${role_external_id}/${user_external_id}?page=${params.page}&per_page=${params.per_page}&q=${query || ''}`);
    }

    getCommssionTeam(params: any):Observable<any[]> {
        return this.http.get<any[]>(`${API_Routes.URL}/commission?q=${params.query}`);
    }

    commissionGroup(commissionGroup: any):Observable<any> {
        return this.http.patch(`${API_Routes.URL}/commission/group`, commissionGroup);
    }

    commissionTeam(commissionTeam: any):Observable<any> {
        return this.http.patch(`${API_Routes.URL}/commission`, commissionTeam);
    }

    deleteCommissionUser(deleteItems: any[]) {
        return this.http.delete(`${API_Routes.URL}/commission`, { body: deleteItems });
    }
}
