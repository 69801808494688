<div>
  <header class="mb-3">
    <p class="text-customGray-dark font-semibold">
      {{ i18n.disciplines_content_card_rubric_criterion }}:
      {{ receiveCriterion.name }}
    </p>
  </header>
  <ul class="grid lg:grid-cols-2 gap-6">
    <li *ngFor="let level of receiveCriterion.levels; let index = index">
      <ng-container *ngIf="!level.deleted">
        <small class="text-customGray-default text-14 flex justify-end mb-2"
          >{{ i18n.disciplines_content_card_rubric_valid }}
          {{ replaceDotForComma(level.points) }}</small
        >
        <div
          class="rounded border border-customGray-mediumGray p-3 flex flex-col items-center gap-3 text-customGray-dark"
          [style.min-height]="'calc(100% - 29px)'"
          role="button"
          (click)="selectLevel(level?.id, receiveCriterion);"
          [ngClass]="{'active': level?.selected}"
          
        >
          <p class="font-medium">{{ level.name }}</p>
          <p>
            {{ level.description }}
          </p>
          <!-- Tooltip -->
          <div class="relative">
            <app-display-panel
              #displayPanel
              [style]="{ position: 'absolute' }"
              [custom_radius]="'10px'"
              [hide_arrow]="true"
              [panel_style]="{ background: '#FEF8E6', width: '15.5rem'}"
              [newStyle]="{ top: '-12.25rem' }">
              <small>
                {{ i18n.disciplines_content_card_rubric_tooltip }}
              </small>
            </app-display-panel>
          </div>
        </div>
      </ng-container>
    </li>
  </ul>
</div>
