import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve, RouterStateSnapshot
} from '@angular/router';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class UsersTitleResolver implements Resolve<void> {
    constructor(
        private sharedService : SharedService,
        private titleService: TitlePageService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        const i18n = { ...this.sharedService.getTranslationsOf('Users') };

        const paths = [
            { url: '', title: i18n.users_title },
        ];

        this.titleService.resolveTitle(route, paths);
    }
}
