<app-generic-modal [isVisible]="isVisible">
    <div class="container">
      <div class="message">
        <span
          class="iconify text-customBlue-royal"
          data-icon="jam:alert-f"
          data-width="40"
          data-height="40"
        ></span>
        <span class="text-16 text-customGray-dark font-medium" [innerHtml]="message"></span>
      </div>

      <div class="decision-btns center">
        <button class="button border border-customBlue-default text-16 text-customBlue-default rounded px-4 py-1 font-semibold font-OpenSans"
            (click)="close($event)" tabindex="1"
        >
          {{ btnText }}
        </button>
      </div>
    </div>
</app-generic-modal>
