import { GlobalSearchComponent } from './global-search/global-search.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '../translate/translate.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserFrameModule } from '../user-frame/user-frame.module';
import { DisplayPanelModule } from '../display-panel/display-panel.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NotificationTooltipComponent } from './notification-tooltip/notification-tooltip.component';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { PermissionModule } from 'src/app/directives/permission/permission.module';


@NgModule({
  declarations: [
    HeaderComponent,
    GlobalSearchComponent,
    NotificationTooltipComponent,
    AccessibilityComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    UserFrameModule,
    DisplayPanelModule,
    LazyLoadImageModule,
    PermissionModule
  ]
})
export class HeaderModule { }
