import {
    Component, EventEmitter, Input, OnDestroy, OnInit,
    Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';

import { updateActiveCourseWork } from '../../../../store/homework-submissions.actions';
import {
    selectConcludedSubmissions,
    selectHomeworkSubmissionsState,
} from '../../../../store/homework-submissions.reducer';

@Component({
    selector: 'app-submission-choose-submission-board',
    templateUrl: './submission-choose-submission-board.component.html',
    styleUrls: ['./submission-choose-submission-board.component.scss'],
})
export class SubmissionChooseSubmissionBoardComponent
implements OnInit, OnDestroy {
    @Input() i18n: any = {};

    @Output() sendIsLoadingCourseWork = new EventEmitter();
    concludedSubmissions$;
    activeCourseWork;
    contentID;
    state$ = this.store
        .select(selectHomeworkSubmissionsState)
        .subscribe((state) => {
            this.contentID = state.contentID;
            this.activeCourseWork = state.activeCourseWork;
        });

    constructor(
        private store: Store,
        private submissionsService: SubmissionsService
    ) {}

    ngOnInit(): void {
        this.store.select(selectConcludedSubmissions).subscribe((concludedSubmissions) => {
            this.concludedSubmissions$ = concludedSubmissions;
        });
    }

    onClickUser(event: MouseEvent, courseWork: any) {
        if (courseWork.completed_at === null) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        this.changeActiveCourseWork(courseWork.id);
    }

    trySelected: any;
    changeActiveCourseWork(newCourseWorkID) {
        this.sendIsLoadingCourseWork.emit(true);
        this.submissionsService
            .getCourseWork(this.contentID, newCourseWorkID)
            .subscribe((courseWork: any) => {
                this.store.dispatch(
                    updateActiveCourseWork({ activeCourseWork: courseWork }),

                );
                // this.activeCourseWork = courseWork;
                if (Object.prototype.hasOwnProperty.call(courseWork.contentSpecific, 'tries')) {
                    const tries = [...courseWork.contentSpecific.tries].reverse();
                    this.trySelected = tries[0].try_id;
                    const tryObj = tries.find((try_obj) => try_obj.try_id === tries[0].try_id);

                    this.submissionsService.homework.next(tryObj);
                }
                this.sendIsLoadingCourseWork.emit(false);
            });
    }

    ngOnDestroy(): void {
        if (this.state$) this.state$.unsubscribe();
    }
}
