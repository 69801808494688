import { DisciplinesModelsRoutingModule } from './disciplines-models-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisciplinesModelsComponent } from './disciplines-models.component';

import { SharedModule } from 'src/app/shared/shared.module';

import { ModalAddDisciplineComponent } from './components/modal-add-discipline/modal-add-discipline.component';
import { CardDisciplineComponent } from './components/card-discipline/card-discipline.component';

import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';

/* import { ImportModalComponent } from './components/import-modal/import-modal.component';
import { SelectOptionBoxComponent } from './components/select-option-box/select-option-box.component';
import { SelectDisciplineBoxComponent } from './components/select-discipline-box/select-discipline-box.component'; */
import { CopyAndReplicationModule } from 'src/app/components/copy-and-replication/copy-and-replication.module';
import { LoadingModalModule } from 'src/app/components/modals/loading-modal/loading-modal.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { ModalConfirmClassroomComponent } from './components/modal-confirm-classroom/modal-confirm-classroom.component';


@NgModule({
  declarations: [
    DisciplinesModelsComponent,
    ModalAddDisciplineComponent,
    CardDisciplineComponent,
    ModalConfirmClassroomComponent,
   /*  ImportModalComponent,
    SelectOptionBoxComponent,
    SelectDisciplineBoxComponent */
  ],
  imports: [
    CommonModule,
    SharedModule,
    DisciplinesModelsRoutingModule,
    DisplayPanelModule,
    GenericModalModule,
    CopyAndReplicationModule,
    LoadingModalModule,
    InputMaskModule
  ]
})
export class DisciplinesModelsModule { }
