<ng-container *ngIf="delivery_allowed || content.coursework_completed_at !== ''">
  <div class="p-4">
    <div class="text-center">
        <ng-container *ngIf="!isStateSended">
            <span class="text-16 text-customBlue-default font-medium">
                {{ i18n.upload_description_completion_rule }}
            </span>
        </ng-container>

        <app-upload-task-delivery
            #uploadTaskDelivery
            [textUploadBtn]="text_upload_button"
            [multiSelect]="true"
            [files]="files"
            [fileType]="fileType"
            [maxFiles]="maxFiles"
            [syncWithDrive]="true"
            [showOpenBtn]="true"
            [showReplaceBtn]="false"
            [showRemoveBtn]="true"
            [showSendTitle]="true"
            [delivery_allowed]="delivery_allowed"
            [statusDelivery]="statusDelivery"
            [content]="content"
            [isStateSended]="isStateSended"
            (loadedFile)="receiveLoadedFile($event)"
            [remainingAttempts]="getRemainingAttempts()"
            (filesEvent)="receiveFiles($event)"
        ></app-upload-task-delivery>
    </div>

    <ng-container *ngIf="hasListFiles() && loadedFile">

        <ng-container *ngIf="!isStateSended">
    
            <div class="flex justify-center mt-10">
                <button 
                    (click)="sendUploadTasks()"
                    [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !isValid || !delivery_allowed || loading}" 
                    [disabled]="!isValid || !delivery_allowed || loading" 
                    class="flex justify-center border bg-customBlue-default rounded-md"
                >
                    <div class="flex items-center py-1 px-12 gap-2">
                      <div *ngIf="loading">
                        <span class="iconify w-5 h-5 text-customWhite-default" data-icon="line-md:uploading-loop"></span>
                      </div>
                      <div *ngIf="!loading">
                        <span class="text-base font-bold text-customWhite-default">{{ i18n.disciplines_submit }}</span>
                      </div>
                    </div>
                </button>
            </div>
        </ng-container>
    
        <ng-container *ngIf="isStateSended">
    
            <div class="flex justify-center mt-10">
                <button 
                    (click)="makeNewDelivery()"
                    [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !delivery_allowed || getRemainingAttempts() === 0 }" 
                    [disabled]="!delivery_allowed || getRemainingAttempts() === 0" 
                    
                    class="flex justify-center border bg-customBlue-default rounded-md"
                >
                    <div class="flex items-center py-1 px-12 gap-2">
                      <div *ngIf="loading">
                        <span class="iconify w-5 h-5 text-customWhite-default" data-icon="line-md:uploading-loop"></span>
                      </div>
                      <div *ngIf="!loading">
                        <span class="text-base font-bold text-customWhite-default">{{ i18n.disciplines_make_new_delivery }}</span>
                      </div>
                    </div>
                </button>
            </div>
        </ng-container>
    </ng-container>
  </div>
</ng-container>

