import {
    ChangeDetectorRef,
    Component, ElementRef, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { CurricularFilters } from '../../../interfaces/planning.interface';
import { ContentPlannerService } from '../../../services/content-planner.service';

@Component({
    selector: 'app-navigable-curriculum',
    templateUrl: './navigable-curriculum.component.html',
    styleUrls: ['./navigable-curriculum.component.scss']
})
export class NavigableCurriculumComponent implements OnInit {
    public i18n: any = {};
    public filters: CurricularFilters | null = null;
    public filterTypes: { key: string }[] = [
        { key: 'segments' },
        { key: 'degrees' },
        // { key: 'areas' },
        // { key: 'components' }
    ];

    public selectedFilters: { [key: string]: number } = {};
    public availableFilters: { [key: string]: number[] } = {};

    public planning: any = null;
    @ViewChild('currilumDomElement') firstDomElement: ElementRef;

    isLoading = false;
    isLoadingPlanning = false;

    @ViewChild('carousel') carousel!: ElementRef;

    constructor(
        private contentPlannerService: ContentPlannerService,
        private platformModalService: PlatformModalsService,
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getFilters();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    getFilters(): void {
        this.isLoading = true;
        this.contentPlannerService.getFilters().subscribe({
            next: (response) => {
                this.isLoading = false;
                this.filters = response;
                this.availableFilters['segments'] = response.segmentsAvailable;
            },
            error: () => {
                this.platformModalService.toggle('message', this.i18n.planner_error_load_filters, 'close');
                this.isLoading = false;
            }
        });
    }

    scrollLeft(): void {
        const carousel = this.carousel.nativeElement;
        if (carousel.scrollLeft === 0) {
            carousel.scrollLeft = carousel.scrollWidth;
        } else {
            carousel.scrollLeft -= carousel.offsetWidth;
        }
    }

    scrollRight(): void {
        const carousel = this.carousel.nativeElement;
        if (carousel.scrollLeft + carousel.offsetWidth >= carousel.scrollWidth) {
            carousel.scrollLeft = 0;
        } else {
            carousel.scrollLeft += carousel.offsetWidth;
        }
    }

    public selectItem(data: { key: string, id: number }): void {
        this.selectedFilters[data.key] = data.id;
        this.checkSelectedFilters();
        this.resetFilters(data);

        this.setAvailableFilters(data.key);
    }

    private checkSelectedFilters(): void {
        const keys = ['components', 'areas', 'degrees', 'segments'];
        const allKeysPresent = keys.every((key) => this.selectedFilters[key] && this.selectedFilters[key] !== undefined);
        if (allKeysPresent) {
            this.getPlanningByFilters();
        }
    }

    private setAvailableFilters(key: string): void {
        const params = {
            segment_id: this.selectedFilters.segments,
            degree_id: this.selectedFilters.degrees,
            area_id: this.selectedFilters.areas,
            component_id: this.selectedFilters.components,
            key
        };

        this.contentPlannerService.getFiltersByKey(params)
            .subscribe({
                next: (response) => {
                    const newKey = this.getNextKey(key);

                    this.availableFilters[newKey] = response.map((item) => item.id);

                    if (key !== 'segments' && this.filters) {
                        this.setFilterType(key, response);
                    }
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_load_planning, 'close');
                }
            });
    }

    private resetFilters(data: any): void {
        if (data.key === 'segments') {
            this.selectedFilters = {
                segments: data.id
            };

            if (this.filters) {
                this.filterTypes = [
                    { key: 'segments' },
                    { key: 'degrees' }
                ];
            }
        }

        if (data.key === 'degrees') {
            this.selectedFilters = {
                segments: this.selectedFilters.segments,
                degrees: data.id
            };

            if (this.filters) {
                this.filterTypes = [
                    { key: 'segments' },
                    { key: 'degrees' },
                    { key: 'areas' }
                ];
            }
        }

        this.cd.detectChanges();
    }

    private setFilterType(key: string, data: any): void {
        const newKey = this.getNextKey(key);

        if (key === 'degrees') {
            this.filterTypes = this.filterTypes.filter((item) => item.key !== 'components');
        }

        if (!this.filterTypes.some((item) => item.key === newKey)) {
            this.filterTypes.push({ key: newKey });
        }

        if (this.filters && (key === 'degrees' || key === 'areas')) {
            this.filters[newKey] = data;
        }
    }

    private getPlanningByFilters(): void {
        this.isLoadingPlanning = true;
        const params = {
            segment_id: this.selectedFilters.segments,
            degree_id: this.selectedFilters.degrees,
            area_id: this.selectedFilters.areas,
            component_id: this.selectedFilters.components
        };

        this.contentPlannerService.getPlanningsByFilters(params)
            .subscribe({
                next: (response) => {
                    this.isLoadingPlanning = false;

                    if (!response?.area_name) {
                        this.planning = null;
                        return;
                    }

                    this.planning = response;
                },
                error: () => {
                    this.isLoadingPlanning = false;
                    this.planning = [];
                    this.platformModalService.toggle('message', this.i18n.planner_error_load_planning, 'close');
                }
            });
    }

    goToStartScreen(): void {
        const firstElement = this.firstDomElement.nativeElement;

        if (firstElement) {
            firstElement.scrollIntoView();
        }
    }

    getKeyName(key: string): string {
        return this.i18n[`planner_${key}`];
    }

    private getNextKey(key: string): string {
        switch (key) {
            case 'segments':
                return 'degrees';
            case 'degrees':
                return 'areas';
            case 'areas':
                return 'components';
            default:
                return key;
        }
    }
}
