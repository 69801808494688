import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { DragAndDropListLib } from 'src/app/libs/drag-drop-list.lib';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-create-content-quiz',
    templateUrl: './create-content-quiz.component.html',
    styleUrls: ['./create-content-quiz.component.scss'],
})
export class CreateContentQuizComponent implements OnInit, OnChanges {
    questions: any = [];
    questionPickerVisibility: boolean;
    questionPickerTrieducVisibility: boolean;
    i18n: any;

    @Input() data: any;
    @Input() points_available: any = {};
    @Output() payload = new EventEmitter();
    weightQuestion: any[] = [];

    constructor(
        private elementRef: ElementRef,
        private dragDropListLib: DragAndDropListLib,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.questions = this.data.contentSpecific?.questions[0] || [];
        this.emitPayload();
    }

    ngOnChanges(): void {
        if (Object.values(this.points_available).length === 0) {
            this.points_available.grades = 0;
        }
    }

    addQuestions(questions) {
        this.questions = questions;
        this.weightQuestion = [];
        this.emitPayload();
    }

    removeQuestion(questionIndex) {
        this.questions.splice(questionIndex, 1);
        this.weightQuestion.splice(questionIndex, 1);
        this.emitPayload();
    }

    bootstrapDragAndDrop() {
        const data = {
            elementRef: this.elementRef,
            callBackFn: this.handleDragAndDrop.bind(this),
        };
        this.dragDropListLib.bootstrap(data);
    }

    handleDragAndDrop(dragData) {
        const externalIds = dragData.order;
        this.questions = externalIds.map((id) => this.questions.find((q) => q.external_id === id));
        this.emitPayload();
    }

    // Envia corretamente os dados da questão
    emitPayload(): void {
        const ready = Boolean(this.questions.length);

        const questions = this.weightQuestion.map((e) => ({ weight: e.weight, external_id: e.question.external_id }));

        const questionnarie = { isQuiz: true };

        this.payload.emit({
            ready,
            payload: { questions, questionnarie },
        });
    }

    // Recebe (questão, peso), e atribui o valor atualizado em cada questão
    receiveWeightQuestion(event): void {
        let editItem = false;

        this.weightQuestion.map((element, index: number) => {
            if (element.question.external_id === event.question.external_id) {
                // Valida o peso
                const weight = event.weight !== null && event.weight !== undefined && !Number.isNaN(event.weight) ? event.weight : '';

                this.weightQuestion[index] = event;
                this.weightQuestion[index].weight = weight;

                editItem = true;
            }
            return null;
        });

        if (!editItem) {
            this.weightQuestion.push(event);
        }
        this.emitPayload();
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('QuestionBank'), ...this.sharedService.getTranslationsOf('Errors') };
    }

    addQuestionsTrieduc(questions) {
        this.questions = [...this.questions, ...questions];
    }

    getTraslatePoints(grade: number): string {
        let msg = this.i18n.question_bank_available_points;

        if (grade > 0 && grade < 2) {
            msg = this.i18n.question_bank_available_point;
        }

        return msg;
    }
}
