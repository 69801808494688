import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { PostPlanning, ResponsePlanning } from '../../../interfaces/planning.interface';
import {
    Area, Columns, CurricularComponent, Degree, ListType, Segment
} from '../../../interfaces/start-planning.interface';
import { ContentPlannerService } from '../../../services/content-planner.service';

@Component({
    selector: 'app-start-planning',
    templateUrl: './start-planning.component.html',
    styleUrls: ['./start-planning.component.scss']
})
export class StartPlanningComponent implements OnInit {
    form: FormGroup;
    segmentsList: Segment[] = [];
    degreesList: Degree[] = [];
    areasList: Area[] = [];
    componentsList: CurricularComponent[] = [];
    isEditMode = false;
    planningExternalId = '';
    i18n: any = {};

    constructor(private fb: FormBuilder, private contentPlannerService: ContentPlannerService, private platformModalService: PlatformModalsService, private router: Router, private route: ActivatedRoute, private sharedService: SharedService) {
        this.createFormPlanning();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.checkIsEditMode();
        this.getSegments();
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    checkIsEditMode(): void {
        if (this.route.snapshot.params?.planning_external_id) {
            this.planningExternalId = this.route.snapshot.params.planning_external_id;
            this.isEditMode = true;
        }
    }

    createFormPlanning(): void {
        this.form = this.fb.group({
            segment: ['', Validators.required],
            degree: ['', Validators.required],
            area: ['', Validators.required],
            component: ['', Validators.required]
        });

        const segmentControl = this.form.get('segment');
        if (segmentControl) {
            segmentControl.setValidators(Validators.required);
            segmentControl.updateValueAndValidity();
        }

        const degreeControl = this.form.get('degree');
        if (degreeControl) {
            degreeControl.setValidators(Validators.required);
            degreeControl.updateValueAndValidity();
        }

        const areaControl = this.form.get('area');
        if (areaControl) {
            areaControl.setValidators(Validators.required);
            areaControl.updateValueAndValidity();
        }

        const componentControl = this.form.get('component');
        if (componentControl) {
            componentControl.setValidators(Validators.required);
            componentControl.updateValueAndValidity();
        }
    }

    resetLists(lists: ListType[]): void {
        if (Array.isArray(lists) && lists.length) {
            lists.forEach((list) => {
                if (list) this[list] = [];
            });
        }
    }

    handleNextColumn(response: ResponsePlanning, valueID: number, nextColumn: string, list: string, formControlName: string): void {
        this.contentPlannerService.getNextColumn(valueID, nextColumn).subscribe({
            next: (data) => {
                if (data[nextColumn]) {
                    this[list] = data[nextColumn];
                    this.form.controls[formControlName].setValue(response[`${nextColumn}_id`]);
                }
            }
        });
    }

    getPlanningDetail(): void {
        if (this.planningExternalId) {
            this.resetLists(['degreesList', 'areasList', 'componentsList']);

            this.contentPlannerService.getPlanningDetail(this.planningExternalId).subscribe({
                next: (response) => {
                    this.form.controls.segment.setValue(response.segment_id);

                    this.handleNextColumn(response, response.segment_id, 'degree', 'degreesList', 'degree');
                    this.handleNextColumn(response, response.degree_id, 'area', 'areasList', 'area');
                    this.handleNextColumn(response, response.area_id, 'component', 'componentsList', 'component');
                },
                error: () => {
                    this.router.navigate(['class-planner']);
                }
            });
        }
    }

    getSegments(): void {
        this.contentPlannerService.getSegments().subscribe({
            next: (response) => {
                this.segmentsList = response.segments;
                this.getPlanningDetail();
            },
            error: () => {
                this.form.controls.segment.setValue('error');
            }
        });
    }

    // Check for parameters and insert value, clear lists and dependent fields
    getNextColumn(column: Columns, nextColumn: Columns): void {
        const valueID = this.form.controls[column].value;

        this.contentPlannerService.getNextColumn(valueID, nextColumn).subscribe({
            next: (response) => {
                if (Object.prototype.hasOwnProperty.call(response, 'degree')) {
                    this.resetLists(['degreesList', 'areasList', 'componentsList']);

                    if (response.degree) this.degreesList = response.degree;

                    this.form.reset({
                        segment: this.form.controls.segment.value,
                        degree: '',
                        area: '',
                        component: '',
                    });
                }

                if (Object.prototype.hasOwnProperty.call(response, 'area')) {
                    this.resetLists(['areasList', 'componentsList']);

                    if (response.area) this.areasList = response.area;

                    this.form.reset({
                        segment: this.form.controls.segment.value,
                        degree: this.form.controls.degree.value,
                        area: '',
                        component: '',
                    });
                }

                if (Object.prototype.hasOwnProperty.call(response, 'component')) {
                    this.resetLists(['componentsList']);

                    if (response.component) this.componentsList = response.component;

                    this.form.reset({
                        segment: this.form.controls.segment.value,
                        degree: this.form.controls.degree.value,
                        area: this.form.controls.area.value,
                        component: '',
                    });
                }
            },
        });
    }

    navigateToCurricular(response: ResponsePlanning): void {
        if (response.external_id) {
            this.router.navigate(['class-planner/curricular-parameters', response.external_id]);
        }
    }

    onSubmit(): void {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.get(key)?.markAsTouched();
        });

        if (this.form.valid) {
            const payload: PostPlanning = {
                segment_id: parseFloat(this.form.controls.segment.value),
                degree_id: parseFloat(this.form.controls.degree.value),
                area_id: parseFloat(this.form.controls.area.value),
                component_id: parseFloat(this.form.controls.component.value),
            };

            if (this.isEditMode && this.planningExternalId) {
                this.contentPlannerService.patchPlanning(this.planningExternalId, payload).subscribe({
                    next: (response) => {
                        this.navigateToCurricular(response);
                    },
                    error: () => {
                        this.platformModalService.toggle('message', this.i18n.planner_error_edit_planner, 'close');
                    }
                });
            } else {
                this.contentPlannerService.postPlanning(payload).subscribe({
                    next: (response) => {
                        this.navigateToCurricular(response);
                        this.setDisciplineTemplate(response.id);
                    },
                    error: (error) => {
                        let message = this.i18n.planner_error_create_planner;

                        if (error.status === 409) {
                            message = this.i18n.planner_error_create_planner_already_exists;
                        }

                        this.platformModalService.toggle('message', message, 'close');
                    }
                });
            }
        }
    }

    setDisciplineTemplate(id: any): void {
        this.contentPlannerService.setDisciplineTemplate(id).subscribe({
            error: () => {
                console.log('Erro ao criar disciplina template');
            }
        });
    }
}
