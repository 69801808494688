import {
    AfterViewInit, Component, OnDestroy, OnInit
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-third-party-cookie',
    templateUrl: './third-party-cookie.component.html',
    styleUrls: ['./third-party-cookie.component.scss']
})
export class ThirdPartyCookieComponent implements AfterViewInit, OnDestroy, OnInit {
    private domain = 'https://storage.googleapis.com';
    private iframe: HTMLIFrameElement;
    public isThirdCookieEnabled = true;
    public i18n: any = {};
    public currentLanguage: string | null = null;
    public browser: string;
    public linkTutorial: string;
    private isIOS: boolean;
    private isSamsung: boolean;

    constructor(
        public sharedService: SharedService,
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.browser = this.getBrowserName();
        this.getLinkTutorial();
        this.detectIOS();
        this.detectSamsung();
    }

    detectIOS(): void {
        const userAgent = window.navigator.userAgent.toLowerCase();
        this.isIOS = /iphone|ipad|ipod/.test(userAgent);
    }

    detectSamsung(): void {
        const userAgent = window.navigator.userAgent.toLowerCase();
        this.isSamsung = userAgent.includes('samsung');
    }

    ngAfterViewInit() {
        this.setCookie();
    }

    private setCookie() {
        if (this.isIOS || this.browser === 'safari' || this.isSamsung) {
            return;
        }

        this.iframe = document.createElement('iframe');
        this.iframe.style.display = 'none';
        document.body.appendChild(this.iframe);

        this.iframe.onload = () => {
            if (this.iframe.contentWindow) {
                this.iframe.contentWindow.postMessage({ type: 'setCookie' }, this.domain);
            } else {
                console.error('Erro: iframe ou contentWindow é nulo.');
            }
        };

        this.iframe.onerror = (error) => {
            console.error('Erro ao carregar iframe', error);
        };

        this.iframe.src = `${this.domain}/plat-v2-prod/3rdpartycookiecheck/start.html`;

        window.addEventListener('message', this.receiveMessage.bind(this));
    }

    private receiveMessage(event: MessageEvent): void {
        if (this.domain !== event.origin) {
            return;
        }

        if (event.data && event.data === 'MM:3PCunsupported') {
            this.isThirdCookieEnabled = false;
            return;
        }

        if (event.data && event.data === 'MM:3PCsupported') {
            this.isThirdCookieEnabled = true;
        }
    }

    private getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Errors') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    private getBrowserName(): string {
        const { userAgent } = navigator;

        if (userAgent.indexOf('Chrome') > -1) {
            return 'chrome';
        }

        if (userAgent.indexOf('Safari') > -1) {
            return 'safari';
        }

        if (userAgent.indexOf('Firefox') > -1) {
            return 'firefox';
        }

        if (userAgent.indexOf('Edge') > -1) {
            return 'edge';
        }

        return 'chrome';
    }

    public reload() {
        window.location.reload();
    }

    public getLinkTutorial(): void {
        const objTutorial = this.getObjTutorial();
        this.linkTutorial = objTutorial[this.browser][this.currentLanguage || 'pt-BR'];
    }

    private getObjTutorial(): any {
        return {
            chrome: {
                en: 'https://suporte.inicie.digital/docs/allow-third-party-cookies-in-chrome-browser/',
                'pt-BR': 'https://suporte.inicie.digital/docs/como-habilitar-cookies-de-terceiros-navegador-chrome/',
                es: 'https://suporte.inicie.digital/docs/como-habilitar-las-cookies-de-terceros-en-el-navegador-chrome/'
            },
            safari: {
                en: 'https://suporte.inicie.digital/docs/allow-third-party-cookies-in-safari/',
                'pt-BR': 'https://suporte.inicie.digital/docs/como-habilitar-cookies-de-terceiros-safari/',
                es: 'https://suporte.inicie.digital/docs/como-habilitar-las-cookies-de-terceros-en-el-safari/'
            },
            firefox: {
                en: 'https://suporte.inicie.digital/docs/allow-third-party-cookies-in-mozilla-firefox/',
                'pt-BR': 'https://suporte.inicie.digital/docs/como-habilitar-cookies-de-terceiros-mozilla-firefox/',
                es: 'https://suporte.inicie.digital/docs/como-habilitar-las-cookies-de-terceros-en-el-mozilla-firefox/'
            },
            edge: {
                en: 'https://suporte.inicie.digital/docs/allow-third-party-cookies-in-microsoft-edge/',
                'pt-BR': 'https://suporte.inicie.digital/docs/como-habilitar-cookies-de-terceiros-microsoft-edge/',
                es: 'https://suporte.inicie.digital/docs/como-habilitar-las-cookies-de-terceros-en-el-microsoft-edge/'
            }
        };
    }

    ngOnDestroy() {
        if (this.iframe) {
            document.body.removeChild(this.iframe);
        }
        window.removeEventListener('message', this.receiveMessage.bind(this));
    }
}
