<app-form-modal
    [title]="i18n.question_bank_select_questions"
    [description]="i18n.question_bank_create_questionnaire_external_description"
    [isVisible]="true"
    [overflow]="false"
    class="flex flex-col"
>
    <ng-container>
        <div
            class="pick-questions-container flex flex-col flex-grow"
            [class.is-visible]="true"
        >
            <header class="header" [class.is-visible]="true">
                <div class="filters">
                    <div class="search-bar">
                        <input
                            [formControl]="searchControl"
                            class="search-bar__input"
                            [class.is-active]="searchTextInput.length"
                            [placeholder]="
                                i18n.question_bank_search_by_question
                            "
                            spellcheck="false"
                            autocomplete="false"
                        />
                        <i class="magnifier-icon icon"></i>
                    </div>

                    <div
                        #pipeDropdown
                        class="pipe-filter"
                        [class.is-expanded]="pipeDropdownExpanded"
                    >
                        <button
                            class="pipe-filter__button"
                            [class.is-active]="filtersOn"
                            (click)="toggleDropdown('pipe')"
                            [title]="i18n.question_bank_filters"
                        >
                            <i
                                class="filter-outline-icon pipe-filter__icon"
                            ></i>
                        </button>

                        <div class="overlay">
                            <div class="button-wrapper"></div>
                            <div
                                class="pipe-filters__filters overflow-y-scroll container_scroll"
                            >
                                <div class="pipe-filters__filter dates">
                                    <!-- Base -->
                                    <app-custom-dropdown
                                        [title]="
                                            i18n.question_bank_question_bank_filter_base
                                        "
                                        [marked]="true"
                                        [autoClose]="true"
                                        [block]="true"
                                    >
                                        <ng-container>
                                            <div
                                                class="flex p-4 gap-1 items-center"
                                                *ngFor="let base of bases"
                                            >
                                                <app-check-button
                                                    [active]="
                                                        baseSelected ===
                                                        base?.id
                                                    "
                                                    (change)="
                                                        toggleBase(base?.id)
                                                    "
                                                    [iconSize]="25"
                                                ></app-check-button>
                                                <span
                                                    class="text-customBlue-default font-medium text-16"
                                                >
                                                    {{ base?.name }}
                                                </span>
                                            </div>
                                        </ng-container>
                                    </app-custom-dropdown>

                                    <!-- Filtros da base comum -->
                                    <ng-container
                                        *ngIf="baseSelected === 'COMMON'"
                                    >
                                        <!-- Tipo de questão -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_type_question
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                        >
                                            <ng-container *ngIf="questionTypes">
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let type of questionTypes
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            typeQuestionSelected ===
                                                            type?.id
                                                        "
                                                        (change)="
                                                            toggleTypeQuestion(
                                                                type?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ type?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Etapas -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_stage
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                        >
                                            <ng-container *ngIf="stages">
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="let stage of stages"
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            stageSelected ===
                                                            stage?.id
                                                        "
                                                        (change)="
                                                            toggleStage(
                                                                stage?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ stage?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Disciplinas -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_discipline
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="disciplines.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let discipline of disciplines
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            disciplineSelected ===
                                                            discipline?.id
                                                        "
                                                        (change)="
                                                            toggleDiscipline(
                                                                discipline?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ discipline?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Conteúdos -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_content
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="contents.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let content of contents
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            contentSelected ===
                                                            content?.id
                                                        "
                                                        (change)="
                                                            toggleContent(
                                                                content?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ content?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Topics -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_topic
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="topics.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="let topic of topics"
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            topicSelected ===
                                                            topic?.id
                                                        "
                                                        (change)="
                                                            toggleTopic(
                                                                topic?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ topic?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Subtopics -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_subtopic
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="subtopics.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let subtopic of subtopics
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            subtopicSelected ===
                                                            subtopic?.id
                                                        "
                                                        (change)="
                                                            toggleSubtopic(
                                                                subtopic?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ subtopic?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Especificações -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_specification
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="specifications.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let specification of specifications
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            specificationSelected ===
                                                            specification?.id
                                                        "
                                                        (change)="
                                                            toggleSpecification(
                                                                specification?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{
                                                            specification?.nome
                                                        }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Detalhes -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_detail
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="details.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let detail of details
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            detailSelected ===
                                                            detail?.id
                                                        "
                                                        (change)="
                                                            toggleDetail(
                                                                detail?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ detail?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>
                                    </ng-container>

                                    <!-- Filtros da base BNCC -->
                                    <ng-container
                                        *ngIf="baseSelected === 'BNCC'"
                                    >
                                        <!-- BNCC etapas -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_stage
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="bnccStages.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let stage of bnccStages
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            bnccStageSelected ===
                                                            stage?.id
                                                        "
                                                        (change)="
                                                            toggleBNCCStage(
                                                                stage?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ stage?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- BNCC Areas o Conhecimento -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_knowledge_area
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="bnccKnowledgeAreas.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let knowledgeArea of bnccKnowledgeAreas
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            bnccKnowledgeAreaSelected ===
                                                            knowledgeArea?.id
                                                        "
                                                        (change)="
                                                            toggleBNCCKnowledgeArea(
                                                                knowledgeArea?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{
                                                            knowledgeArea?.nome
                                                        }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- BNCC Disciplinas -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_discipline
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="bnccDisciplines.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let discipline of bnccDisciplines
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            bnccDisciplineSelected ===
                                                            discipline?.id
                                                        "
                                                        (change)="
                                                            toggleBNCCDiscipline(
                                                                discipline?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ discipline?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- BNCC Ano Escolar -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_school_year
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="bnccSchoolYears.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let degree of bnccSchoolYears
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            bnccSchoolYearSelected ===
                                                            degree?.id
                                                        "
                                                        (change)="
                                                            toggleBNCCSchoolYear(
                                                                degree?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ degree?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- BNCC Habilidades -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_skill
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="bnccSkills.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let skill of bnccSkills
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            bnccSkillSelected ===
                                                            skill?.id
                                                        "
                                                        (change)="
                                                            toggleBNCCSkill(
                                                                skill?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ skill?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>
                                    </ng-container>

                                    <!-- Filtros SAEB -->
                                    <ng-container
                                        *ngIf="baseSelected === 'SAEB'"
                                    >
                                        <!-- SAEB Areas do COnhecimento -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_knowledge_area
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="knowledgeAreas.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let knowledgeArea of knowledgeAreas
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            knowledgeAreaSelected ===
                                                            knowledgeArea?.id
                                                        "
                                                        (change)="
                                                            toggleKnowledgeArea(
                                                                knowledgeArea?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{
                                                            knowledgeArea?.nome
                                                        }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Anos escolares -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_school_year
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="schoolYears.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let degree of schoolYears
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            schoolYearSelected ===
                                                            degree?.id
                                                        "
                                                        (change)="
                                                            toggleSchoolYear(
                                                                degree?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ degree?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Eixos do conhecimento -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_knowledge_axis
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="knowledgeAxis.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let axis of knowledgeAxis
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            knowledgeAxisSelected ===
                                                            axis?.id
                                                        "
                                                        (change)="
                                                            toggleKnowledgeAxis(
                                                                axis?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ axis?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Eixos cognitivos -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_cognitive_axis
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="cognitiveAxis.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let axis of cognitiveAxis
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            cognitiveAxisSelected ===
                                                            axis?.id
                                                        "
                                                        (change)="
                                                            toggleCognitiveAxis(
                                                                axis?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ axis?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>

                                        <!-- Habilidades cognitivas -->
                                        <app-custom-dropdown
                                            [title]="
                                                i18n.question_bank_question_bank_filter_cognitive_skill
                                            "
                                            [marked]="true"
                                            [autoClose]="true"
                                            [block]="true"
                                            *ngIf="cognitiveSkills.length"
                                        >
                                            <ng-container>
                                                <div
                                                    class="flex p-4 gap-1 items-center"
                                                    *ngFor="
                                                        let skill of cognitiveSkills
                                                    "
                                                >
                                                    <app-check-button
                                                        [active]="
                                                            cognitiveSkillSelected ===
                                                            skill?.id
                                                        "
                                                        (change)="
                                                            toggleCognitiveSkill(
                                                                skill?.id
                                                            )
                                                        "
                                                        [iconSize]="25"
                                                    ></app-check-button>
                                                    <span
                                                        class="text-customBlue-default font-medium text-16"
                                                    >
                                                        {{ skill?.nome }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </app-custom-dropdown>
                                    </ng-container>
                                </div>

                                <app-bullets-loader
                                    *ngIf="loadFilters"
                                ></app-bullets-loader>

                                <div class="mt-10 w-full flex justify-end">
                                    <button
                                        class="bg-customBlue-default rounded-md w-36 py-2"
                                        (click)="getQuestions(false, true)"
                                    >
                                        <span
                                            class="text-14 font-bold font-Roboto text-customWhite-default"
                                        >
                                            Filtrar
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div class="text-divisor">
                {{ i18n.question_bank_question }}
                <!-- <div class="text-divisor__highlight">
                    {{ countselectedQuestions }}
                </div> -->
            </div>

            <ng-container *ngIf="isLoading">
                <div class="flex justify-center items-center h-full">
                    <app-bullets-loader></app-bullets-loader>
                </div>
            </ng-container>

            <ng-container *ngIf="!isLoading">
                <div
                    class="questions flex-grow overflow-y-scroll container_scroll"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false"
                    *ngIf="!noQuestionsFound"
                >
                    <app-question-bank-trieduc
                        [questions]="questionsTrieduc"
                        [selectedQuestions]="selectedQuestions"
                        [i18n]="i18n"
                        (setQuestions)="setQuestions($event)"
                    >
                    </app-question-bank-trieduc>
                </div>

                <div class="questions__not-found" *ngIf="noQuestionsFound">
                    <!-- ANIMATED SVG PENCIL -->
                    <svg
                        class="not-found__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        preserveAspectRatio="xMidYMid meet"
                        style="transform: rotate(360deg)"
                        viewBox="3.25 2.25 17.5 19.5"
                    >
                        <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 8v4m0 4.01l.01-.011M9 3H4v3m0 5v2m16-2v2M15 3h5v3M9 21H4v-3m11 3h5v-3"
                            class="TdRVQuGX_0"
                        ></path>
                        <style data-made-with="vivus-instant">
                            .TdRVQuGX_0 {
                                stroke-dasharray: 41 43;
                                stroke-dashoffset: 42;
                                animation: TdRVQuGX_draw 2000ms ease-in-out
                                    200ms forwards;
                            }
                            @keyframes TdRVQuGX_draw {
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes TdRVQuGX_fade {
                                0% {
                                    stroke-opacity: 1;
                                }
                                94.44444444444444% {
                                    stroke-opacity: 1;
                                }
                                100% {
                                    stroke-opacity: 0;
                                }
                            }
                        </style>
                    </svg>

                    {{ i18n.question_bank_search_results_not_found }}
                </div>

                <app-bullets-loader *ngIf="loadingScroll"></app-bullets-loader>

                <div class="decision-btns center mt-7 flex-none">
                    <div class="btn forward text-16" (click)="createQuestions()">
                        {{ i18n.question_bank_continue }}
                    </div>
                    <div class="btn backward text-16" (click)="closePicker.emit()">
                        {{ i18n.question_bank_cancel }}
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</app-form-modal>
