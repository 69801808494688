<div class="flex flex-col gap-4 pr-2">
    <ng-container *ngFor="let question of questions">
        <div class="border border-customBlue-glass rounded-md">
            <header class="border-b border-customBlue-glass p-3">
                <div class="flex justify-between">
                    <span class="text-12 text-customBlue-default font-medium">
                        COD {{ question.id }}
                    </span>
                    <label class="checkbox-container">
                        <input
                            type="checkbox"
                            [value]="question.isSelected"
                            (click)="
                                question.isSelected = !question.isSelected;
                                selectQuestion(question)
                            "
                            class="hidden"
                        />
                        <span class="check"></span>
                    </label>
                </div>
            </header>
            <section class="p-3">
                <div class="flex flex-col gap-3">
                    <div
                        *ngIf="question?.textosBase"
                        class="text-18 text-customGray-dark font-medium font-Roboto"
                    >
                        <div
                            *ngFor="let text of question.textosBase"
                            [innerHTML]="sanitizeHtml(text)"
                        ></div>
                    </div>
                    <div
                        class="text-18 text-customGray-dark font-medium font-Roboto"
                        [innerHTML]="sanitizeHtml(question.enunciado)"
                    ></div>
                    <div class="flex flex-col gap-2">
                        <ng-container
                            *ngFor="let alternative of question.alternativas"
                        >
                            <div class="flex gap-2">
                                <app-select-button
                                    [active]="alternative.correta"
                                ></app-select-button>
                                <span
                                    [innerHTML]="
                                        sanitizeHtml(alternative.conteudo)
                                    "
                                ></span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </section>
        </div>
    </ng-container>
</div>
