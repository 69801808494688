import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { QuestionBankService } from 'src/app/pages/disciplines/components/question-bank/question-bank.service';
import { SharedService } from 'src/app/shared/shared.service';

interface Filter {
    id: string;
    translation: string;
    active: boolean;
}

@Component({
    selector: 'app-pick-questions-from-bank',
    templateUrl: './pick-questions-from-bank.component.html',
    styleUrls: ['./pick-questions-from-bank.component.scss'],
})
export class PickQuestionsFromBankComponent
implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('pipeDropdown') pipeDropdown: ElementRef;
    @ViewChild('tagSugestion') tagSugestion: ElementRef;
    @ViewChild('searchInputElement') searchInputElement: ElementRef;
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    @Input() selectedQuestions: any[] = [];

    @Output() addQuestions = new EventEmitter();
    @Output() close = new EventEmitter();

    tagFilterStylization = {
        borderWidth: 2,
        closed: {
            borderColor: '#f4f4f4',
            textColor: '#aaafb4',
            iconColor: '#aaafb4',
        },
        expanded: {
            borderColor: '#233674',
            textColor: '#233674',
        },
    };

    noQuestionData = {
        external_id: 'question-0b18128bb0',
        title: '<p>sdfsfd</p>',
        text: null,
        type: 'TEXT',
        tags: [],
    };

    // Dropdowns controls
    pipeDropdownExpanded = false;
    tagsSugestionExpanded = false;
    pipeDropdownKey: symbol;
    tagsSugestionKey: symbol;

    // Visibility of the modals
    createQuestionVisibility = false;
    editQuestionVisibility = false;

    // Inputs
    searchTextInput = '';
    searchTagsInput = '';

    // Other variables
    questions: any[] = ['']; // Do not remove that string! -> [''] If you remove the ViewChild will return 'undefined'
    disciplineExternalId: string;
    activePage = 1;
    paginationParams: any;
    sortingType = 'desc';
    FiltersOn = false;
    editInputParams: any;
    emptyBank = true;
    noQuestionsFound = true;
    bankInitiated = false;
    i18n: any;

    allTags: any[] = ['']; // Do not remove that string! -> [''] If you remove the ViewChild will return 'undefined', the tags autoclose will not work anymore
    sugestionTags: any[] = [];
    selectedTags: any[] = [];

    types: Filter[];
    dates: any[];

    constructor(
        private shared: SharedService,
        private router: Router,
        private questionBank: QuestionBankService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.setTranslations();

        this.selectedQuestions = JSON.parse(JSON.stringify(this.selectedQuestions));
    }

    ngAfterViewInit(): void {
        this.getDisciplineExternalId();
        this.getQuestions();
        this.initDOM();

        fromEvent(this.searchInputElement.nativeElement, 'keydown')
            .pipe(debounceTime(200))
            .subscribe(() => {
                this.activePage = 1;
                this.refreshQuestionsWithoutScroll();
            });
        this.cd.detectChanges();
    }

    setTranslations() {
        const translationModuleName = 'QuestionBank';
        this.shared.setI18n(this, translationModuleName);

        this.types = [
            {
                id: 'SCALE',
                translation: this.i18n.question_bank_scale,
                active: false,
            },
            { id: 'LIST', translation: this.i18n.question_bank_list, active: false },
            {
                id: 'TEXT',
                translation: this.i18n.question_bank_short_answer,
                active: false,
            },
            {
                id: 'PARAGRAPH_TEXT',
                translation: this.i18n.question_bank_paragraph,
                active: false,
            },
            {
                id: 'MULTIPLE_CHOICE',
                translation: this.i18n.question_bank_multiple_choice,
                active: false,
            },
            {
                id: 'CHECKBOX',
                translation: this.i18n.question_bank_checkbox,
                active: false,
            },
            { id: 'GRID', translation: this.i18n.question_bank_grid, active: false },
            {
                id: 'CHECKBOX_GRID',
                translation: this.i18n.question_bank_checkbox_grid,
                active: false,
            },
        ];

        this.dates = [
            { id: 'desc', translation: this.i18n.question_bank_newer },
            { id: 'asc', translation: this.i18n.question_bank_older },
        ];
    }

    getDisciplineExternalId() {
        this.disciplineExternalId = sessionStorage.getItem('discipline_external_id') || '';
    }

    initDOM() {
        const _this = this;

        const pipeFilterClassName = '.pipe-filter';
        this.pipeDropdownKey = this.shared.toCloseWhenClickOutside(
            pipeFilterClassName,
            this.pipeDropdown,
            { _this, propertyToBeToggled: 'pipeDropdownExpanded' }
        );

        const tagSugestionClassName = '.search-tags';
        this.tagsSugestionKey = this.shared.toCloseWhenClickOutside(
            tagSugestionClassName,
            this.tagSugestion,
            { _this, propertyToBeToggled: 'tagsSugestionExpanded' }
        );
    }

    selectQuestion(event, question, index) {
        const isSelected = this.selectedQuestions.findIndex(
            (q) => q.external_id === question.external_id
        );

        if (isSelected >= 0) {
            const questionIndex = isSelected;
            this.selectedQuestions.splice(questionIndex, 1);
        } else {
            this.selectedQuestions.push(question);
        }

        this.questions[index].selected = !this.questions[index].selected;
        this.questions[index].user_liked = event;
    }

    markQuestionsAsSelected() {
        this.questions.forEach((question) => {
            const isSelected = this.questionIsSelected(question);
            question.selected = isSelected;
        });
    }

    questionIsSelected(question) {
        if (this.selectedQuestions.length === 0) return false;

        return this.selectedQuestions.some(
            (q) => q.external_id === question.external_id
        );
    }

    toggleDropdown(type: string) {
        const dropdownState = this[`${type}DropdownExpanded`];

        this[`${type}DropdownExpanded`] = !dropdownState;
    }

    getQuestions(restartPagination?: boolean, doNotScroll?: boolean) {
        this.bankInitiated = true;

        const getParams = {
            disciplineExternalId: this.disciplineExternalId,
            activePage: this.activePage,
            title: this.searchTextInput,
            tags: this.selectedTags.map((tag) => tag.external_id).toString(),
            sorting: this.sortingType,
            type: this.types
                .filter((type) => type.active)
                .map((type) => type.id)
                .toString(),
        };

        this.questionBank.getQuestions(getParams).subscribe({
            next: (res: any) => {
                // ATENTION: If you add a new filter or query search please add it on the verification bellow
                this.emptyBank = false;
                this.noQuestionsFound = res.questions.length === 0;

                if (this.questions[0] === '') {
                    this.questions = [];
                }

                this.questions = res.questions;
                this.markQuestionsAsSelected();

                this.allTags = res.tags;
                this.generateTagsSugestions(null, true);

                if (!res.questions.length && res.total_questions) {
                    // If the you deleted the last item of a page this if will make the questions section reload again but to the previous and correct page
                    this.activePage -= 1;
                    this.getQuestions(true);

                    return;
                }

                this.paginationParams = {
                    items: [...Array(res.total_questions).keys()],
                    itemsPerPage: 5,
                    visibleButtons: 5,
                    activePage: this.activePage,
                };

                if (restartPagination) {
                    this.pagination.params = this.paginationParams;
                    this.pagination.initPaginationLib();
                }
            },
            error: (res) => {
                this.emptyBank = true;
                this.bankInitiated = true;
                this.noQuestionsFound = true;
                this.questions = [];

                this.router.navigateByUrl(
                    `disciplines/${this.disciplineExternalId}/question-bank`
                );
            },
        });
    }

    goToPage({ activePage }) {
        const getParams = {
            disciplineExternalId: this.disciplineExternalId,
            activePage,
            title: this.searchTextInput,
            tags: this.selectedTags.map((tag) => tag.external_id).toString(),
            sorting: this.sortingType,
            type: this.types
                .filter((type) => type.active)
                .map((type) => type.id)
                .toString(),
        };

        this.questionBank.getQuestions(getParams).subscribe({
            next: (res: any) => {
                this.questions = res.questions;

                this.activePage = activePage;

                this.markQuestionsAsSelected();
            },
            error: (res) => {
                if (res.error.message === 'Bank Question not is vinculate in Bank') {
                    this.router.navigateByUrl(
                        `disciplines/${this.disciplineExternalId}/question-bank`
                    );
                }
            },
        });
    }

    generateTagsSugestions($event?: any, firstLoad?: boolean) {
        if ($event) $event.stopPropagation();

        const sugestions = this.allTags.filter((originalTag) => originalTag.text
            .toLocaleLowerCase()
            .includes(this.searchTagsInput.toLocaleLowerCase()));
        const nonRepetitiveSugestions = sugestions.filter(
            (sug) => !this.selectedTags.some((tag) => tag.text === sug.text)
        );
        this.sugestionTags = nonRepetitiveSugestions;

        if (firstLoad) return;

        this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
    }

    selectTag(tag, index: number) {
        setTimeout(() => {
            this.sugestionTags.splice(index, 1);
            this.selectedTags.push(tag);
            this.tagsSugestionExpanded = Boolean(this.sugestionTags.length);
            this.refreshQuestionsWithoutScroll();
        }, 0);
    }

    unselectTag(index: number) {
        this.selectedTags.splice(index, 1);

        this.refreshQuestionsWithoutScroll();
    }

    changeSorting(sortingOptions: any[]) {
        const sortingType: string = [...sortingOptions].shift().id;
        this.dates = [...sortingOptions];

        this.sortingType = sortingType;

        this.refreshQuestionsWithoutScroll();
        this.refreshFilterTracking();
    }

    refreshFilterTracking() {
        const dateModification = this.dates[0].id !== 'desc';
        const typeModification = this.types.some((type) => type.active);

        this.FiltersOn = dateModification || typeModification;
    }

    refreshQuestionsWithoutScroll() {
        const restartPagination = true;
        const doNotScroll = true;
        this.getQuestions(restartPagination, doNotScroll);
    }

    ngOnDestroy(): void {
        if (this.pipeDropdownKey) this.shared.toCloseWhenClickOutside(this.pipeDropdownKey);
        if (this.tagsSugestionKey) this.shared.toCloseWhenClickOutside(this.tagsSugestionKey);
    }

    likeQuestion($event, question) {
        this.selectedQuestions.forEach((q) => {
            if (q.external_id === question.external_id) {
                q.user_liked = $event;
            }
        });
    }
}
