import {
    Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-preview-exam-modal',
    templateUrl: './preview-exam-modal.component.html',
    styleUrls: ['./preview-exam-modal.component.scss']
})
export class PreviewExamModalComponent {
    @Input() isOpen = false;
    @Input() data: any;
    @Input() i18n: any;
    @Input() currentLanguage: any;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

    formatDateToExam(dateString: string): string {
        const lang = this.currentLanguage === 'en' ? 'en-US' : 'pt-BR';

        let of = 'de';
        let at = 'às';

        if (lang === 'en-US') {
            of = 'of';
            at = 'at';
        }

        // Converte a string da data para um objeto Date
        const date = new Date(dateString);

        // Opções para formatar a data
        const dayOfWeek = date.toLocaleDateString(lang, { weekday: 'long' });
        const day = date.getDate();
        const month = date.toLocaleDateString(lang, { month: 'long' });
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Formatar a data no formato desejado
        const formattedDate = `${dayOfWeek} ( ${day} ${of}  ${month}) ${at} ${hours}h${minutes}`;

        return formattedDate;
    }

    formatTime(duration: string): string {
        const [hours, minutes, seconds] = duration.split(':').map((part) => parseInt(part, 10));

        if (hours > 0) {
            let format = `${hours}h${minutes.toString().padStart(2, '0')}`;

            if (minutes > 0) {
                format += ` ${this.i18n?.disciplines_assessments_preview_minutes}`;
            }

            return format;
        }

        return `${minutes} ${this.i18n?.disciplines_assessments_preview_minutes}`;
    }

    close() {
        this.closeModal.emit();
    }
}
