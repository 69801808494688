import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CheckButtonComponent } from './check-button/check-button.component';
import { PlusButtonComponent } from './plus-button/plus-button.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@NgModule({
    declarations: [
        PlusButtonComponent,
        SelectButtonComponent,
        CheckButtonComponent,
        ToggleButtonComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        PlusButtonComponent,
        SelectButtonComponent,
        CheckButtonComponent,
        ToggleButtonComponent,
    ]
})
export class ButtonsModule { }
