import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SettingsGeneralService {
    constructor(private http: HttpClient) { }

    getGeneralSettings<T>(): Observable<T> {
        return this.http.get<T>(`${API_Routes.URL}/settings/general`);
    }

    patchGeneralSettings<T>(params): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/settings/general/`, params);
    }

    getUrlChatScopes(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/general/url-chat-scopes`);
    }

    confirmClassroomScopes(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/general/confirm-chat-scopes`);
    }
}
