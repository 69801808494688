import {
    animate, AUTO_STYLE, state, style, transition, trigger
} from '@angular/animations';
import {
    Component, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ContentQuizService } from './content-quiz.service';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-content-view-quiz',
    templateUrl: './content-view-quiz.component.html',
    styleUrls: ['./content-view-quiz.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class ContentViewQuizComponent implements OnInit, OnDestroy {
    @Input() content: any = {};
    contentSpecific: any = {};
    urlParams: any = {};
    i18n: any = {};
    i18nAdvanceCriteria: any;
    routeObservable1: any;
    routeObservable2: any;
    completitionRuleTranslation = '';
    quizInited = false;
    finishQuizLoading = false;
    isStudent = false;
    isCompleted = false;
    countIncorrect = 0;
    countCorrect = 0;

    remainingAttempts = false;
    canSubmitQuiz = false;

    isLoading = false;
    questions: any = [];

    constructor(
        private platModalsService: PlatformModalsService,
        private activatedRoute: ActivatedRoute,
        private quizService: ContentQuizService,
        private elementRef: ElementRef,
        private shared: SharedService,
        private router: Router
    ) {}

    ngOnInit(): void {
        console.log('Content view ngOnInit');

        this.getTranslations();
        this.setCompletitionRuleTranslation();
        this.contentSpecific = this.content.contentSpecific;
        this.contentSpecific.questions = this.contentSpecific.questions.flat();
        this.hasRemainingAttempts();
        this.isStudent = this.shared.getUserSync().role_type === 'student';
        this.isCompleted = this.returnIsComplete();
        this.getUrlParams();
        this.getAnswerAndFeedback();
    }

    public returnIsComplete(): boolean {
        return this.isStudent && this.content.status.includes('COMPLETE');
    }

    hasRemainingAttempts(): void {
        if (this.content.completion_rule_value === null) {
            this.remainingAttempts = true;
            return;
        }
        this.remainingAttempts = this.content.coursework_submission_attempts < this.content.completion_rule_value;
    }

    getUrlParams(): void {
        this.routeObservable2 = this.activatedRoute?.params?.subscribe((params) => {
            this.urlParams.section = params.section;
            this.urlParams.section_title = params.section_title;
            this.urlParams.type = params.type;
            this.urlParams.edit = 1;
        });
    }

    tries: any = [];
    getAnswerAndFeedback(): void {
        this.isLoading = true;

        if (!this.isStudent) {
            this.questions = this.contentSpecific.questions;
            this.isLoading = false;
            return;
        }

        if (this.isStudent && this.content.coursework_submission_attempts === 0) {
            this.isLoading = false;
            return;
        }
        this.quizService.getAnswerAndFeedback(this.content.external_id).subscribe({
            next: (res: any) => {
                this.tries = res.tries.reverse();
                if (this.tries && this.tries.length) {
                    this.tryId = this.tries[0].try_id;
                }
                if (this.tryId) {
                    this.getQuestionsFromTryId(this.tryId);
                }
                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    getQuestionsFromTryId(tryId: any) {
        let tryObject = this.tries.find((tryObj) => tryObj.try_id === tryId);

        if (!tryObject) {
            tryObject = this.contentSpecific.questions;
            return;
        }

        const responseMap = new Map(
            tryObject.questions.map((questionWithResponse) => [
                questionWithResponse.external_id,
                questionWithResponse,
            ])
        );

        if (!responseMap) {
            return;
        }

        this.contentSpecific.questions.forEach((question_default, i) => {
            const questionWithResponseObj: any = responseMap.get(question_default.external_id);

            if (questionWithResponseObj) {
                questionWithResponseObj.weight = question_default.weight;
                this.questions[i] = questionWithResponseObj;
            }
        });

        this.countQuestions(this.questions);
    }

    tryId: number;
    changeTry(tryId: number) {
        this.tryId = tryId;
        this.getQuestionsFromTryId(this.tryId);
    }

    returnIndexByTryId(tryId: number): string {
        const index = this.tries.indexOf(this.tries.find((tryObj) => tryObj.try_id === tryId));
        return `${index + 1}`;
    }

    countQuestions(questions: any[]): void {
        this.countIncorrect = 0;
        this.countCorrect = 0;
        questions.forEach((question, i) => {
            console.log('question ', question);

            if (question.status === 'incorrect') {
                this.countIncorrect += 1;
            }

            if (question.status === 'correct') {
                this.countCorrect += 1;
            }
        });
    }

    setCompletitionRuleTranslation(): void {
        this.i18nAdvanceCriteria = this.shared.getTranslationsOf('AdvanceAndCriterion');

        const completitionRulesTranslations = {
            ACCESS: this.i18nAdvanceCriteria.adv_and_crit_access,
            TIME: this.i18nAdvanceCriteria.adv_and_crit_time,
            ATTEMPT_SUBMISSION: this.i18nAdvanceCriteria.adv_and_crit_attempt_sub,
            STUDENT_NOTIFICATION:
                this.i18nAdvanceCriteria.adv_and_crit_std_notification,
        };

        this.completitionRuleTranslation = completitionRulesTranslations[this.content.completion_rule];
    }

    startQuiz(): void {
        this.platModalsService.toggle('loading');
        this.quizService.startQuiz({ contentExternalId: this.content.external_id })
            .subscribe({
                next: () => {
                    // this.getAnswerAndFeedback();
                    console.log('this.questions ', this.questions);
                    this.questions = [];
                    console.log('this.contentSpecific.questions ', this.contentSpecific.questions);
                    this.questions = this.contentSpecific.questions;
                    addEventListener('beforeunload', this.alertBeforeRefresh.bind(this));
                    this.controlStartQuiz(true);
                    this.platModalsService.toggle('loading');
                    this.canSubmitQuiz = false;
                },
                error: (error) => {
                    this.platModalsService.toggle('loading');
                    let message = '';

                    if (error.error?.error.includes('The form can\'t have multipe answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_multipe_answers;
                    } else if (error.error?.error.includes('The form attempt is already open')) {
                        message = this.i18n.discipline_content_view_quiz_error_already_open;
                    } else if (error.error?.error.includes('The form is closed to answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_closed_answers;
                    } else if (error.error?.error.includes('The form can not have multipe answers')) {
                        message = this.i18n.discipline_content_view_quiz_error_multipe_answers;
                    } else if (error.error?.error.includes('Quiz Data not found')) {
                        message = this.i18n.discipline_content_view_quiz_error_not_found;
                    } else {
                        message = this.i18n.question_bank_content_view_quiz_start_error;
                    }

                    this.platModalsService.toggle('message', message, 'close');
                }
            });
    }

    controlStartQuiz(boolean: boolean): void {
        this.quizInited = boolean;
    }

    finishQuiz(): void {
        const reqParams = { contentExternalId: this.content.external_id };
        this.platModalsService.toggle('loading');

        this.quizService.finishQuiz(reqParams).subscribe({
            next: () => {
                this.getBackToContents();
                this.canSubmitQuiz = false;
            },
            error: (res) => {
                this.platModalsService.toggle('message', res.error.message, 'close');
                this.platModalsService.close('loading');
            },
        });

        removeEventListener('beforeunload', this.alertBeforeRefresh);
    }

    editQuiz(): void {
        const disciplineExternalId = this.shared.getDisciplineExternalId(
            this.router.url
        );

        this.elementRef.nativeElement.closest('.router-outlet').scrollTop = 0;
        this.router.navigate([
            `disciplines/${disciplineExternalId}/content-assignment/assignment`,
            this.urlParams,
        ]);
    }

    saveStudentAnswer(answer: any): void {
        if (answer.type === 'change') {
            return;
        }
        this.canSubmitQuiz = false;

        const { user_response, external_id } = answer;

        if (!user_response) return;

        const reqParams = {
            contentExternalId: this.content.external_id,
            question_external_id: external_id,
            user_response,
        };

        this.quizService.saveStudentQuestionAnswer(reqParams).subscribe(
            {
                next: () => {
                    this.canSubmitQuiz = true;
                },
                error: (err) => {
                    this.platModalsService.toggle('message', err.error.error, 'close');
                }
            }
        );
    }

    getBackToContents(): void {
        const disciplineExternalId = this.shared.getDisciplineExternalId(
            this.router.url
        );

        this.router.navigateByUrl(
            `disciplines/${disciplineExternalId}/content-tab`
        );
    }

    alertBeforeRefresh(event): void {
        event.preventDefault();
        event.returnValue = 'Unsaved modifications';
        return event;
    }

    getTranslations(): void {
        this.i18n = {
            ...this.shared.getTranslationsOf('QuestionBank'),
            ...this.shared.getTranslationsOf('Errors'),
            ...this.shared.getTranslationsOf('Disciplines')
        };
    }

    collapseOption = false;
    toggleOptions(): void {
        this.collapseOption = !this.collapseOption;
    }

    ngOnDestroy() {
        if (this.routeObservable2) this.routeObservable2.unsubscribe();
    }
}
