<div
	class="flex bg-customWhite-default px-2 py-4 md:p-8 rounded-t-md"
	style="box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);"
>	
  	<div class="flex flex-col w-full">

		<h1
			class="font-bold text-26 text-customBlue-default"
		>
			{{i18n.dash_my_tasks}}
		</h1>

        <div class="">
            <div 
                class="relative" 
            >
                <div>

                    <button 
                        class="w-full flex items-center justify-between cursor-pointer p-2 pr-5 rounded-md border border-customGray-mediumGray" 
                        (click)="isDropdownOpen=!isDropdownOpen"
                    >
                        <span 
                            *ngIf="!checkedList.length"
                            class="text-customGray-dark"
                        >
                            {{ i18n.dash_all_disciplines }}
                        </span>
                        <ng-container *ngIf="checkedList.length">
                            <span 
                                class="text-customGray-dark"
                                style="white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;"
                            >
                                {{ checkedList.length }} {{ checkedList.length > 1 ? i18n.dash_selected_disciplines : i18n.dash_selected_discipline }} 
                            </span>
                        </ng-container>
                        <button 
                            class="justify-end"
                            [@rotatedState]="!isDropdownOpen"
                        >
                            <span class="iconify w-8 h-8 text-customBlue-default" data-icon="iconamoon:arrow-up-2-fill"></span>
                        </button>
                    </button>

                </div>

                <div 
                    class="w-full drop-show cursor-pointer border-t-0 border border-customGray-default rounded-b-md bg-customWhite-default style-scroll " 
                    [@collapse]="isDropdownOpen"
                >
                    <div >
                        <label
                            class="container flex gap-2 items-center p-2 bg-customGray-iceGray" 
                        >
                            <input 
                                class="hidden"
                                type="checkbox" 
                                [(ngModel)]="allSelected" 
                                (change)="selectAll()"
                            />
                            <div class="check flex justify-center"></div>
                            <div 
                                class="text-customGray-dark"
                            >
                                {{ i18n.dash_select_all_disciplines }}
                            </div>
                        </label>
                    </div>

                    <div class="drop-list p-2">
                        <label 
                            class="container flex gap-2 items-center" 
                            *ngFor="let discipline of dashData.disciplines"
                        >
                            <input 
                                class="hidden"
                                type="checkbox" 
                                [(ngModel)]="discipline.checked" 
                                (change)="getSelectedValue(discipline)" 
                            />
                            <div class="check flex justify-center"></div>
                            <div 
                                [pTooltip]="discipline.name"
                                tooltipPosition="top"
                                class="text-customGray-dark"
                                style="white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;"
                            >
                                {{discipline.name}}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="checkedList.length > 0">
            <div 
                class="flex flex-wrap gap-2 mt-4"
            >
                <ng-container *ngFor="let discipline of dashData.disciplines "  >
                    <div
                        *ngIf="discipline.checked"
                        class="flex items-center justify-between p-2 border border-customGray-mediumGray"
                        style="max-width: 250px; min-width: 0"
                    >
                        <div 
                            [pTooltip]="discipline.name"
                            tooltipPosition="top"
                            class="text-customGray-dark"
                            style="white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;"
                        >
                            {{discipline.name}}
                        </div>
        
                        <div class="cursor-pointer" (click)="removeFilter(discipline)">
                            <span class="iconify w-5 h-5 text-customBlue-default" data-icon="mdi:close"></span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

		<!-- PENDING -->
		<app-status-contents
			[titleStatus]="i18n.dash_pending_title"
			[disciplines]="dashData.disciplines"
			[statusContent]="contentFiltered.pending"
			[i18n]="i18n"
			[typeStatus]="'pending'"
		></app-status-contents>

		<!-- LATE -->
		<app-status-contents
			[titleStatus]="i18n.dash_late_title"
			[disciplines]="dashData.disciplines"
			[statusContent]="contentFiltered.late"
			[i18n]="i18n"
			[typeStatus]="'late'"
		></app-status-contents>

		<!-- NO DEADLINE -->
		<app-status-contents
			[titleStatus]="i18n.dash_no_deadline_title"
			[disciplines]="dashData.disciplines"
			[statusContent]="contentFiltered.no_deadline"
			[i18n]="i18n"
			[typeStatus]="'no_deadline'"
		></app-status-contents>

		<!-- COMPLETED -->
		<app-status-contents
			[titleStatus]="i18n.dash_completed_title"
			[disciplines]="dashData.disciplines"
			[statusContent]="contentFiltered.completed"
			[i18n]="i18n"
			[typeStatus]="'completed'"
		></app-status-contents>

	</div>
</div>
