<div [ngClass]="{'container-table-detail': dad === 'period-detail'}" class="overflow-auto w-auto">
    <table class="bg-customWhite-default rounded-lg w-full">
        <thead>
            <ng-container *ngFor="let student of grades?.students; let i = index;">
                <ng-container *ngIf="i == 0">
                    <tr *ngIf="lastCategoryId">
                        <ng-container *ngFor="let grade of student?.grades">
                            <th *ngIf="checkLastCategoryName(grade.grade_category_id)" [colSpan]="fnColSpan(grade.grade_category_id)">
                                <div class="relative flex justify-center items-center gap-2 text-center bg-categories-name ">
                                    <span>
                                        {{grade.category_name}}
                                    </span>
                                    <ng-container *ngIf="grade?.calculation_type !== 'sum'">
                                        <i (click)="displayPanel.toggle();" class="circle-interrogation-icon circle-interrogation cursor-pointer"></i>
                                        <div  >
                                            <app-display-panel #displayPanel
                                            [style]="{ position: 'absolute', right: '-100px', top: '-15px' }"
                                            [custom_radius]="'10px'"
                                            [hide_arrow]="true"
                                            [panel_style]="{ background: '#FEF8E6' }">
                                                <ng-container *ngIf="grade?.category_weight !== undefined">
                                                    <span class="text-customGray-dark text-16 font-normal">
                                                        {{fnGetCategoryPercent(grade?.category_weight)}}
                                                        <!-- {{i18n.grades_grade_category_percentage}} {{}} -->
                                                    </span>
                                                </ng-container>
                                            </app-display-panel>
                                        </div>
                                    </ng-container>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="student?.grades.length == 0">
                            <th></th>
                        </ng-container>
                        <th colspan="2">
                            <div *ngIf="isSaving" class="flex justify-center items-center">
                                <i class="loading-icon w-10 h-10"></i>
                                <span>
                                    {{i18n.grades_saving}}
                                </span>
                            </div>
                        </th>
                    </tr>
                    <tr class="text-customGray-dark font-semibold text-18 bg-title-grades">
                        <ng-container *ngFor="let grade of student?.grades; let y = index;">
                            <th *ngIf="y == 0" class="p-3 pt-4 title-student">
                                {{i18n.grades_student}}
                            </th>
                            <th  style="min-width: 115px;" class="pb-3 pt-4 px-2 border border-customGray-lighterGray">
                                <span *ngIf="grade?.content_substitutive_id == null">
                                    {{grade.content_title}}
                                </span>
                                <div class="flex-row" *ngIf="grade?.content_substitutive_id !== null">
                                    <span>
                                        {{grade.content_title}}
                                    </span>
                                    <p class="text-customGray-iconGray font-normal text-14" >
                                        {{i18n.grades_substitutive_lesson}} {{fnGetSubistitutiveName(grade?.content_substitutive_id, student?.grades)}} {{")"}}
                                    </p>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="student?.grades.length == 0">
                            <th class="p-3">{{i18n.grades_student}}</th>
                        </ng-container>
                        <ng-container *ngIf="i == 0">
                            <th class="px-10 pt-1.5 border border-customGray-lighterGray">
                                {{i18n.grades_total}}
                            </th>
                            <th class="px-10 pt-1.5">
                                {{i18n.grades_status}}
                            </th>
                        </ng-container>
                    </tr>
                </ng-container>
            </ng-container>
        </thead>
        <tbody>
            <tr *ngFor="let student of grades?.students" class="border-b border-customGray-lighterGray">
                <td class="column-student" [ngClass]="{'p-3 pr-8 border border-customGray-lighterGray' : dad != 'period-detail'}">
                    <div (click)="goToStudentDetail(student, grades?.period)" class="flex items-center gap-2 cursor-pointer" [ngClass]="{'p-3 border-l border-customGray-lighterGray box-shadow-line': dad === 'period-detail'}">
                        <div>
                            <app-user-frame
                            [style]="'width: 1.875rem; height: 1.875rem;'"
                            [img_src]="student?.image"
                            [user_name]="student?.name">
                            </app-user-frame>
                        </div>
                        <div>
                            <span>
                                {{student.name}}
                            </span>
                        </div>
                    </div>
                </td>
                <ng-container *ngFor="let grade of student?.grades">
                    <td class="text-center border border-customGray-lighterGray p-0">
                        <div>
                            <input
                                #gradeInput
                                (change)="patchGrade(grade, gradeInput.value)"
                                type="text"
                                [mask]="decimal_places_mask"
                                [(ngModel)]="grade.evaluation"
                                [ngClass]="{'cursor-not-allowed bg-not-allowed ': grade?.coursework_id == null || grade?.considered_for_average == false || isSaving}"
                                [disabled]="grade?.coursework_id == null || grade?.considered_for_average == false || isSaving"
                                class="text-center w-14"
                                [attr.step]="inputType === 'number' ? 0.01 : null"
                                [attr.min]="inputType === 'number' ? 0 : null"
                            />
                        </div>
                    </td>
                </ng-container>
                <td class="text-center border border-customGray-lighterGray">
                    {{student.average}}
                </td>
                <td class="text-center border border-customGray-lighterGray px-2">
                    <ng-container [ngSwitch]="student.status">
                        <div *ngSwitchCase="'grade_status_approved'"  class="bg-approved text-customGray-dark font-bold text-center">
                            {{i18n.grades_approved}}
                        </div>
                        <div *ngSwitchCase="'grade_status_reproved'" class="bg-reproved text-customGray-dark font-bold text-center">
                            {{i18n.grades_reproved}}
                        </div>
                        <div *ngSwitchCase="'grade_status_open'"  class="bg-open text-customGray-dark font-bold text-center">
                            {{i18n.grades_open}}
                        </div>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
