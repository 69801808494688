<ng-container >
    <div class="box-options" *ngFor="let section of sections; let index = index">
        <div class="box-options__option">
            <div class="text-options-1">
                <i
                [@rotatedState]="state[index]"
                class="arrow-down-icon menu-section-icon"
                (click)="closedSection[index] = !closedSection[index]"
                (click)="state[index] = !state[index]"
                >
                </i>
                {{ section?.title }}
            </div>
            <label
                class="container"
            >
                <input type="checkbox"
                        #checkAll_
                        id="checkAll_{{index}}"
                        (change)="isCheckedAll($event, section, index)"
                        (change)="isCheckedOpen(closedSection[index], $event, index)"
                    />
                <span class="check"></span>
            </label>
        </div>
        <div [@collapse]="closedSection[index]" [formGroup]="form" class="overflow-hidden">
            <div
                *ngFor="let content of form.controls[section?.external_id]['controls']; let i = index"
                class="content-options__option"
                [formArrayName]="section?.external_id"
                pTooltip="{{form.controls[section.external_id]['controls'][i]['controls']?.title.value}}"
                tooltipPosition="top"
                pRipple
            >
                <ng-container [formGroupName]="i">
                    <div class=" content-options__text content-options">
                        <i class="{{chooseContentType(form.controls[section.external_id]['controls'][i]['controls']?.type.value)}} content-options__icon"></i>
                        <p [ngClass]="{'found': i == textFound && alteredFilter == true}">{{form.controls[section.external_id]['controls'][i]['controls']?.title.value}}</p>
                        <!-- <p>{{i}}</p> -->
                    </div>
                    <ng-container *ngIf="section.contents[i]?.can_replicate">

                        <label
                            class="container container-small"
                        >
                            <input
                            id="checked_{{ content.value.external_id }}"
                            (click)="getContentCheck(content); disableAllChacked(checked, checkAll_, index, section?.external_id)"
                            type="checkbox"
                            formControlName="check"
                            class="contentInput{{index}}"
                            #checked
                            />
                            <span class="check"></span>
                        </label>
                    </ng-container>

                    <ng-container *ngIf="!section.contents[i]?.can_replicate">
                        <div>
                            <div
                                (click)="getLocationToDisplayPanel($event);"
                            >
                                <span
                                    class="iconify w-5 h-5"
                                    data-icon="el:warning-sign"
                                    style="color: #F9B5A3"
                                ></span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
<app-display-panel
    #displayPanelWarning
    [style]="{ position: 'absolute' }"
    [custom_radius]="'10px'"
    [hide_arrow]="true"
    [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
    [newStyle]="{ top: displayPanelObject.top , left: displayPanelObject.left, right: displayPanelObject.right }"
>
    {{ i18n.disciplines_export_file_sharing_warning }}
</app-display-panel>

<!-- linha 866 -->
