import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { QuestionBankService } from '../../question-bank.service';

@Component({
    selector: 'app-question-bank-question',
    templateUrl: './question-bank-question.component.html',
    styleUrls: ['./question-bank-question.component.scss'],
})
export class QuestionBankQuestionComponent implements OnInit, AfterViewInit {
    @ViewChild('Form') form: NgForm;
    @ViewChild('question') $question: ElementRef;
    @Input() data: any;
    @Input() DOMid: number;

    @Input() noEdit = false;
    @Input() noDelete = false;
    @Input() noSelect = true;
    @Input() noTag = false;
    @Input() noComment = true;
    @Input() selected = false;
    @Input() order: number;
    @Input() bypassDeletion = false;
    @Input() readonly = true;
    @Input() correction = false;
    @Input() correctionTeacher = false;
    @Input() comments: any;
    @Input() showComments = false;
    @Input() evaluative_content = false;
    _correction = false;

    @Output() deleteQuestion = new EventEmitter();
    @Output() editQuestion = new EventEmitter();
    @Output() likeQuestion = new EventEmitter();
    @Output() change = new EventEmitter();
    @Output() onSelect = new EventEmitter();
    @Output() comment = new EventEmitter();

    @Output() sendWeightQuestion = new EventEmitter();
    @Output() sendPointsCorrection = new EventEmitter();
    weightQuestion = 0;
    gradeCorrection = 0;
    controlweightQuestion = false;
    isSaveQuestion = false;

    title: SafeHtml;
    description: SafeHtml;
    type: string;
    Array = Array;

    loadingMode = false;
    deleteMode = false;
    isInvisible = false;

    positiveFeedback: any;
    negativeFeedback: any;
    feedback: any;

    commentsVisibility = false;
    commentInput: string;

    questionSharedKey: symbol;
    setTimeoutKey: any;
    userInfo: any;
    i18n: any;
    user: any = {};

    isCommentOpen = false;

    constructor(
        private platModalsService: PlatformModalsService,
        private questionBank: QuestionBankService,
        private sanitizer: DomSanitizer,
        public shared: SharedService,
        public router: Router,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (!this.data) return;

        this.extractFeedback();
        this.data = JSON.parse(JSON.stringify(this.data));

        // Setting data such as title, description and type
        this.title = this.sanitizer.bypassSecurityTrustHtml(this.data.title);
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
        this.type = this.data.type;
        setTimeout(() => this.DOMinit(), 0);

        this.getTranslations();
        this.getUserData();
        this.emitWeightQuestion();
        this.getComments();
        this.getUser();
    }

    ngAfterViewInit(): void {
        if (this.router.url.includes('content-assignment/assignment')) {
            this.controlweightQuestion = true;
        } else {
            this.controlweightQuestion = false;
        }

        // Exibe (nota, peso) retornado pelo back-end nos inputs e emite para o payload
        if (this.data?.weight) {
            this.weightQuestion = this.data.weight;
            this.gradeCorrection = this.data.score || 0;
            this.emitWeightQuestion();
            this.getGradeCorrection();
        }

        this.verifychangeCorrectionQuestion();
        this.cd.detectChanges();
    }

    getUser(): void {
        this.shared.getUser().subscribe((user) => {
            this.user = user;
        });
    }

    getComments() {
        if (this.comments == null) {
            this.comments = [];
        }
    }

    verifychangeCorrectionQuestion(): void {
        if (!this.correction || !this.correctionTeacher) return;
        if (
            this.data.type.match(/SCALE|TEXT|GRID/)
            && this.evaluative_content
        ) {
            this._correction = true;
        }
    }

    getUserData() {
        const userData = this.shared.getUserSync();
        this.userInfo = {
            name: userData.name,
            image: userData.image,
        };
    }

    getTranslations() {
        this.i18n = {
            ...this.shared.getTranslationsOf('Errors'),
            ...this.shared.getTranslationsOf('QuestionBank'),
        };
    }

    extractFeedback() {
        if (this.data.options && this.data.options[0]) {
            const correctFeedback = this.data.options[0].correct_feedback;
            const wrongFeedback = this.data.options[0].wrong_feedback;
            this.positiveFeedback = correctFeedback
                ? this.sanitizer.bypassSecurityTrustHtml(correctFeedback)
                : '';

            this.negativeFeedback = wrongFeedback
                ? this.sanitizer.bypassSecurityTrustHtml(wrongFeedback)
                : '';
        }
    }

    DOMinit() {
        const questionDOMClass = '.question-box';
        this.questionSharedKey = this.shared.toCloseWhenClickOutside(
            questionDOMClass,
            this.$question,
            { _this: this, propertyToBeToggled: 'deleteMode' }
        );
    }

    callEditMode() {
        this.editQuestion.emit();
    }

    askToSelect() {
        this.onSelect.emit(this.data.user_liked);
        // this.selected = !this.selected
    }

    toggleAskToDelete() {
        if (this.bypassDeletion) {
            this.loadingMode = true;
            setTimeout(() => {
                this.deleteQuestion.emit(); // Time necessary for animation of loading
            }, 1000);
            return;
        }

        this.platModalsService.toggle('decision', 'deletion_subject_question', {
            forward: () => {
                this.deleteItself();
            },
            finally: () => {
                this.platModalsService.close('decision');
            },
        });
    }

    toggleComments() {
        this.commentsVisibility = !this.commentsVisibility;
    }

    deleteItself() {
        this.loadingMode = true;
        this.questionBank.deleteQuestion(this.data.external_id).subscribe({
            next: () => {
                this.loadingMode = false;
                this.isInvisible = true;
                this.deleteQuestion.emit();
            },
            error: (err) => {
                this.loadingMode = false;
                let messageError: string;
                switch (err.status) {
                    default:
                        messageError = this.i18n.question_bank_error_delete;
                }
                this.showModalError(messageError);
            },
        });
    }

    getCOD() {
        return this.data.external_id
            .replace('question-', '')
            .toUpperCase()
            .slice(5);
    }

    onChange(data?) {
        clearTimeout(this.setTimeoutKey);
        this.setTimeoutKey = setTimeout(() => {
            const questionData = data || this.data;
            const hasUserResponse = questionData.user_response;
            if (hasUserResponse) {
                this.change.emit(questionData);
                this.isSaveQuestion = true;
            }
        }, 500);
    }

    onComment() {
        this.comment.emit(this.commentInput);
    }

    writeComment($event) {
        if (!$event.key) return;
        const $commentInput = $event.currentTarget;
        setTimeout(() => {
            this.commentInput = $commentInput.value;
        }, 150);
    }

    onFeedback($event) {
        this.feedback = $event;
    }

    changeLikeQuestion(question_external_id: string) {
        if (this.data.user_liked === 0) {
            this.data.user_liked = 1;
            this.questionBank.postLikeQuestion(question_external_id).subscribe({
                next: () => {
                    this.likeQuestion.emit(1);
                    this.cd.detectChanges();
                },
                error: (err) => this.showModalError(err.error.message),
            });
        } else {
            this.data.user_liked = 0;
            this.questionBank.postUnlikeQuestion(question_external_id).subscribe({
                next: () => {
                    this.likeQuestion.emit(0);
                    this.cd.detectChanges();
                },
                error: (err) => this.showModalError(err.error.message),
            });
        }
    }

    showModalError(message: string): void {
        this.platModalsService.toggle(
            'message',
            {
                message,
                icon_existence: true,
                icon_color: '#F36C48',
                custom_icon: 'attention-icon',
            },
            'close'
        );
    }

    validateWeightQuestion(): void {
        let value = String(this.weightQuestion);
        value = value.replace(/[^0-9.]/g, '');
        value = value.replace(/^0*(\d+(\.\d+)?)/, '$1');

        const grade = parseFloat(value);
        this.weightQuestion = Math.max(grade, 0);

        this.emitWeightQuestion();
    }

    // Envia a questão e seu peso
    emitWeightQuestion(): void {
        this.sendWeightQuestion.emit({
            weight: this.weightQuestion,
            question: this.data,
        });
    }

    getGradeCorrection(): void {
        if (Number.isNaN(this.gradeCorrection) || this.gradeCorrection === null) {
            this.gradeCorrection = 0;
        }

        if (this.gradeCorrection > this.data.weight) {
            this.gradeCorrection = parseFloat(this.data.weight);
        }

        this.sendPointsCorrection.emit({
            grade: this.gradeCorrection,
            question_external_id: this.data.external_id,
            weight: this.data.weight,
        });
    }

    validateValue(scoreQuestion): void {
        let gradeString = scoreQuestion.toString();

        if (gradeString.includes('-')) {
            gradeString = gradeString.replace(/-/g, '');
            this.gradeCorrection = parseFloat(gradeString);
        }
    }

    ngOnDestroy() {
        if (this.questionSharedKey) this.shared.toCloseWhenClickOutside(this.questionSharedKey);
    }
}
