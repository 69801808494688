import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import { DisplayPanelComponent } from '../../display-panel/display-panel.component';
import { SharedService } from 'src/app/shared/shared.service';
import { collapseAnimation, rotatedStateAnimation } from 'src/app/utils/animations/animations';


const DEFAULT_DURATION = 250;

@Component({
  selector: 'app-select-option-box',
  templateUrl: './select-option-box.component.html',
  styleUrls: ['./select-option-box.component.scss'],
  animations: [
    collapseAnimation('250'),
    rotatedStateAnimation('250')
  ],
})
export class SelectOptionBoxComponent implements OnInit,  OnChanges{
  @ViewChild('checkAll_') testCheckAll: ElementRef

  testeClass = 'text-content-icon'
  checked: boolean = false
  form: UntypedFormGroup
  valueContentForm: any = [];
  closedSection: boolean[] = [];
  state: boolean[] = [];
  checkedAll: boolean[] = [];
  valueSectionForm: any[] = [];
  i18n: any;

  @Input() nameSection: string;
  @Input() modelForm: UntypedFormGroup;
  @Input() sections: any;
  @Input() alteredFilter: boolean = false
  @Input() textFound: any;
  @Input() receiveControlAllSelect: boolean = false;
  @Input() receiveExternalIDContent: string = '';

  @Output() sendingCheckData = new EventEmitter();
  @Output() sendingCheckSectionData = new EventEmitter();
  @Output() checkingAll = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.initializeStates();
   }

  ngOnChanges(changes: SimpleChanges): void {
    let controlAllSelectSection = changes?.receiveControlAllSelect?.currentValue

    this.createContentsForm();
    this.initFormArray(this.sections);
    this.valueContentForm = [];
    this.valueSectionForm = [];
    this.initializeStates();
    this.selectAllSection(controlAllSelectSection);
    this.autoSelected();
}

    getTranslations(){
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),

        };
    }

  selectAllSection(selectAllSection) {
    if(selectAllSection != undefined) {
        this.sections?.forEach((element, i) => {
          let check = document.getElementById('checkAll_'+ i) as HTMLInputElement;
          let isCheck = check?.checked
          isCheck == undefined ? isCheck = false : isCheck = isCheck

          if (!isCheck && selectAllSection == true) {
            check?.click()
            console.log('SECTION F: ', isCheck);
          }
          if (check?.checked == true && selectAllSection == false) {
            check?.click()
            console.log('SECTION T: ', isCheck);
          }
      });
      this.checkContentChange(this.valueContentForm)
    }

    if (selectAllSection === false) {
      this.valueSectionForm = [];
      this.checkSectionChange(this.valueSectionForm);
    }
  }

initializeStates() {
  this.sections?.forEach((e, i) => {
    this.checkedAll[i] = false
  });
  if (this.alteredFilter) {
    this.sections?.forEach((e, i) => {
      this.closedSection[i] = false
      this.state[i] = true
    });
  } else {
    this.sections?.forEach((e, i) => {
      this.closedSection[i] = true
      this.state[i] = false
    });
  }
  }

  createContentsForm() {
    this.form = this.formBuilder.group({
       contents: this.formBuilder.array([])
    })
  }

  initFormArray(sections: any){
    sections?.forEach((section: any)=>{
      this.form.addControl(section.external_id, this.formBuilder.array([]));
      let formArray = this.form.get(section.external_id) as UntypedFormArray;
      section?.contents.forEach((content: any)=>{
        formArray.push(this.createNewContentControl(content))
      })
      this.form.setControl(section.external_id, formArray)
    })
  }

  createNewContentControl(content: any) {
    let newContent = new UntypedFormGroup({
        title: new UntypedFormControl(content?.title),
        type: new UntypedFormControl(content?.type),
        external_id: new UntypedFormControl(content?.external_id),
        check: new UntypedFormControl(false)
    });
    return newContent;
  }

  checkContentChange(data: any) {
    this.sendingCheckData?.emit(data);
  }

  checkSectionChange(data: any) {
    this.sendingCheckSectionData?.emit(data);
  }

  disableAllChacked(input: HTMLInputElement, inputChackAll: HTMLInputElement, index: number, sectionID?: string) {
    if(!input.checked && inputChackAll.checked) {
      (<HTMLInputElement>document.getElementById(`checkAll_${index}`))!.checked = false;

      if (this.valueSectionForm.includes(sectionID)) {
        this.valueSectionForm.splice(this.valueSectionForm.indexOf(sectionID), 1);
        this.checkSectionChange(this.valueSectionForm);
      }
    }

    let childAllChecked: boolean = inputChackAll.checked;
    let SectionInput = [].slice.call(document.getElementsByClassName(`contentInput${ index }`)!);

    childAllChecked = SectionInput.every((element: HTMLInputElement) => {
      return element.checked == true
    })

    if (childAllChecked) {
      (<HTMLInputElement>document.getElementById(`checkAll_${index}`))!.checked = true;
      if (!this.valueSectionForm.includes(sectionID)) {
        this.valueSectionForm.push(sectionID);
        this.checkSectionChange(this.valueSectionForm);
      }
    }
  }

  isCheckedAll(event: any, section, i) {
    if (event?.target?.checked == true) {
      this.checkedAll[i] = true;
      this.form?.controls[section.external_id]['controls']?.forEach(content => {
        let external_id = content?.controls?.external_id?.value;
        if(!this.valueContentForm.includes(external_id)){
          this.valueContentForm.push(external_id);
          content.controls['check'].setValue(true);
          this.checkContentChange(this.valueContentForm)
        }
      });
      if (!this.valueSectionForm.includes(section.external_id)) {
        this.valueSectionForm.push(section.external_id);
        this.checkSectionChange(this.valueSectionForm)
      }
    } else {
      this.checkedAll[i] = false;
      this.form?.controls[section.external_id]['controls']?.forEach(content => {
        let external_id = content?.controls?.external_id?.value;
        if(this.valueContentForm.includes(external_id)){
          this.valueContentForm.splice(this.valueContentForm.indexOf(external_id), 1)
          content.controls['check'].setValue(false);
        }
      });
      if (this.valueSectionForm.includes(section.external_id)) {
        this.valueSectionForm.splice(this.valueSectionForm.indexOf(section.external_id), 1);
        this.checkSectionChange(this.valueSectionForm)
      }
    }
  }

  isCheckedOpen(closedSection, event, i) {
    if (event?.target?.checked == true && closedSection == false) {
      this.closedSection[i] = false
      this.state[i] = true
    }
    else if(event?.target?.checked == true && closedSection == true) {
      this.closedSection[i] = false
      this.state[i] = true
    }
  }

  getContentCheck(content: any){
    setTimeout(() => {
      let external_id = content.controls.external_id.value;

      if (!this.valueContentForm.includes(external_id)) {
        this.valueContentForm.push(external_id);
      } else {
        this.valueContentForm.splice(this.valueContentForm.indexOf(external_id), 1)
      }

      this.checkContentChange(this.valueContentForm)
    }, 50);
  }

  chooseContentType(contentType) {
    switch (contentType) {
      case 'File':
        return 'text-content-icon'
      case 'Youtube':
        return 'video-content-icon'
      case 'TextPage':
        return 'text-content-icon'
      case 'External':
        return 'complementary-content-icon'
      case 'Link':
        return 'complementary-content-icon'
      case 'UploadAssignment':
        return 'text-content-icon'
      case 'Link':
        return 'complementary-content-icon'
      default:
        return 'text-content-icon'
    }
  }

  autoSelected() {
    // Verifica se foi clicado em um conteúdo válido (conteúdos dentro de disciplines)
    if (this.receiveExternalIDContent.length) {
      this.sections.forEach((e, i) => {
        this.form.controls[e?.external_id]['controls'].filter((control) => {
          if (control.value.external_id === this.receiveExternalIDContent) {
            // Encontra o external_id passado e click nele (assim ele vem auto selecionado)
            const getSelected = setInterval(() => {
              if (document.getElementById(`checked_${control.value.external_id}`)) {
                if (!this.valueContentForm.includes(control.value.external_id)) {
                  this.closedSection[i] = false;
                  document.getElementById(`checked_${control.value.external_id}`)?.click();
                  clearInterval(getSelected);
                }
              }
            }, 100)
          } 
        })
      })
    }
  }

  @ViewChild('displayPanelWarning') displayPanelWarning! : DisplayPanelComponent;
  displayPanelObject: any = {}
  getLocationToDisplayPanel($event){
    const windowWidth = window.innerWidth;
    
    const top = $event.clientY + 10;
    const left = $event.clientX + 8;
    this.displayPanelObject.top = top + 'px';
    this.displayPanelObject.left = left + 'px';
    if (windowWidth < 1065 ) {
        this.displayPanelObject.left = '';
        this.displayPanelObject.right = '0px';
    }
    this.displayPanelWarning.toggle($event);
  }
}
