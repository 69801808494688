import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class QuestionBankService {
    showPasteImage: boolean = false;

    constructor(private http: HttpClient) { }

    //BANK
    getQuestions({
        disciplineExternalId,
        activePage,
        title,
        tags,
        sorting,
        type,
    }: any) {
        let getUrl = `${API_Routes.URL}/bank_questions/${disciplineExternalId}?page=${activePage}&per_page=5&search=${title}&order=${sorting}&tags=${tags}&type=${type}`;

        return this.http.get(getUrl);
    }

    // QUESTIONS
    createQuestion(payload: any) {
        return this.http.post(`${API_Routes.URL}/question`, payload);
    }

    deleteQuestion(questionExternalId: string) {
        return this.http.delete(`${API_Routes.URL}/question/${questionExternalId}`);
    }

    updateQuestion(patchParams: any) {
        const getUrl = `${API_Routes.URL}/question/${patchParams.external_id}`; //question external_id

        return this.http.patch(getUrl, patchParams);
    }

    postLikeQuestion(question_external_id: string): Observable<{}> {
        return this.http.post(
            `${API_Routes.URL}/question/${question_external_id}/like`,
            {}
        );
    }

    postUnlikeQuestion(question_external_id: string): Observable<{}> {
        return this.http.post<{}>(
            `${API_Routes.URL}/question/${question_external_id}/unlike`,
            {}
        );
    }

    setAlternativeNameFromContent(
        string,
        index: number,
        question_alternative_i18n: string
    ) {
        const regexForCleanHTMLTags = /<[^>]*>|&.+\;/g;
        const macthA1 = /&agrave;/g;
        const macthAA1 = /&Agrave;/g;
        const macthA2 = /&aacute;/g;
        const macthAA2 = /&Aacute;/g;
        const macthA3 = /&acirc;/g;
        const macthAA3 = /&Acirc;/g;
        const macthA4 = /&atilde;/g;
        const macthAA4 = /&Atilde;/g;
        const macthE1 = /&egrave;/g;
        const macthEE1 = /&Egrave;/g;
        const macthE2 = /&eacute;/g;
        const macthEE2 = /&Eacute;/g;
        const macthE3 = /&ecirc;/g;
        const macthEE3 = /&Ecirc;/g;
        const macthI1 = /&igrave;/g;
        const macthII1 = /&Igrave;/g;
        const macthI2 = /&iacute;/g;
        const macthII2 = /&Iacute;/g;
        const macthI3 = /&icirc;/g;
        const macthII3 = /&Icirc;/g;
        const macthO1 = /&ograve;/g;
        const macthOO1 = /&Ograve;/g;
        const macthO2 = /&oacute;/g;
        const macthOO2 = /&Oacute;/g;
        const macthO3 = /&ocirc;/g;
        const macthO03 = /&Ocirc;/g;
        const macthO4 = /&otilde;/g;
        const macthOO4 = /&Otilde;/g;
        const macthU1 = /&ugrave;/g;
        const macthUU1 = /&Ugrave;/g;
        const macthU2 = /&uacute;/g;
        const macthUU2 = /&Uacute;/g;
        const macthU3 = /&ucirc;/g;
        const macthUU3 = /&Ucirc;/g;
        const matchÇ = /&ccedil;/g;
        const matchÇÇ = /&Ccedil;/g;
        const matchÑ = /&ntilde;/g;
        const matchÑÑ = /&Ntilde;/g;

        return string
            ? string
                .replaceAll(macthA1, 'à')
                .replaceAll(macthAA1, 'À')
                .replaceAll(macthA2, 'á')
                .replaceAll(macthAA2, 'Á')
                .replaceAll(macthA3, 'â')
                .replaceAll(macthAA3, 'Â')
                .replaceAll(macthA4, 'ã')
                .replaceAll(macthAA4, 'Ã')
                .replaceAll(macthE1, 'è')
                .replaceAll(macthEE1, 'È')
                .replaceAll(macthE2, 'é')
                .replaceAll(macthEE2, 'É')
                .replaceAll(macthE3, 'ê')
                .replaceAll(macthEE3, 'Ê')
                .replaceAll(macthI1, 'ì')
                .replaceAll(macthII1, 'Ì')
                .replaceAll(macthI2, 'í')
                .replaceAll(macthII2, 'Í')
                .replaceAll(macthI3, 'î')
                .replaceAll(macthII3, 'Î')
                .replaceAll(macthO1, 'ò')
                .replaceAll(macthOO1, 'Ò')
                .replaceAll(macthO2, 'ó')
                .replaceAll(macthOO2, 'Ó')
                .replaceAll(macthO3, 'ô')
                .replaceAll(macthO03, 'Ô')
                .replaceAll(macthO4, 'õ')
                .replaceAll(macthOO4, 'Õ')
                .replaceAll(macthU1, 'ù')
                .replaceAll(macthUU1, 'Ù')
                .replaceAll(macthU2, 'ú')
                .replaceAll(macthUU2, 'Ú')
                .replaceAll(macthU3, 'û')
                .replaceAll(macthUU3, 'Û')
                .replaceAll(matchÇ, 'ç')
                .replaceAll(matchÇÇ, 'Ç')
                .replaceAll(matchÑ, 'ñ')
                .replaceAll(matchÑÑ, 'Ñ')
                .replaceAll(regexForCleanHTMLTags, '')
                .replaceAll(regexForCleanHTMLTags, '')
            : this.getAlternativeName(index, question_alternative_i18n);
    }

    getAlternativeName(index: number, question_alternative_i18n: string) {
        return `${question_alternative_i18n} ${index + 1}`;
    }

    verifyPasteImage(_event) {
        let event = _event.event || window.event;
        let key = event.which || event.keyCode; // Detecting keyCode
        let ctrl = event.ctrlKey ? event.ctrlKey : key === 17 ? true : false;

        if (key == 86 && ctrl) {
            this.showPasteImage = !this.showPasteImage;
            setTimeout(() => (this.showPasteImage = !this.showPasteImage), 6000);
        }
    }
}
