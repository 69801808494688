import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentService } from 'src/app/pages/disciplines/components/assessments/assessment.service';
import { ScheduleResponse } from 'src/app/pages/disciplines/components/assessments/interfaces/assessment.interface';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
    selector: 'app-evaluation-bank-modal',
    templateUrl: './evaluation-bank-modal.component.html',
    styleUrls: ['./evaluation-bank-modal.component.scss']
})
export class EvaluationBankModalComponent implements OnInit {
    @Input() public isOpen: boolean;
    @Input() i18n: any;
    @Input() scheduleSelected: string;
    @Input() currentLanguage: string;
    @Input() classIdId: string;

    @Output() closeModalEmmit = new EventEmitter<boolean>();
    @Output() scheduleSelectedEmmit = new EventEmitter<ScheduleResponse>();

    schedules: ScheduleResponse[] = [];
    isLoading = false;

    constructor(
        private assessmentService: AssessmentService,
        private cd: ChangeDetectorRef,
        private platModalsService: PlatformModalsService,
    ) { }

    ngOnInit(): void {
        this.getSchedules();
    }

    public close(): void {
        this.closeModalEmmit.emit(false);
    }

    public getSchedules(): void {
        if (this.classIdId) {
            this.isLoading = true;
            this.assessmentService.getSchedules(this.classIdId).subscribe({
                next: (data) => {
                    this.schedules = data;
                    this.isLoading = false;

                    if (this.scheduleSelected) {
                        this.checkedSchedule(this.scheduleSelected);
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: this.i18n.disciplines_assessments_error_load_schedule,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'attention-icon',
                        },
                        'close'
                    );
                    console.error('Error fetching schedules', err);
                }
            });
        }
    }

    public selectSchedule(schedule: ScheduleResponse): void {
        this.scheduleSelected = schedule.agendamentoId;
        this.checkedSchedule(schedule.agendamentoId);
    }

    public checkedSchedule(scheduleId: string): void {
        this.schedules.forEach((s) => {
            s.checked = s.agendamentoId === scheduleId;
        });

        this.cd.detectChanges(); // Força a detecção de mudanças
    }

    public submit(): void {
        const schedule = this.schedules.find((s) => s.agendamentoId === this.scheduleSelected);
        this.scheduleSelectedEmmit.emit(schedule);
        this.close();
    }
}
