import { Component, OnInit } from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ResponsePlanning } from './interfaces/planning.interface';
import { ContentPlannerService } from './services/content-planner.service';

@Component({
    selector: 'app-content-planner',
    templateUrl: './content-planner.component.html',
    styleUrls: ['./content-planner.component.scss']
})
export class ContentPlannerComponent implements OnInit {
    planningList: ResponsePlanning[] = [];
    filteredPlanningList: ResponsePlanning[] = [];
    currentLanguage: string | null;
    filterBy: string;
    activeRole: string;
    i18n: any = {};

    constructor(private contentPlannerService: ContentPlannerService, private platformModalService: PlatformModalsService, private sharedService: SharedService) {}

    ngOnInit(): void {
        this.getTraductions();
        this.getUser();
        this.getPlanning();
        this.getCurrentLanguage();
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    getCurrentLanguage(): void {
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUser<T extends { role_type: string }>(): void {
        this.sharedService.getUser().subscribe((user) => {
            const userObj = user as unknown as T;
            this.activeRole = userObj?.role_type || '';
        });
    }

    getPlanning() :void {
        this.contentPlannerService.getPlanning().subscribe({
            next: (response) => {
                this.filteredPlanningList = response.plannings;
                this.planningList = response.plannings;
            },
            error: () => {
                this.planningList = [];
            }
        });
    }

    filterByTime(option: 'firsts' | 'lasts'): void {
        if (option.includes('firsts')) {
            this.planningList.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        } else {
            this.planningList.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        }
    }

    normalizeString(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    set search_planning_filter(value: string) {
        this.filteredPlanningList = this.planningList;
        this.filterBy = value;

        const normalizedValue = this.normalizeString(value.toLowerCase());

        this.filteredPlanningList = this.planningList.filter((planning) => this.normalizeString(`${planning.degree_name} - ${planning.component_name}`.toLowerCase()).indexOf(normalizedValue) > -1);
    }

    get search_planning_filter(): string {
        return this.filterBy;
    }

    resetFilter(): void {
        this.search_planning_filter = '';
        this.filterBy = '';
    }

    decisionDeletePlanning(planning_external_id: string) {
        this.platformModalService.toggle('decision', this.i18n.planner_are_you_sure_delete_planner, {
            forward: () => {
                this.deletePlanning(planning_external_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    deletePlanning(planning_external_id: string): void {
        if (planning_external_id) {
            this.contentPlannerService.deletePlanning(planning_external_id).subscribe({
                next: () => {
                    this.resetFilter();
                    this.getPlanning();
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_delete_planner, 'close');
                }
            });
        }
    }

    getRouteLinkDiscipline(planning: ResponsePlanning): string {
        return `/disciplines/${planning?.discipline?.external_id}/content-tab`;
    }
}
