import {
    animate,
    AUTO_STYLE,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { SharedService } from 'src/app/shared/shared.service';

import { SubstituteActivityService } from './substitute-activity.service';


const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-substitute-activity',
    templateUrl: './substitute-activity.component.html',
    styleUrls: ['./substitute-activity.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0px', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class SubstituteActivityComponent implements OnInit, OnChanges {
    @Output() substitute_activity = new EventEmitter();
    @Output() substituteActivityControl = new EventEmitter();
    @Input() load_substitute_activity: any = {};
    @Input() contentExternalID = '';
    form: UntypedFormGroup;

    section: any[] = [];
    studentList: any[] = [];
    studentListSelected: any[] = [];
    changeModalUsers = false;
    collapseOption = false;
    currentP = 1;
    itemsPage = 6;
    search_user = '';
    discipline_external_id = '';
    i18n: any = {};

    constructor(
        private sectionService: SectionService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private disciplineDetailService: DisciplineDetailService,
        private substituteActivityService: SubstituteActivityService
    ) { }

    ngOnInit(): void {
        this.sectionService.sections = [];
        this.getParams();
        this.getTranslations();
        this.createForm();
        this.getDisciplineDetail();
        this.getSections();
    }

    ngOnChanges(): void {
        if (this.load_substitute_activity !== null && Object.keys(this.load_substitute_activity).length !== 0) {
            this.setForm();
        }
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Disciplines');
    }

    toggleOptions(): void {
        this.collapseOption = !this.collapseOption;
        this.substituteActivityControl.emit(this.collapseOption);

        if (this.collapseOption) {
            if (this.load_substitute_activity !== null && Object.keys(this.load_substitute_activity).length !== 0) {
                this.substituteActivityService.evaluativeResourceDisabled$.next(true);
            }

            this.sendInformation();
        } else {
            this.substituteActivityService.evaluativeResourceDisabled$.next(false);
            this.substitute_activity.emit({});
        }
    }

    toggleModal(): void {
        this.changeModalUsers = !this.changeModalUsers;
    }

    SelectPreventDefault(event: Event): void {
        event.preventDefault();
    }

    createForm(): void {
        this.form = this.fb.group({
            activity_substitute: [''],
            grade_config: [''],
        });
    }

    getParams(): void {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    isAllSelected(user, boolean) {
        const index = this.studentListSelected.findIndex((savedUser) => savedUser.external_id === user.external_id);

        if (boolean && index === -1) {
            this.studentListSelected.push(user);
        } else if (!boolean && index !== -1) {
            this.studentListSelected.splice(index, 1);
        } else if (boolean && index !== -1) {
            this.studentListSelected[index] = user;
        }
    }

    getDisciplineDetail(): void {
        this.disciplineDetailService
            .disciplineBasic$
            .subscribe((discipline) => {
                this.studentList = discipline?.participants?.student || [];

                if (this.studentList.length) {
                    this.studentList.map((e) => {
                        e.isSelected = false;
                        return null;
                    });
                }
            });
    }

    getSections(): void {
        const getSections = setInterval(() => {
            if (this.sectionService.sections.length !== 0) {
                const contentExternalID = this.form.controls.activity_substitute.value;
                this.sectionService.sections.forEach((sections) => {
                    sections.contents?.forEach((content) => {
                        // Se modo edição - lista o conteúdo cadastrado
                        if (contentExternalID && content.external_id === contentExternalID && content.has_substitute_activity === true) {
                            this.section.push(content);
                        // Se não mostra os conteúdos para substituir exceto do atual
                        } else if (content.weight && content.has_substitute_activity === false && !content.substitute_activity && this.contentExternalID !== content.external_id) {
                            this.section.push(content);
                        }
                    });
                });
                clearInterval(getSections);
            }
        }, 1000);
    }

    sendInformation(): void {
        const externalIDs: string[] = [];
        this.studentListSelected.forEach((e) => { externalIDs.push(e.external_id); });
        const activitySubstitute = this.form.controls.activity_substitute.value;
        const gradeConfig = this.form.controls.grade_config.value;

        if (activitySubstitute) {
            this.substituteActivityService.evaluativeResourceDisabled$.next(true);

            if (gradeConfig && externalIDs.length) {
                this.substitute_activity.emit({
                    content_external_id: activitySubstitute,
                    substitute_way: gradeConfig,
                    elegible_students: externalIDs,
                });
            }
        }
    }

    setForm(): void {
        this.form.controls.activity_substitute.setValue(this.load_substitute_activity.content_external_id);
        this.form.controls.grade_config.setValue(this.load_substitute_activity.substitution_way);
        this.substituteActivityService.evaluativeResourceDisabled$.next(true);

        const getStudentInterval = setInterval(() => {
            if (this.studentList.length) {
                this.studentList.filter((e) => {
                    this.load_substitute_activity.elegible_students.forEach((element) => {
                        if (e.external_id === element) this.studentListSelected.push(e);
                    });
                    return null;
                });

                this.sendInformation();
                clearInterval(getStudentInterval);
            }
        }, 200);

        this.collapseOption = true;
    }

    stopPropagation($event): void {
        if ($event) $event.stopPropagation();
    }

    getSubstituteActivityViewGrade(): void {
        const contentExternalID = this.form.controls.activity_substitute.value;

        if (contentExternalID) {
            this.substituteActivityService.getSubstituteActivityViewGrade(this.discipline_external_id, contentExternalID).subscribe({
                next: (response) => {
                    this.substituteActivityService.evaluativeResourceDisabled$.next(true);
                    this.substituteActivityService.evaluativeResourceValue$.next(response);
                },
                error: () => {
                    this.substituteActivityService.evaluativeResourceDisabled$.next(false);
                }
            });
        }
    }
}
