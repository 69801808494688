import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarouselModule } from 'src/app/components/carousel/carousel.module';
import { EmptyDataModule } from 'src/app/components/empty-data/empty-data.module';
import { MessageModalModule } from 'src/app/components/modals/message-modal/message-modal.module';
import { ProgressBarModule } from 'src/app/components/progress-bar/progress-bar.module';
import { UserFrameModule } from 'src/app/components/user-frame/user-frame.module';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { RemoveFirstHourZeroPipe } from 'src/app/pipes/remove-first-hour-zero/remove-first-hour-zero.pipe';
import { SharedModule } from 'src/app/shared/shared.module';

import { DropdownsModule } from '../../components/dropdowns/dropdowns.module';
import { AdminComponent } from './admin/admin.component';
import { AdmCardComponent } from './components/adm-components/adm-card/adm-card.component';
import { AdmChartsComponent } from './components/adm-components/adm-charts/adm-charts.component';
import { ActiveUsersComponent } from './components/adm-components/adm-chunks/active-users.component';
import { AdmShortcutCardComponent } from './components/adm-components/adm-chunks/adm-shortcut-card.component';
import { AdmUserCardComponent } from './components/adm-components/adm-chunks/adm-user-card.component';
import { AveragePerformanceClassComponent } from './components/adm-components/adm-chunks/average-performance-class.component';
import { AverageTimeUseComponent } from './components/adm-components/adm-chunks/average-time-use.component';
import { ContentTypeComponent } from './components/adm-components/adm-chunks/content-type.component';
import { StructureResumeComponent } from './components/adm-components/adm-chunks/structure-resume.component';
import { AdmShortcutsComponent } from './components/adm-components/adm-shortcuts/adm-shortcuts.component';
import { AdmUsersComponent } from './components/adm-components/adm-users/adm-users.component';
import { CardStudentComponent } from './components/card-student/card-student.component';
import { CardTeacherComponent } from './components/card-teacher/card-teacher.component';
import { DashboardFeedComponent } from './components/dashboard-feed/dashboard-feed.component';
import { LastAnnouncementsComponent } from './components/last-announcements/last-announcements.component';
import { StatusContentsComponent } from './components/my-tasks/components/status-contents/status-contents.component';
import { MyTasksComponent } from './components/my-tasks/my-tasks.component';
import { MyWeekTasksComponent } from './components/my-week-tasks/my-week-tasks.component';
import { ResumeDataComponent } from './components/resume-data/resume-data.component';
// import { DateAgoPipe } from 'src/app/pipes/date-ago/date-ago.pipe';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NormalComponent } from './normal/normal.component';
import { StudentComponent } from './student/student.component';
import { TeacherComponent } from './teacher/teacher.component';

// import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        DashboardComponent,
        StudentComponent,
        TeacherComponent,
        NormalComponent,
        RemoveFirstHourZeroPipe,
        // DateAgoPipe,
        AdminComponent,
        LastAnnouncementsComponent,
        DashboardFeedComponent,
        ResumeDataComponent,
        CardTeacherComponent,
        CardStudentComponent,
        AdmCardComponent,
        AdmShortcutsComponent,
        AdmChartsComponent,
        AdmUsersComponent,
        AdmUserCardComponent,
        AdmShortcutCardComponent,
        AveragePerformanceClassComponent,
        AverageTimeUseComponent,
        ContentTypeComponent,
        StructureResumeComponent,
        ActiveUsersComponent,
        MyTasksComponent,
        StatusContentsComponent,
        MyWeekTasksComponent
    ],
    exports: [
        RemoveFirstHourZeroPipe,
        // DateAgoPipe
        PermissionModule,
        DropdownsModule
    ],
    imports: [
        SharedModule,
        DashboardRoutingModule,
        NgxChartsModule,
        ProgressBarModule,
        UserFrameModule,
        // BrowserModule,
        LazyLoadImageModule,
        UserFrameModule,
        // BrowserModule,
        LazyLoadImageModule,
        MessageModalModule,
        EmptyDataModule,
        MultiSelectModule,
        CommonModule,
        CarouselModule,
        PermissionModule,
    ],
})
export class DashboardModule { }
