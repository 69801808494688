import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ContentGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
    constructor(
        private sharedService: SharedService,

    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const i18n = this.sharedService.getTranslationsOf('Errors');
        const message = i18n.permission_unauthorized;

        if (state.url.includes('content-view')) {
            if (!this.sharedService.checkPermission('contents', 'list')) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('homework-submissions')) {
            if (!this.sharedService.checkPermission('contents_submission', 'list')) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('assignment')) {
            if (!this.sharedService.checkPermission('contents', 'list')) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        if (state.url.includes('assignment')) {
            if (!this.sharedService.checkPermission('contents', 'list')) {
                this.sharedService.showUnauthorizedModal(message);
                return false;
            }
        }

        return true;
    }

    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
